import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Menu, Dropdown, Button, Divider, Tooltip,Input,Select} from 'antd';
import UserAvatar from "react-user-avatar";
import EventListener from "react-event-listener";
import {animateScroll, Element as ScrollElement} from "react-scroll";
import anim from "animejs";

import {AvatarColors, IconTypes} from "@/constants/common";

import PB, {SimplePB} from "@/libs/simplePB";

import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import TimeDisplayField from "@/components/common/common.timeDisplayField";
import Icon from "@/components/common/common.icon";

import style from '@/style/common/relation/common.relation.aiConsole.less';
import {SysUIConfig} from "@/constants/sys.config";
import {API_analysisChopdown,API_analysisMilvusChopdown,API_analysisChopdown_english,API_checkChopTxt,API_analysisSemantemeByNodes,API_streamChat,baseEventSourceUrl,API_getNodeIds} from "@/api/relation";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import intl from 'react-intl-universal';
//import { fetchEventSource } from '@microsoft/fetch-event-source';
import {
  getNodeIcon,
  NODE_TYPE_TEXT
} from '@/constants/vis.defaultDefine.1';

const dataListKey = ['nodes'];
const searchFields = ['fname', 'tag'];
const roundPerFrame = 200;
const aiSendTypeIcons = ['icon-node-star','icon-dashboard2','icon-information-o','icon-institute','icon-help','icon-jiqiren1'];

let defaultDataLists = {};
dataListKey.forEach(v => defaultDataLists[v] = []);

const margin = 8;

const nextStatusMap = {
  background: 'active',
  hidden: 'background',
  active: 'hidden',
};

class RelationAIConsole extends React.PureComponent {
  state = {
    translationX: 0,
    translationY: 0,
    inputFocused: false,
    containerId: `ai-console-${Math.random()}`,
    heightLevel: 3,
    status: 'active',
    inputText:'',
    aiHover:false,
    lastSearchText: undefined,
    searchResultList: [],
    inputType:0
  };

  element = undefined;

  nodeType = intl.get('locale')!=='zh-cn'?1:400;
  allChopList = [];
  bak = 0;

  elementInputForSend = undefined;

  moveBarElement = undefined;

  containerBoundingRect = undefined;

  dragging = false;

  lastX = undefined;

  lastY = undefined;

  newMessages = 0;

  autoScroll = true;

  scrolling = false;

  /**
   * @type {undefined|Function}
   */
  afterScroll = undefined;

  prevHeightLevel = 3;

  constructor(props) {
    super(props);

    this.messages = [{
      type: 'ai',
      key: `m-${Math.random()}`,
      time: moment(),
      content: intl.get('Custom.message.welcome'),
    }];

    this.messageMap = {
      [this.messages[0].key]: this.messages[0],
    };
  }

  updateAndScroll = (messageKey, callback) => {
    let me = this, dx = 0, doScroll = me.autoScroll;

    if (me.scrolling) {
      me.afterScroll = () => {
        me.updateAndScroll(messageKey, callback);
      }
      return;
    }

    if (messageKey !== undefined) {
      doScroll = true;
    }
    let objDiv = document.getElementById(me.state.containerId);
    if (objDiv) {
      if (doScroll) {
        // 计算位置
        let nodeIdx = -1;
        if (messageKey !== undefined) {
          nodeIdx = me.messages.findIndex(v => v.key === messageKey);
        }
        if (nodeIdx < 0) nodeIdx = objDiv.childNodes.length;
        dx = objDiv.scrollHeight;
        if (dx > objDiv.clientHeight) {
          // 计算滚动位置
          dx = 0;
          objDiv.childNodes.forEach((node, idx) => {
            if (idx <= nodeIdx) dx += node.offsetHeight;
          });
          dx -= 100;
        }
        if (objDiv.scrollHeight - dx - objDiv.clientHeight < 10) {
          me.newMessages = 0;
        }
      }
    }

    me.forceUpdate(objDiv ? () => {
      // 滚动到最新一条记录处
      if (doScroll) {
        me.scrolling = true;
        animateScroll.scrollTo(
          dx,
          {
            containerId: me.state.containerId,
            duration: 350,
          }
        );
        setTimeout(() => {
          me.scrolling = false;
          if (me.afterScroll) {
            let fn = me.afterScroll;
            me.afterScroll = undefined;
            fn();
          }
        })
      }
      callback && setTimeout(callback, 500);
    } : undefined);
  };

  initElementXY = () => {
    let me = this;

    if (me.containerBoundingRect) return;
    if (!me.moveBarElement) return;

    let moveBarElementBoundingClientRect = me.moveBarElement.getBoundingClientRect();
    let documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let documentHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let consoleElementBoundingClientReact = me.moveBarElement.parentElement.getBoundingClientRect();
    me.containerBoundingRect = {
      // 浮层位置范围
      minTop: moveBarElementBoundingClientRect.height / 2 * 4.7,
      maxTop: documentHeight,
      minLeft: 0,
      maxLeft: documentWidth - moveBarElementBoundingClientRect.height,
      // 鼠标范围
      minX: 0,
      maxX: documentWidth,
      minY: moveBarElementBoundingClientRect.height / 2 * 2.6 + 1,
      maxY: documentHeight,
      // 1rem实际长度
      rem: moveBarElementBoundingClientRect.height / 2,
      // 控制台宽高
      consoleWidth: consoleElementBoundingClientReact.width,
      consoleHeight: consoleElementBoundingClientReact.height,
      // 移动条宽高
      moveBarWidth: moveBarElementBoundingClientRect.width,
      moveBarHeight: moveBarElementBoundingClientRect.height,
      // 文档宽高
      documentWidth,
      documentHeight,
    };

    me.setState({
      /*translationX: (document.body.offsetWidth - me.containerBoundingRect.consoleWidth) * 0.5,
      translationY: document.body.offsetHeight * 0.75 - me.containerBoundingRect.consoleHeight + me.containerBoundingRect.moveBarHeight,   v1
      translationX: me.props.initialLeft ? me.props.initialLeft : me.containerBoundingRect.documentWidth - 3.8857 * me.containerBoundingRect.rem - me.containerBoundingRect.consoleWidth,
      translationY: me.props.initialTop ? me.props.initialTop : me.containerBoundingRect.documentHeight / 2 - 1.25 * me.containerBoundingRect.rem
        - me.containerBoundingRect.consoleHeight / 2 + me.containerBoundingRect.moveBarHeight / 2,   v2  */
      translationX: me.props.initialLeft ? me.props.initialLeft : me.containerBoundingRect.documentWidth - 3.8857 * me.containerBoundingRect.rem - me.containerBoundingRect.consoleWidth,
      translationY: me.props.initialTop ? me.props.initialTop : me.containerBoundingRect.minTop*1.3,
    });
  };

  onDragStart = e => {
    let me = this;

    e.stopPropagation();

    if (e.which === 1 || e.button === 0) {
      me.dragging = true;
    }
  };

  onDragging = e => {
    let me = this;
    if (!me.dragging) return;
    let currentX = Math.max(me.containerBoundingRect.minX, Math.min(me.containerBoundingRect.maxX, e.clientX));
    let currentY = Math.max(me.containerBoundingRect.minY, Math.min(me.containerBoundingRect.maxY, e.clientY));
    if (me.lastX && me.lastY) {
      let dx = currentX - me.lastX,
        dy = currentY - me.lastY,
        currentLeft = me.state.translationX,
        currentTop = me.state.translationY,
        targetLeft = currentLeft + dx,
        targetTop = currentTop + dy,
        finalLeft = Math.max(me.containerBoundingRect.minLeft,
          Math.min(me.containerBoundingRect.maxLeft, targetLeft)),
        finalTop = Math.max(me.containerBoundingRect.minTop, Math.min(me.containerBoundingRect.maxTop, targetTop));
      me.setState({translationX: finalLeft, translationY: finalTop});
    }
    me.lastX = currentX;
    me.lastY = currentY;
  };

  onDragEnd = () => {
    let me = this;

    if (!me.dragging) return;
    me.dragging = false;
    me.lastX = undefined;
    me.lastY = undefined;
    me.forceUpdate();
  };

  onHoverTrue = e => {
    //e.stopPropagation();
    //e.preventDefault();
    let me = this;
    me.setState({aiHover:true});
  }

  onHoverFalse = () => {
    let me = this;
    me.setState({aiHover:false});
  }

  dockComponent = () => {
    let me = this;

    setTimeout(() => {
      let targetTranslateX = me.containerBoundingRect.documentWidth - 3.8857 * me.containerBoundingRect.rem
        - me.containerBoundingRect.consoleWidth;
      /*let targetTranslateY = me.containerBoundingRect.documentHeight / 2 - 1.25 * me.containerBoundingRect.rem
        - (me.containerBoundingRect.consoleHeight - me.containerBoundingRect.moveBarHeight) / 2;*/
      let targetTranslateY = me.containerBoundingRect.documentHeight / 2 - 1.25 * me.containerBoundingRect.rem
        - me.containerBoundingRect.consoleHeight / 2 + me.containerBoundingRect.moveBarHeight / 2;
      me.animMoveTo(targetTranslateX, targetTranslateY);
    }, 100);
  };

  animMoveTo = (x, y) => {
    let me = this;

    if (me.state.translationX !== x || me.state.translationY !== y) {
      if (me.anim) stopAnimation(me.anim);
      // noinspection SpellCheckingInspection
      me.anim = anim({
        targets: me.element,
        left: x,
        top: y,
        duration: 800,
        easing: 'easeInOutCirc',
        update: function (anim) {
          me.setState({
            translationX: parseInt(anim.animations[0].currentValue.substring(0,
              anim.animations[0].currentValue.length - 2)),
            translationY: parseInt(anim.animations[1].currentValue.substring(0,
              anim.animations[1].currentValue.length - 2)),
          });
        },
      });
    }
  };

  setHeightLevel = (e, heightLevel) => {
    let me = this;

    e.preventDefault();
    e.stopPropagation();

    let currentContentHeight = me.containerBoundingRect.consoleHeight - me.containerBoundingRect.moveBarHeight;
    if(heightLevel===0){
      me.animMoveTo(me.containerBoundingRect.documentWidth - 3.8857 * me.containerBoundingRect.rem - me.containerBoundingRect.consoleWidth,me.containerBoundingRect.rem*4.5+me.containerBoundingRect.moveBarHeight);
      setTimeout(() => {
        me.props.bus.emit('aiConsole', 'status.do_change', {status: null});
        heightLevel=3;
        me.prevHeightLevel = 2;
        me.containerBoundingRect.consoleHeight = currentContentHeight + me.containerBoundingRect.moveBarHeight;
        me.setState({
          heightLevel,
        });
      }, 1000);
    }else{
      if (heightLevel > me.state.heightLevel) {
        // 放大
        currentContentHeight *= 2;
        me.prevHeightLevel = me.state.heightLevel;
      } else {
        // 缩小
        currentContentHeight /= 2;
        me.prevHeightLevel = me.state.heightLevel;
      }
      me.containerBoundingRect.consoleHeight = currentContentHeight + me.containerBoundingRect.moveBarHeight;
      me.setState({
        heightLevel,
      });
    } 
  };

  getAnalysisChopdown = (params) => {
    return new Promise((resolve, reject) => {
      if(intl.get('locale')==='zh-cn'){
        params.chop_way = this.state.inputType
        if(this.state.inputType!==5){
          API_analysisMilvusChopdown(params).then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          }).catch(error => {
            reject(error);
          });
        }
      }else{
        API_analysisChopdown_english(params).then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      }
    });
  };

 /*  
  getAnalysisChopdown = (params) => {
    return new Promise((resolve, reject) => {
      if(this.state.inputType===0){
        API_analysisSemantemeByNodes(params).then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      }else{
        if(intl.get('locale')==='zh-cn'){
          params.chop_way = this.state.inputType===0?400:500
          API_analysisMilvusChopdown(params).then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          }).catch(error => {
            reject(error);
          });
        }else{
          API_analysisChopdown_english(params).then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          }).catch(error => {
            reject(error);
          });
        }
      }
    });
  };
 /*
  getAnalysisChopdown = (params) => {
    return new Promise((resolve, reject) => {
      if(intl.get('locale')==='zh-cn'){
        params.chop_way = this.state.inputType===0?400:500
        API_analysisMilvusChopdown(params).then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      }else{
        API_analysisChopdown_english(params).then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      }
    });
  };
 */
  showAiConsole = (fname,nodeType,nodeList,messageKey) => {
    let me = this;
    if(nodeList && nodeList.length>0){
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <div>
            {fname}
            <ul className={style['ai-node-ul']}>{nodeList.map((node,idx) => (<li className={`${style['ai-node-li']} ${nodeList.length==1 ? style['li-one'] : style['li-two']}`}>{node.label}</li>))}</ul>
          </div>
        ),
        delay: 0,
      });
    }        
  }

  showAiConsoleError = (messageKey,msg) => {
    let me = this;
    if(msg && msg.length>0){
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <div style={{lineHeight:1.6}}>
            {msg[0]}
            {msg.length>1 && <ul className={style['ai-node-ul']}><li className={`${style['ai-node-li']} ${style['li-one']}`}>{msg[1]}</li></ul>}
            {msg.length>2 && msg[2]}
          </div>
        ),
        delay: 0,
      });
    }        
  }

  showAiConsoleMore = (messageKey,fname,msgList) => {
    let me = this;
    if(msgList && msgList.length>0){
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <div>
            {fname}
            <ul className={style['ai-node-ul']}>{msgList.map((node,idx) => (<li className={`${style['ai-node-li']} ${msgList.length==1 ? style['li-one'] : style['li-two']}`}>{node.label}</li>))}</ul>
            <span onClick={e => {e.preventDefault();me.handleGetMore();}} className="clamp-lines__link_button">【{'更多'}】</span>
          </div>
        ),
        delay: 0,
      });
    }
  }

  showAiConsoleNodes = (messageKey,node_ids,fname,msgList) => {
    let me = this;
    if((msgList && msgList.length>0) || (node_ids && node_ids.length>0)){
      const {data} = me.props.networkRef.getData();
      let nodeList = data.nodes.get().filter(node => node_ids.includes(node.id));
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <div>
            {fname}
            <ul className={style['ai-node-ul']}>{msgList.map((node,idx) => (<li className={`${style['ai-node-li']} ${msgList.length==1 ? style['li-one'] : style['li-two']}`}>{node.label}</li>))}</ul>
            {'相关节点：'}
            <ul className={style['ai-node-ul']}>
              {nodeList.map((node,idx) => (<li className={style['ai-node-li-action-node']}
                onClick={e => {
                  e.preventDefault();
                  PB.emit('network', 'focus', node);
                }}
              >
                <span className={style['node-dot']}>
                  <Icon {...getNodeIcon({type: NODE_TYPE_TEXT})} style={{width:'0.8em',marginRight: '0.5em'}}/>
                </span>
                <Tooltip title={node.label.replace(/<[^>]+>/g,"")}>{node.label.replace(/<[^>]+>/g,"")}</Tooltip></li>))}
            </ul>
            <span onClick={e => {e.preventDefault();me.handleGetMore();}} className="clamp-lines__link_button">【{'更多'}】</span>
          </div>
        ),
        delay: 0,
      });
    }        
  }

  doChopdown = (reget = false,messageKey) => {
    let me = this;
    if(me.state.inputText && me.state.inputText.length>0){
      let params ={
        view_id:me.props.viewId,
        node_nm:me.state.inputText, 
        node_tag:'', 
        chop_way:me.state.inputType,
        child:'',
        reget:0,
        bak:me.bak,
        query:me.state.inputText,
        func_name:'ask_context_evnt'
      }
      me.allChopList = [];
      me.bak=0;
      if(intl.get('locale')==='zh-cn'){
        me.doEventSourse(params,messageKey);
      }else{
        me.getAnalysisChopdown(params).then(response => {
          if (response && response.data && response.data.code === 0 && response.data.data.length>0) {
            response.data.data.forEach((d, index) => {
              if(d.phr_down.length>0){
                let data_arr = d.phr_down.split('^^');
                if(data_arr.length>0){
                  let _data = [];
                  data_arr.forEach((item, idx) => {
                    _data.push({id:idx,label:item});
                  });
                  me.allChopList.push(_data);
                }
              }
            });
            if(me.allChopList.length>0){
              let _d = [];
              me.allChopList[0].forEach((i, idx) => {
                _d.push(i);
              });
              me.showAiConsole(params.node_nm,params.chop_way,_d,messageKey);
            }else{
              if(params.bak===0){
                me.bak=1;
                me.doChopdown(reget,messageKey);
              }else{
                me.showAiConsoleError(messageKey,this.state.inputType===0?intl.get('Custom.message.noChopResult'):intl.get('Custom.message.noChopDataResult'));
              }
            }    
          } else {
            if(params.bak===0){
              me.bak=1;
              me.doChopdown(reget,messageKey);
            }
          }
        }).catch(error => {
          console.warn(`get api failed`,error);
          me.showAiConsoleError(messageKey,this.state.inputType===0?intl.get('Custom.message.noChopResult'):intl.get('Custom.message.noChopDataResult'));
        });
      }
    }
  }

  doEventSourse = (params,messageKey,content='') => {
    let me = this;
    let showmore = false;
    let url = me.state.inputType<5?`${baseEventSourceUrl}/chat/map_query?view_id=${params.view_id}&chop_way=${params.chop_way}&func_name=${params.func_name}&node_nm=${params.node_nm}`:
      `${baseEventSourceUrl}/chat/any_query?node_nm=${params.node_nm}`;
    if(params.func_name=='ask_context_question'){
      url = `${baseEventSourceUrl}/chat/map_query?view_id=${params.view_id}&chop_way=${params.chop_way}&func_name=${params.func_name}`
    }
    if(params.func_name=='ask_context_evnt'){
      showmore = true
    }
    let isDone = false;
    let _data = [];
    const evtSource = new EventSource(url, {withCredentials: true});
    evtSource.onmessage = function (event) {
      console.log("message", event);
      //_data.push({id:0,label:event.data.replaceAll('^^','\n')});
      //me.showAiConsole(me.state.inputText,me.state.inputType,_data,messageKey);
        if(event.data.length>0){
          if(event.data == "[DONE]"){
            isDone = true;
          }
          if(!isDone){
            if(event.data.includes("[DONE]")){
              me.showAiConsoleError(messageKey,this.state.inputType===0?intl.get('Custom.message.noChopResult'):intl.get('Custom.message.noChopDataResult'));
            }else{
              _data = [];
              let data_str = content===''?event.data:`${content}^^${intl.get('Custom.aiConsole.aiSendTypeText')[params.chop_way]}，您可以这样提问：^^${event.data}`;
              let data_arr = data_str.split('^^');
              if(data_arr.length>0){
                //content!=='' && data_arr.unshift(content);
                data_arr.forEach((item, idx) => {
                  _data.push({id:idx,label:item});
                });
                me.showAiConsole(params.node_nm||'',params.chop_way,_data,messageKey);
              }
            }
          }else{
            if(event.data.length>10){
              let sequences_str = event.data.replaceAll('[DONE]','');
              if(sequences_str.length>0){
                let sequences_arr = sequences_str.split(' ');
                let p = {
                  view_id : params.view_id,
                  sequence_node_ex : sequences_arr.join(',')
                }
                API_getNodeIds(p).then(response => {
                  if (response && response.data && response.data.code === 0 && response.data.data && response.data.data.length>0) {
                    let node_ids = response.data.data.map(item => item.id);
                    me.showAiConsoleNodes(messageKey,node_ids,params.node_nm||'',_data);
                  }
                }).catch(error => {
                  console.warn(`get api failed`,error);
                });

              }
            }else{
              if(showmore){
                me.showAiConsoleMore(messageKey,params.node_nm||'',_data);
              }
            }
            setTimeout(() => {
              evtSource.close();
            }, 1000);
          }
        }
    };
    evtSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      evtSource.close();
    };
  }


  checkInputText = (messageKey) => {
    let me = this;
    let searchText = me.state.inputText;
    let params ={
      keyword:me.state.inputText, 
      view_id:me.props.viewId
    }
    API_checkChopTxt(params).then(response => {
      if (response && response.data && response.data.code === 0) {
        if(response.data.data && response.data.data.length>0 && response.data.data[0].e===1){
          me.doChopdown(false,messageKey);
        }else{
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: intl.get('Custom.message.noCalculationResultForMap')});
        }
      } else {
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: intl.get('Custom.message.noCalculationResultForMap')});
      }
    }).catch(error => {
      console.warn(`get api failed`,error);
    });
  }
  onInputChange = e => {
    let me = this;
    e.stopPropagation();
    e.preventDefault();
    me.setState({inputText: e.target.value.trim()});
  }

  doHandleSendInput = (bus=false) => {
    let me = this;
    if(me.state.inputText && me.state.inputText.length>0){
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `${me.state.inputText}`
      });
      let messageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>{intl.get('Custom.message.loadExactMatch')}</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.doChopdown(false,messageKey);
      /*
      let params = {};
      API_getMapList(params).then(response => {
        console.error(response);
        if (response && response.data && response.data.code === 0) {
          resolve(response);
        } else {
          reject(response);
        }
      }).catch(error => {
        reject(error);
      });
      */
      /*  不在这里校验是否当前图谱相关节点
      if(bus){
        me.doChopdown(false,messageKey);
      }else{
        me.checkInputText(messageKey);
      }
      */
    }else{
      //请输入发送内容，为空不处理
    }
  };

  doChangerInputType = () => {
    let me = this;
    PB.emit('aiConsole', 'message.push', {
      type: 'user',
      content: intl.get('Custom.aiConsole.switchContext')
    });
    let messageKey;
    PB.emit('aiConsole', 'message.push', {
      type: 'ai',
      content: (
        <div>
          {intl.get('Custom.aiConsole.pleaseSwitchContext')}
          <ul className={style['ai-node-ul']}>
            {//intl.get('Custom.aiConsole.aiSendType').map((item,idx) => (
            //<li className={`${style['ai-node-li']} ${style['ai-node-li-action']}`} onClick={e => {e.stopPropagation();e.preventDefault();me.handleSelectChange(idx);}}>{item}</li>))
            }
          </ul>
        </div>
      ),
      delay: 200,
    });
  };

  handleSelectChange = (value) => {
    let me = this;
    let messageKey;
    me.setState({inputType: value}, () => {
      let content = `${intl.get('Custom.aiConsole.successfullySwitchContext')}${intl.get('Custom.aiConsole.aiSendType')[me.state.inputType]}`;
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content,
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.doSelectChange(value,messageKey,content);
    });
  };

  doSelectChange = (value = 0,messageKey,content) => {
    let me = this;
    let params ={
      view_id:me.props.viewId,
      //node_nm:me.state.inputText,
      chop_way:value,
      func_name:'ask_context_question'
    }
    if(intl.get('locale')==='zh-cn' && value<5){
      me.doEventSourse(params,messageKey,content);
    }
  }

  handleGetMore = () => {
    let me = this;
    PB.emit('aiConsole', 'message.push', {
      type: 'user',
      content: `获取更多内容`
    });
    let messageKey;
    PB.emit('aiConsole', 'message.push', {
      type: 'ai',
      content: `${me.state.inputText}`,
      callback: ({key}) => messageKey = key,
      delay: 200,
    });
    me.doGetMore(messageKey);  
  };

  doGetMore = (messageKey) => {
    let me = this;
    let params ={
      view_id:me.props.viewId,
      node_nm:me.state.inputText,
      chop_way:0,
      func_name:'ask_context_more'
    }
    me.doEventSourse(params,messageKey);
  }
      

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'aiConsole', 'show.on_hover_false',
      () => me.onHoverFalse());

    me.props.bus.sub(me, 'relationSearch', 'input.on_focus',
      () => me.setState({inputFocused: true}));

    me.props.bus.sub(me, 'relationSearch', 'input.on_blur',
      () => me.setState({inputFocused: false}));

    me.props.bus.sub(me, 'aiConsole', 'message.push', ({type, content, actions, callback, delay}) => {
      let key = `m-${Math.random()}`;
      let message = {
        type: type === 'ai' ? 'ai' : 'user',
        key,
        time: moment(),
        content,
        showAt: delay ? moment().add(delay, 'ms') : moment(),
        className: type === 'ai' ? (
          actions && actions.length > 0 ? 'has-action' : undefined
        ) : undefined,
        actions,
      };
      me.messages.push(message);
      me.messageMap[key] = message;
      if (delay) {
        message.refreshTimeout = setTimeout(() => {
          message.refreshTimeout = undefined;
          me.newMessages++;
          me.updateAndScroll();
        }, delay);
      } else {
        me.newMessages++;
        me.updateAndScroll();
      }
      callback && callback({key});
    });

    me.props.bus.sub(me, 'aiConsole', 'message.update', ({key, content, actions}) => {
      let message = me.messageMap[key];
      if (message) {
        if (message.refreshTimeout) clearTimeout(message.refreshTimeout);
        let now = moment();
        message.content = content;
        message.actions = actions;
        message.className = message.type === 'ai' ? (
          actions && actions.length > 0 ? 'has-action' : undefined
        ) : undefined;
        if (message.showAt) {
          if (now.isAfter(message.showAt) && now.diff(message.showAt, 'ms') < 500) {
            message.refreshTimeout = setTimeout(() => {
              message.refreshTimeout = undefined;
              me.updateAndScroll();
            }, now.diff(message.showAt, 'ms'));
          } else {
            message.showAt = now;
            me.updateAndScroll();
          }
        } else {
          me.updateAndScroll();
        }
      }
    });

    me.props.bus.sub(me, 'aiConsole', 'message.patch', ({key, content, actions}) => {
      let message = me.messageMap[key];
      if (message) {
        me.props.bus.emit('aiConsole', 'message.update', {
          key,
          content: content || message.content,
          actions: actions || message.actions,
        });
      }
    });

    me.props.bus.sub(me, 'aiConsole', 'message.remove', ({key}) => {
      let message = me.messageMap[key];
      if (message) {
        let pos = me.messages.findIndex(m => m.key === key);
        if (pos >= 0) {
          me.messages.splice(pos, 1);
        }
        delete me.messageMap[key];
        if (me.newMessages > 0) {
          me.newMessages--;
        }
        me.updateAndScroll();
      }
    });

    me.props.bus.sub(me, 'aiConsole', 'message.alert', ({key, type = 'danger'}) => {
      let message = me.messageMap[key];
      if (message) {
        let originalClassName = message.className, times = 2, loopFn = () => {
          message.className = originalClassName + ' ' + type;
          me.forceUpdate(() => {
            setTimeout(() => {
              message.className = originalClassName;
              me.forceUpdate(() => {
                times--;
                if (times > 0) setTimeout(loopFn, 300);
              });
            }, 300);
          });
        };
        me.updateAndScroll(key, loopFn);
      }
    });

    me.props.bus.sub(me, 'aiConsole', 'message.notice', ({key}) => {
      me.props.bus.emit('aiConsole', 'message.alert', {key, type: 'notice'});
    });

    me.props.bus.sub(me, 'aiConsole', 'status.do_change', ({status}) => {
      me.setState({status: status ? status : nextStatusMap[me.state.status]}, () => {
        me.props.bus.emit('aiConsole', 'status.changed', {status: me.state.status});
        requestAnimationFrame(() => me.updateAndScroll(me.messages[me.messages.length - 1].key));
      });
    });

    me.props.bus.sub(me, 'aiConsole', 'input.changed', ({inputText}) => {
      me.setState({inputText}, () => {
        me.elementInputForSend.focus();
      });
    });

    me.props.bus.sub(me, 'aiConsole', 'input.send', ({inputText}) => {
      me.setState({inputText}, () => {
        me.elementInputForSend.focus();
        me.doHandleSendInput(true);
      });
    });
    

    requestAnimationFrame(() => me.initElementXY());
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    let lastTime, lastShownTime, now = moment();
    return me.props.hideAiDialogBoxFlag===1?(''):(
      <div
        id="ai_console"
        className={`dark-theme ${style['console-draggable-frame']} height-level-${me.state.heightLevel} ${me.state.status} ${me.dragging||me.state.aiHover ? style['console-draggable-aiHover'] : ''}`}
        style={{left: `${me.state.translationX}px`,top: `${me.state.translationY}px`,visibility: SysUIConfig.RelationAIConsole.visibility}}
        ref={ele => {
          if (!ele || ele === me.element) return;
          me.element = ele;
        }}
        onContextMenu = {() => {window.event.returnValue=false;return false;}}
        onClick={e => me.onHoverTrue(e)}
      >
        <EventListener
          target={window}
          onMouseMove={e => me.onDragging(e)}
          onMouseUp={() => me.onDragEnd()}
        />
        <div
          className={`${style['console-draggable-btn']} ${me.dragging||me.state.aiHover ? 'dragging' : ''}`}
          onMouseDown={e => me.onDragStart(e)}
          onMouseUp={() => me.onDragEnd()}
          onDoubleClick={() => me.dockComponent()}
          ref={ele => {
            if (!ele || ele === me.moveBarElement) return;
            me.moveBarElement = ele;
            me.initElementXY();
          }}
        >
          <Tooltip title={intl.get('Custom.aiConsole.drag')}><Icon name={'icon-move'} theme="outlined" type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}}/>
          {intl.get('Custom.aiConsole.aiBox')}</Tooltip> 
          <span style={{right: 0, position: 'absolute'}}>
            {
              me.state.heightLevel > 0 && me.state.heightLevel < 3 ? (
                me.prevHeightLevel < me.state.heightLevel ? (
                  <Tooltip title={intl.get('Custom.aiConsole.increase')}>
                    <Button
                      className={'ant-btn-icon-only'}
                      disabled={me.state.heightLevel >= 3}
                      onClick={e => me.setHeightLevel(e, me.state.heightLevel + 1)}
                      onMouseDown={e => e.stopPropagation()}
                    >
                      <Icon type={IconTypes.ICON_FONT} name={'icon-increase-height'}/>
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={intl.get('Custom.aiConsole.lower')}>
                    <Button
                      className={'ant-btn-icon-only'}
                      disabled={me.state.heightLevel <= 0}
                      onClick={e => me.setHeightLevel(e, me.state.heightLevel - 1)}
                      onMouseDown={e => e.stopPropagation()}
                    >
                      <Icon type={IconTypes.ICON_FONT} name={'icon-decrease-height'}/>
                    </Button>
                  </Tooltip>
                )
              ) : (
                me.state.heightLevel <= 0 ? (
                  <Tooltip title={intl.get('Custom.aiConsole.increase')}>
                    <Button
                      className={'ant-btn-icon-only'}
                      disabled={me.state.heightLevel >= 3}
                      onClick={e => me.setHeightLevel(e, me.state.heightLevel + 1)}
                      onMouseDown={e => e.stopPropagation()}
                    >
                      <Icon type={IconTypes.ICON_FONT} name={'icon-increase-height'}/>
                    </Button>
                  </Tooltip>
                ) : (
                  me.state.heightLevel >= 3 ? (
                    <Tooltip title={intl.get('Custom.aiConsole.lower')}>
                      <Button
                        className={'ant-btn-icon-only'}
                        disabled={me.state.heightLevel <= 1}
                        onClick={e => me.setHeightLevel(e, me.state.heightLevel - 1)}
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <Icon type={IconTypes.ICON_FONT} name={'icon-decrease-height'}/>
                      </Button>
                    </Tooltip>
                  ) : null
                )
              )
            }
          </span>
        </div>
        <ScrollElement
          id={me.state.containerId}
          className={`scrollbar-none ${style['console-frame']}`}
          style={{overflow: 'hidden auto'}}
          onScroll={e => {
            let element = e.target;
            if (element.scrollHeight - element.scrollTop - element.clientHeight < 10) {
              me.autoScroll = true;
              if (me.newMessages > 0) {
                me.newMessages = 0;
                me.forceUpdate();
              }
            } else {
              me.autoScroll = false;
            }
          }}
        >
          {
            me.messages.map(message => {
              if (message['showAt'] && now.isBefore(message['showAt'])) return null;
              let timeSpan = undefined;
              if (!lastShownTime) {
                lastTime = message.time;
                lastShownTime = message.time.clone();
                timeSpan = (
                  <Divider key={`t-${lastShownTime.valueOf()}`}>
                    <TimeDisplayField timestamp={lastShownTime.valueOf()}/>
                  </Divider>
                );
              } else if (lastTime.diff(message.time, 'm') <= -5 || lastShownTime.diff(message.time, 'm') <= -15) {
                lastTime = message.time;
                lastShownTime = message.time.clone();
                timeSpan = (
                  <Divider key={`t-${lastShownTime.valueOf()}`}>
                    <TimeDisplayField timestamp={lastShownTime.valueOf()}/>
                  </Divider>
                );
              } else {
                lastTime = message.time;
              }
              return (
                <React.Fragment key={`g-${message.key}`}>
                  {timeSpan ? timeSpan : null}
                  <div
                    key={message.key}
                    className={`${style['console-message']} ${message['className'] ? message['className'] : ''} ${message.type}-message`}
                  >
                    <div className={style['console-message-avatar']}>
                      {
                        message.type === 'ai' ? (
                          <div
                            className={`${style['console-message-avatar-content']} ${style['avatar-computer-icon']}`}
                            style={{float: 'left'}}
                          >
                            <UserAvatar size={'var(--avatar-size)'} name={intl.get('Custom.general.title')} colors={['#3498db']}/>
                          </div>
                        ) : null
                      }
                    </div>
                    <div className={`${style['console-message-content']}`}>
                      <div
                        className={`${style['console-message-popover']} ${message.type} ant-popover ant-popover-placement-${message.type === 'ai' ? 'rightTop' : 'leftTop'}`}
                        style={{float: message.type === 'ai' ? 'left' : 'right',marginBottom:me.state.status === 'active' && message['actions'] && message['actions'].length > 0 ?'1rem':'0'}}
                      >
                        <div className={'ant-popover-content'}>
                          <div className="ant-popover-arrow"><span className="ant-popover-arrow-content"/></div>
                          <div className="ant-popover-inner" role="tooltip">
                            <div className={style['console-message-popover-msg']}>
                              {message.content}
                            </div>
                            {
                              me.state.status === 'active' && message['actions'] && message['actions'].length > 0 ? (
                                <React.Fragment>
                                  <hr/>
                                  <div className={style['console-message-popover-action']}>
                                    {message['actions']}
                                    <div style={{userSelect: 'none', display: 'inline-block'}}/>
                                  </div>
                                </React.Fragment>
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={style['console-message-avatar']}>
                      {
                        message.type === 'user' ? (
                          <div
                            className={
                              `${style['console-message-avatar-content']} ${me.props.currentUserInfo.picId ? '' : style['avatar-default-icon']}`
                            }
                            style={{float: 'right'}}
                          >
                            <UserAvatar
                              size={'var(--avatar-size)'}
                              name={me.props.currentUserInfo.nick}
                              src={
                                me.props.currentUserInfo.picId ?
                                  `${REQUEST_BASE}/user/user/file/${me.props.currentUserInfo.picId}?Authorization=${getToken()}` :
                                  undefined
                              }
                              colors={AvatarColors}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </ScrollElement>
        <div
          className={style['new-message-frame']}
          style={{display: (!me.autoScroll && me.newMessages > 0) ? 'block' : 'none'}}
        >
          <span onClick={() => me.updateAndScroll(me.messages[me.messages.length - 1].key)}>
            {intl.get('Custom.message.youhave')} {me.newMessages} {intl.get('Custom.message.newMessages')}
          </span>
        </div>
        <div className={`${style['console-draggable-bottom']} ${me.dragging||me.state.aiHover ? 'dragging' : ''}`}>
          {
          //<div className={style['console-draggable-sendtype-box']} style={{display:'none'}}>
          //  <span>{intl.get('Custom.aiConsole.currentContext')}{intl.get('Custom.aiConsole.aiSendType')[me.state.inputType]}</span>
          //  <Dropdown overlayClassName={'dark-theme'} placement="topRight"
          //    overlay={
          //      <Menu style={{textAlign: 'center'}}>
          //        {intl.get('Custom.aiConsole.aiSendType').map((aiSendType, i) => (
          //        <Menu.Item onClick={e => {me.handleSelectChange(i);}}><Icon type={IconTypes.ICON_FONT} name={aiSendTypeIcons[i]}/>{intl.get('Custom.aiConsole.aiSendType')[i]}</Menu.Item>))}
          //      </Menu>
          //      //onClick={e => {e.stopPropagation();e.preventDefault();me.doChangerInputType();}}             
          //  }>
          //    <span className={style['console-draggable-sendtype-action']}>【{intl.get('Custom.form.switch')}】</span>  
          //  </Dropdown>
          //</div>
          }
          <Input.Group compact>
            <Input placeholder={intl.get('Custom.form.sendPlaceholder')}
              style={{borderRadius:0 }}
              bordered={false}
              defaultValue=""
              ref={el => me.elementInputForSend = el}
              onChange={(e) => me.onInputChange(e)}
              onPressEnter={e => {
                e.stopPropagation();
                e.preventDefault();
                me.doHandleSendInput();
              }}
            />
            <Button className={style['console-input-send']} onClick={e => {e.stopPropagation();e.preventDefault();me.doHandleSendInput();}}>
              <Icon name="icon-navigation-o" type={IconTypes.ICON_FONT}/> {intl.get('Custom.form.send')}
            </Button>
          </Input.Group>
          
        </div>
      </div>
    );
  }
}

RelationAIConsole.defaultProps = {
  bus: PB,
  currentUserInfo: {userId: parseInt(localStorage.getItem('userId'))},
  viewId: '',
};

RelationAIConsole.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  currentUserInfo: PropTypes.object,
  initialLeft: PropTypes.number,
  initialTop: PropTypes.number,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  viewId: PropTypes.string
};

export default RelationAIConsole;

const stopAnimation = animations => {
  /*
   This used to just pause any remaining animation
   but anime gets stuck sometimes when an animation
   is trying to tween values approaching 0.

   Basically to avoid that we're just forcing near-finished
   animations to jump to the end.

   This is definitely a hack but it gets the job done—
   if the root cause can be determined it would be good
   to revisit.
   */
  const stop = anim => {
    const {duration, remaining} = anim;
    if (remaining === 1) anim.seek(duration);
    else anim.pause();
  };
  if (Array.isArray(animations)) animations.forEach(anim => stop(anim));
  else stop(animations);
};
