import Pagination from "rc-pagination/es/locale/en_US";
import DatePicker from '../date-picker/locale/en_US';
import TimePicker from '../time-picker/locale/en_US';
import Calendar from '../calendar/locale/en_US';
export default {
  locale: 'en',
  Pagination: Pagination,
  DatePicker: DatePicker,
  TimePicker: TimePicker,
  Calendar: Calendar,
  global: {
    placeholder: 'Please select'
  },
  Table: {
    filterTitle: 'Filter menu',
    filterConfirm: 'OK',
    filterReset: 'Reset',
    selectAll: 'Select current page',
    selectInvert: 'Invert current page',
    sortTitle: 'Sort',
    expand: 'Expand row',
    collapse: 'Collapse row'
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Cancel',
    justOkText: 'OK'
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Cancel'
  },
  Transfer: {
    titles: ['', ''],
    searchPlaceholder: 'Search here',
    itemUnit: 'item',
    itemsUnit: 'items'
  },
  Upload: {
    uploading: 'Uploading...',
    removeFile: 'Remove file',
    uploadError: 'Upload error',
    previewFile: 'Preview file',
    downloadFile: 'Download file'
  },
  Empty: {
    description: 'No Data'
  },
  Icon: {
    icon: 'icon'
  },
  Text: {
    edit: 'Edit',
    copy: 'Copy',
    copied: 'Copied',
    expand: 'Expand'
  },
  PageHeader: {
    back: 'Back'
  },
  DateTime_c:{
    weekName:['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    dayName:['today ', 'yesterday ', 'the day before yesterday '],
    dayText:[' this ',' last ',' days before ',' days after '],
    timeText:[' before ',' after ']
  },
  Custom: {
    general:{
      title: 'JoinMap',
      titleMeta: 'We map Value',
      join: 'Joinmap Now',
      load: 'load',
      loading:'loading',
      more: 'more',
      add:'add',
      creat: 'creat',
      feedback: 'feedback',
      help: 'help',
      operationGuide: 'view the operation guide',
      projectVideo:'Project video',
      note:{
        changer:'The contents of the note have changed',
        hidewindow:'Hide note window',
        showwindow:'Show note window'
      },
      message:"Message center",
      weekIntegral:'Points this week',
      thisIntegral:'Points of this map',
      allIntegral:'Points of whole maps',
      userCenter:'user center',
      baseInfo:'basic information',
      setPassword:'set password',
      changePassword:'change password',
      modifyMailbox:'modify mailbox',
      bindMailbox:'bind mailbox',
      bindWeChat:'bind weChat',
      signOut:'sign out',
      login:'sign in',
      logining:'signining',
      loginSuccess:'sign in success',
      register:'sign up',
      resourceLoading:'resource roading',
      scanLogin:'Scan code to sign in',
      pWechatLogin:'Please sign in using WeChat scanning code',
      qrcodeExpired:'QR code has expired, please',
      accountLogin:'account sign in',
      wechatLogin:'wechat sign in',
      wechat:'wechat',
      noNeedPassword:'No need to set password',
      enterEmail:'Please enter email',
      haveAccount:"Already have an account?",
      noAccount:"Don't have an account yet?",
      forgotPassword:'Forgot your password?',
      loginNow:'Sign In Now',
      registerNow:'Sign Up Now',
      inputPassword:'input a password',
      pInputPassword:'Please input a password',
      refresh:' refresh',
      next:'next',
      requestWait:'Request in progress, please wait...',
      new:'new',
      operationSucceeded:'success',
      searchMore:'search more',
      addToMap:'add to map',
      connectNode:'Connect to the current node',
      copySelectedNode:'Copy selected node text',
      switchNext:'Switch to next batch',
      operationTerminated:'Operation terminated',
      pleasePress:'Please press',
      latestNews:'Latest News',
      attentionReport:'Pay attention to the latest trends of the keynote report and understand the development trend.',
      of:"'",
      news:'News',
      dynamicInformationBase:'Dynamic information base'
    },
    form:{
      checkAll:'Check all',
      enterKeywordSearch:'Enter keyword search',
      searchInMap:'Search in the map',
      signSpace:'Try words separated by a greater than sign or space',
      send:'Send',
      sendPlaceholder:'Talk to robot',
      switch:'Switch'
    },
    outLink:{
      bilibili:'bilibili',
      wx_video:'wechat channels',
      wx_mini:'wechat mini programs',
    },
    notification:{
      screenWidthAlert: ['If you run Joinmap on a 4K display in full screen, ','you will get an excellent visual experience.'],
    },
    message:{
      loadFail:'Failed to load more data. Please try again later',
      masterRight:'Only the moderator can add collaboration members. Please contact the moderator.',
      welcome:'Welcome to JoinMap!',
      youhave:'You have',
      newMessages:'new messages',
      nodowith:['The current node has little to do with other nodes'],
      noRecommendedNodes:['Because there are not enough nodes,there are no recommended nodes to connect',''],
      calculation:['In calculation, you can continue other operations.The results will be displayed in the dialog box on the right after a few seconds.', ''],
      noCalculationResultRedo:['No calculation results,', 'Retrying...'],
      noCalculationResult:['No calculation results,', 'You can try again later'],
      noCalculationResultForMap:'There is no node related to your question,try to include a keyword of any node.',
      noCalculationResultSame:'The content you entered is the same as the last time. You can view the results of the last time directly.',
      calculationCompleted:'Calculation completed, please check the results in AI dialog box',
      pleaseSelectConnected:'Please select the node to be connected first.',
      pleaseSelectAdded:'Please select the node to be added first.',
      recommendedClipboard:'The recommended candidate information has been copied to the clipboard.',
      selectNodeCopy:'Please select the node to copy the text content first.',
      copySelectedText:'Copy the selected node text.',
      switchDialogStatus:'Click to switch the dialog box status. Currently:',
      loadExactMatch:'Calculating…',
      loadingUnderlay:'Loading underlay, please wait…',
      loadingFavorite:'Loading my favorite, please wait…',
      calculatingOptimization:'Calculating load optimization path...',
      cooperation:['This map allows open collaboration,', 'You will become a member of the collaboration and can create your node!'],
      noChopResult:['The current graph does not seem to be related to the question, you can ','1. Add some material nodes related to the problem to the current graph, \n 2. Alternatively, you can switch the context to the dynamic information database and try asking again'],
      noChopDataResult:['No materials related to your question were found, you can ',
      '1. Contact us hi@joinmap.ai Customize your exclusive dynamic information library; \n 2. Alternatively, you can freely accumulate information in the graph and gradually form your own information base.',
      'The information database can gather fragmentation information related to you, such as online information, work information, reading notes, daily chores, etc. Robots can sort out and search these information to provide you with auxiliary decision support.'],
      viewOperation:{
        addFiles: 'Uploading, please wait……',
        addRelation: 'Adding association, please wait……',
        addTextNodes: 'Adding node, please wait……',
        loadBriefingInfoList: 'Loading briefing……',
        loadData: 'Loading map data……',
        loadDynamicData: 'Loading dynamic analysis data……',
        loadDataSet: 'Loading dataset information, please wait……',
        loadFileList: 'Loading file list, please wait……',
        loadRelatedClue: 'Semantic discovery in progress……',
        loadRelatedResource: 'Resource association is in progress……',
        loadSubViewData: 'Resource association is in progress. Computing node association information. Please wait……',
        recoverRelationGraph: 'Processing, please wait……',
        removeFiles: 'Deleting file, please wait……',
        removeNode: 'Deleting node, please wait……',
        removeRelation: 'Deleting association, please wait……',
        removeRelationGraph: 'Processing, please wait……',
        saveNodeWithRelations: 'Reserving node, please wait……',
        saveRelationGraph: 'Saving diagram, please wait……',
        smartSearchGraphInAllView: 'Relationship structure association is in progress, please wait patiently……',
        updateConfig: 'Saving……',
        updateFilesRemark: 'Setting up, please wait……',
        updateNodeInfo: 'Updating node information……',
        voteExplorationResultByNode: 'The evaluation is being submitted, please wait……',
        voteExplorationResultBySubview: 'The evaluation is being submitted, please wait……',
      }
    },
    aiConsole:{
      aiBox:'AI Dialog',
      drag:'Hold down drag, double-click auto dock',
      increase:'Increase dialog height',
      lower:'Lower dialog height',
      mapLoaded:'The map data is loaded',
      popFilterBar:'Move the mouse to the left side of the interface to pop up the filter bar',
      clickReport:'Click the "Report" button at the top of the filter bar to view the special report of this map',
      clickLatestNews:'Click the "Latest News" in the special report to view the latest nodes of this map',
      aiSendType:['This map','Dynamic information base'],
      currentContext:'Current conversation context:',
      switchContext:'Switch conversation context',
      pleaseSwitchContext:'To provide you with the conversation context, please switch:',
      successfullySwitchContext:'Successfully switched the conversation context for you to:'
    },
    leader:{
      swiperTitle_0: 'Create dynamically associated notes',
      swiperContent_0: ['Novel relevance input', 'Associative search adapted to the brain', 'Easy establishment of a leafy knowledge system'],
      swiperTitle_1: 'Link data sensing network',
      swiperContent_1: ['Open data', 'Hidden clues','Digital interaction in parallel world '],
      swiperTitle_2: 'Generate grand and exquisite atlas',
      swiperContent_2: ['Vast stars',' Innovation implication ',' Amazing science and technology aesthetics'],
      swiperTitle_3: 'Share cognitive insights',
      swiperContent_3: ['Discover interesting stories','Experience the joy of exploration ',' Share valuable insights with the world '],
      swiperTitle_4: 'Overview of industrial resources',
      swiperContent_4: ['A complete picture of the industry at a glance', 'Theme report of free editor', 'Enterprises and talents with twinkling stars'],
      swiperTitle_5: 'Push team collaboration tasks',
      swiperContent_5: ['Encouraging tactical resources','Wall map operations looking down on the whole ','Mission scenario solutions'],
      swiperTitle_6: 'Through education and training thinking',
      swiperContent_6: ['Knowledge structure of neuron model', 'Interesting and spreading communication', 'Profound cognitive network analysis'],
    },
    channel:{
      sysName:['Team leader', 'Team member', 'Public readonly', 'Public participating', 'Public replicable', 'Latest'],
      simpleChannel:['All','Team','Public']
    },
    menu:{
      properties:'Map Properties',
      basicProperties:'Basic Properties',
      setUnderlay:'Set Underlay',
      chinaMap:'Set up China map',
      uploadImage:'Upload Base Image',
      clearImage:'Clear Base Image',
      setBackground:'Set Background',
      titlePicture:'Title Picture',
      optimizeLoading:'Optimize Loading',
      cancelOptimization:'Cancel Optimization',
      hideAIDialogBox:'Hide AI Dialog Box',
      showAIDialogBox:'Show AI Dialog Box',
      hideInputBox:'Hide Input Box',
      showInputBox:'Show Input Box',
      autoDim:'Auto Dim',
      allNodesOn:'All Nodes On',
      currentNodesOn:'Current Nodes On',
      autoDimTime:'Nodes Automatically Dims Over Time',
      currentAutoDimTime:'Current Nodes Automatically Dims Over Time',
      autoDimLevel:'Nodes Automatically Dims By Level',
      currentAutoDimLevel:'Current Nodes Automatically Dims By Level',
      latestOverview:'Latest overview',
      mapAuthority:'Map Authority',
      accessSettings:'Access Settings',
      ticketManagement:'Ticket Management',
      mapTransfer:'Map Transfer',
      unlockEdit:'Unlock Edit',
      lockEdit:'Lock Edit',
      copyMap:'Copy Map',
      mutualErasure:'Mutual Erasure',
      prohibitEditOthers:"Prohibit members from deleting or modifying others' nodes",
      allowEditOthers:"Allow members to delete and modify others' nodes",
      mapNode:'Map Nodes',
      generateReport:'Generate Report',
      nodesStatistics:'Nodes Statistics',
      keynoteReport:'Keynote Report',
      relationshipClues:'Relationship clues',
      enclosure:'Map Enclosure',
      allNodesFixed:'All Nodes Fixed',
      allNodesMoved:'All Nodes Moved',
      allNodesLines:'All Nodes Lines',
      allNodesCurves:'All Nodes Curves',
      exportSave:'Export Save',
      advancedExport:'Advanced Export',
      exportPicture:'Export Picture',
      exportAll:'Export All',
      generateLink:'Generate Link',
      customPrinting:'Custom Printing',
      training:'Intelligent Training',
      byTime:'By Time',
      byGeography:'By geography',
      byRelationship:'By Relationship',
      byCard:'By Card',
      opex:'OPEX',
      panoramaView:'Panorama View',
      microservices:'Microservices',
      export:'Export',
      printing:'Printing',
      switchDimMode:'Switch Dim Mode',
      affectsVisual:'(only affects the visual effect)',
      keepAllBright:'Keep all bright',
      adjustSpacing:'Adjust node spacing',
      switchNodeLayout:'Switch node layout',
      gravitationalContractionLayout:'Gravitational contraction layout',
      expansionExtensionLayout:'Expansion and extension layout',
      layoutRightLeft:'Layout from right to left',
      layoutLeftRight:'Layout from left to right',
      layoutTopDown:'Top-down layout',
      layoutBottomUp:'Bottom-up layout',
      arrowMark:'Arrow mark',
      showAllNodes:'Show all nodes',
      displayWordCloud:'Display the word cloud of all nodes in this map',
      autoRefreshing:'Start automatic refreshing of map data',
      autoRefreshingStop:'The map data is being refreshed automatically, click Stop',
      ShowHideSearch:'Show/hide search bar',
      ShowHideInput:'Show/hide input bar',
      ShowHideAI:'Show/hide AI Box',
      ShowHideMicroservices:'Show/hide microservices',
      ShowHideView:'Show/hide view',
      selectPlaySpeed:'Select playback speed',
      slowlyPlay:'Play Slowly',
      constantPlay:'Play Constant Speed',
      quickPlay:'Play Quick',
      resumeAutomatic:'Space/click button: resume automatic play',
      clickStopPause:'Click the button to stop, and press the space bar to pause',
      showNodeEditing:'Displays the chronological order of node editing',
      hideNodeEditing:'Hide the chronological order of node editing'
    },
    filter:{
      all:'All',
      highlightAllNodes:'Highlight all nodes',
      report:'Report',
      highlightReport:'Highlight Keynote Report',
      reportTip:'Highlight the node in the recently played keynote report',
      favorite:'favorite',
      favoriteTip:'Only visible to me after setting',
      important:'important',
      importantTop:'All members of this atlas are visible after setting',
      find:'find',
      findTip:'Highlight nodes created by functions such as discovering activities/discovering people',
      selected:'selected',
      selectedTip:'Highlight other nodes associated with the selected node',
      type:'type',
      connect:'connect',
      today:'today',
      todayTip:'Highlight the node updated today',
      yesterday:'yesterday',
      yesterdayTip:'Highlight the node updated yesterday',
      before:'before yesterday',
      beforeTip:'Highlight the node updated the day before yesterday',
      isolated:'isolated',
      isolatedTip:'Highlight all isolated nodes',
      connectMe:'connect me',
      connectMeTip:'Highlight other user nodes associated with my node',
      highlightAll:'Highlight all ',
      typeNodes:" type nodes"

    },
    view:{
      add:'new map',
      name:'map',
      addInfo:"You don't have the map yet. Hurry to create a new one……",
      eventMap:'event map',
      list: 'map market',
      openSettings:'open settings',
      memberList:'member list',
      editbase:'Modify the basic information of the map',
      putMemberlit:'Collapse the list of collaboration members',
      expandMemberlit:'Expand collaboration member list',
      seeMemberlit:'View member list',
      member:'member',
      newNodes:'latest nodes',
      node:'node',
      backgroundStatus:'quiet mode',
      hiddenStatus: 'hidden mode',
      activeStatus: 'active mode',
      searchMaps:'Search Maps',
      searchNodes:'Search Nodes',
      previousNode:'Previous node',
      nextNode:'next node',
      rambling:'Rambling',
      left:'left',
      screen:'Filter',
      leftFilter:'Left Filter',
      customList:'Custom list',
      system:'system',
      public:'public',
      autoPlay:'Click the button to play automatically'
    }
  }
};