import _ from 'lodash';
import moment from 'moment';
import {Store} from 'redux';
import {IDBPObjectStore} from 'idb';
import {DataSet} from '@/libs/vis';
import {SimplePB} from "@/libs/simplePB";
import {
  TYPE_FIELD_NAME,
  NODE_TYPE_TEXT,
  NODE_TYPE_COMPANY,
  NODE_TYPE_TALENT,
  NODE_TYPE_TAG,
  NODE_TYPE_PATENT,
  NODE_TYPE_PAPER,
  NODE_TYPE_DOCS,
  NODE_TYPE_NEWS_ACTIVITIES,
  getNodeDisplayTitle,
} from "@/constants/vis.defaultDefine.1";

// 本地引入
import Edge, {defaultEdgeId} from '../Edge';
import Node from '../Node';
import {
  NetworkDataLoadingStatus,
  NodeExpandingStatus,
  NodeGrowingStatus,
  NodeExactMatchingStatus,
  NetworkSubViewMatchingStatus,
  NetworkFileListLoadingStatus,
} from "./status";
import {
  ADD_TO_GRAPH,
  ADD_TO_VIEW,
  EdgeEvents,
  NetworkEvents,
  NodeEvents,
  REMOVE_FROM_GRAPH,
  REMOVE_FROM_VIEW,
} from "./events";
import {bindUtil, privateProtector} from "@/libs/core-decorators";
import {
  API_ExpandNode,
  API_GrowNode,
  //API_GetRelationData,
  //API_RemoveEdge,
  //API_RemoveNode,
  //API_GetNodeInfo,
  //API_UpdateNodeInfo,
  API_UpdateFilesRemark,
  API_UploadProperty,
  API_RemoveFile,
  API_RemoveFileItem,
  API_ExactMatchesByNode,
  API_MatchesBySubView,
  API_GetFileList,
  API_ExploreByNode,
  API_VoteExplorationResultBySubview,
  API_VoteExplorationResultByResource,
  API_ExploreBySubView,
  API_MatchTagsByNode,
  API_MatchResourcesByNode,
  //API_AddRelationGraph,
  API_LoadBriefingInfoList,
  API_AccessNode,
  API_LoadViewConfig,
  API_UpdateViewConfig,
  API_LoadViewIndexValue,
  API_ExploreAsync,
  API_ExploreAsyncByNum,
  API_ExploreAsyncByProps,
  API_ExploreSyncByConnection,
  API_ExploreSyncByDomainPerson,
  API_ExploreSyncBySimilarity,
  //API_RemoveNodes,
  //API_UpdateEdgeInfoList,
  API_SmartSearchInView,
  //API_BatchUpdateNodeInfoPartially,
  API_LoadRankData,
  API_LoadRankData_V2,
  API_LoadViewUserConfig,
  API_UpdateViewUserConfig,
  API_GravityNodeIdListInView,
  API_SearchWordListInView,
  API_UpdateNodeInfoPartially,
  API_LockView,
  API_UnlockView,
  API_GetViewById,
  API_GetViewStructureGraph,
  API_SetViewBadge,
  API_SmartSearchGraphInAllView,
  API_SmartSearchNodeInAllView,
  API_SmartSearchUserInAllView,
  API_RecommendByView,
  //API_RecoverRelationGraph,
  //API_RemoveRelationGraph,
  API_GetViewCover,
  API_RecommendCompanyByView,
  //API_GetRelationStructure,
  //API_GetRelationNodeList,
  API_AccessView,
  API_DoMicroServiceByView,
  //API_RemoveNoneFixedNodePositionsByView,
  API_GraphNodeList
} from "@/libs/view/network/api";

import {
  // API_ExpandNode,
  // API_GrowNode,
  API_GetRelationData,
  API_RemoveEdge,
  API_RemoveNode,
  API_GetNodeInfo,
  API_UpdateNodeInfo,
  // API_UpdateFilesRemark,
  // API_UploadProperty,
  // API_RemoveFile,
  // API_RemoveFileItem,
  // API_ExactMatchesByNode,
  // API_MatchesBySubView,
  // API_GetFileList,
  // API_ExploreByNode,
  // API_VoteExplorationResultBySubview,
  // API_VoteExplorationResultByResource,
  // API_ExploreBySubView,
  // API_MatchTagsByNode,
  // API_MatchResourcesByNode,
  API_AddRelationGraph,
  // API_LoadBriefingInfoList,
  // API_AccessNode,
  // API_LoadViewConfig,
  // API_UpdateViewConfig,
  // API_LoadViewIndexValue,
  // API_ExploreAsync,
  // API_ExploreAsyncByNum,
  // API_ExploreAsyncByProps,
  // API_ExploreSyncByConnection,
  // API_ExploreSyncByDomainPerson,
  // API_ExploreSyncBySimilarity,
  API_RemoveNodes,
  API_UpdateEdgeInfoList,
  // API_SmartSearchInView,
  API_BatchUpdateNodeInfoPartially,
  // API_LoadRankData,
  // API_LoadRankData_V2,
  // API_LoadViewUserConfig,
  // API_UpdateViewUserConfig,
  // API_GravityNodeIdListInView,
  // API_SearchWordListInView,
  // API_UpdateNodeInfoPartially,
  // API_LockView,
  // API_UnlockView,
  // API_GetViewById,
  // API_GetViewStructureGraph,
  // API_SetViewBadge,
  // API_SmartSearchGraphInAllView,
  // API_SmartSearchNodeInAllView,
  // API_SmartSearchUserInAllView,
  // API_RecommendByView,
  API_RecoverRelationGraph,
  API_RemoveRelationGraph,
  // API_GetViewCover,
  // API_RecommendCompanyByView,
  API_GetRelationStructure,
  API_GetRelationNodeList,
  // API_AccessView,
  // API_DoMicroServiceByView,
  API_RemoveNoneFixedNodePositionsByView,
  // API_GraphNodeList
} from "@/libs/view/network/map_v2_api";

import {NetworkDataReloadingStatus, NetworkDataSetLoadingStatus} from "@/libs/view/network/status";
import {getShortestPath, splitNodeGroups} from "@/libs/graph-utils";
import {longestCommonSubstring} from "@/components/common/common.functions";
import {MULTIMEDIA_EXT} from "@/constants/common";
import {
  getCachedViewInfo,
  getCachedViewNodesStatus,
  getCachedViewStructureStatus,
} from "@/redux-saga/view/selectors";
import {
  invalidateViewDataCacheAction,
  loadViewInfoSuccessAction,
  loadViewNodesSuccessAction,
  loadViewStructureSuccessAction, validateStoreAction,
} from "@/redux-saga/view/actions";
import {getCacheStore} from '@/storage/idbStorage';

/**
 * @typedef {{
 *   classifiedNodes: Object.<string, Node[]>,
 *   edges: [Edge],
 *   errorCode: number,
 *   errorMsg: string,
 *   nodeId: boolean|string,
 *   nodes: [Node],
 *   status: string
 * }} ExactMatchResult
 */

/**
 * @typedef {{
 *   classifiedNodes: Object.<string, Node[]>,
 *   edges: [Edge],
 *   errorCode: number,
 *   errorMsg: string,
 *   id: boolean|string,
 *   nodes: [Node],
 *   status: string
 * }} MatchResult
 */

/**
 * @typedef {{
 *   id: string,
 *   name: string,
 *   displayName: string,
 *   nodeIds: string[],
 *   nodes: Node[],
 *   edges: Edge[],
 *   resourceIds: string[],
 *   resources: Node[],
 *   tags: string[],
 *   version: int
 * }} SubViewInfo
 */

/**
 * @typedef {{
 *   types: number[],
 *   keywords: string[],
 *   areas: {
 *     province: string,
 *     city: string
 *   }[],
 *   time: string
 * }} ViewOptions
 */

/**
 * @typedef {{
 *   allNodes: [Node],
 *   currentlyShowingAmount: number,
 *   currentEdges: [Edge],
 *   currentNodes: [Node],
 *   currentSolidEdgeIds: [string],
 *   currentSolidNodeIds: [string],
 *   currentStartPos: number,
 *   errorCode: number,
 *   errorMsg: string,
 *   newEdges: [Edge],
 *   newNodes: [Node],
 *   newNodesMap: {},
 *   nextStartPos: number,
 *   nodeId: boolean|string,
 *   processingNodeIds: [string],
 *   status: string
 * }} RelatedClueResult
 */

/**
 * @typedef {{
 *   allNodes: [Node],
 *   currentlyShowingAmount: number,
 *   currentEdges: [Edge],
 *   currentNodes: [Node],
 *   currentSolidEdgeIds: [string],
 *   currentSolidNodeIds: [string],
 *   currentStartPos: number,
 *   errorCode: number,
 *   errorMsg: string,
 *   newEdges: [Edge],
 *   newNodes: [Node],
 *   newNodesMap: {},
 *   nextStartPos: number,
 *   nodeId: boolean|string,
 *   processingNodeIds: [string],
 *   status: string
 * }} RelatedResourceResult
 */

/**
 * 节点断言函数
 *
 * @callback NodePredicate
 * @param {Node} node 节点实例
 * @param {number} idx 节点下标
 * @param {[Node]} nodes 所有节点
 */

/**
 * @type {Readonly<{
 *   DATA_SET: {label: string, enabled: boolean, key: number},
 *   INSTITUTE: {label: string, enabled: boolean, key: number},
 *   TALENT: {label: string, enabled: boolean, key: number},
 *   ORG: {label: string, enabled: boolean, key: number},
 *   INDEX: {label: string, enabled: boolean, key: number},
 *   USER: {label: string, enabled: boolean, key: number},
 *   CHART: {label: string, enabled: boolean, key: number},
 *   GOV: {label: string, enabled: boolean, key: number},
 *   COMPANY: {label: string, enabled: boolean, key: number}
 * }>}
 */
export const MatchCategories = Object.freeze({
  INDEX: {
    // 指数
    enabled: true,
    label: '指数',
    key: 0,
  },
  CHART: {
    // 图表
    enabled: true,
    label: '图表',
    key: 1,
  },
  DATA_SET: {
    // 数据集
    enabled: true,
    label: '数据集',
    key: 2,
  },
  GOV: {
    // 政府
    enabled: true,
    label: '政府',
    key: 3,
  },
  ORG: {
    // 社团
    enabled: true,
    label: '社团',
    key: 4,
  },
  INSTITUTE: {
    // 院所
    enabled: true,
    label: '院所',
    key: 5,
  },
  COMPANY: {
    // 企业
    enabled: true,
    label: '企业',
    key: 6,
  },
  TALENT: {
    // 人物
    enabled: true,
    label: '人物',
    key: 7,
  },
  USER: {
    // 用户
    enabled: false,
    label: '用户',
    key: 8,
  },
});

/**
 * @return {ViewOptions}
 */
const getDefaultViewOptions = () => ({
  time: '',
  types: [
    NODE_TYPE_COMPANY,
    NODE_TYPE_TALENT,
    NODE_TYPE_PATENT,
    NODE_TYPE_PAPER,
    NODE_TYPE_NEWS_ACTIVITIES,
    NODE_TYPE_DOCS,
  ],
  areas: [],
  keywords: [],
});

/**
 * @return {ExactMatchResult}
 */
const getEmptyExactMatchResult = () => {
  const classifiedNodes = {};
  Object.values(MatchCategories).filter(c => c.enabled)
    .forEach(c => classifiedNodes[c.key] = []);
  return {
    nodeId: false, // 当前搜索结果对应的节点ID
    nodes: [], // 所有新节点列表
    classifiedNodes,
    edges: [], // 所有新关联关系列表
    status: NodeExactMatchingStatus.IDLE, // 搜索结果状态
    errorCode: 0, // 搜索结果错误码
    errorMsg: '', // 搜索结果错误信息
  }
};

/**
 * @return {MatchResult}
 */
const getEmptyMatchResult = () => {
  const classifiedNodes = {};
  Object.values(MatchCategories).filter(c => c.enabled)
    .forEach(c => classifiedNodes[c.key] = []);
  return {
    id: false, // 当前搜索结果对应的节点ID
    nodes: [], // 所有新节点列表
    classifiedNodes,
    edges: [], // 所有新关联关系列表
    status: NodeExactMatchingStatus.IDLE, // 搜索结果状态
    errorCode: 0, // 搜索结果错误码
    errorMsg: '', // 搜索结果错误信息
  }
};

/**
 * @return {RelatedClueResult}
 */
const getEmptyRelatedClueResult = () => ({
  nodeId: false, // 当前扩展结果对应的节点ID
  newNodes: [], // 去除视图中已有节点后的所有新节点列表
  newNodesMap: {}, // 去除视图中已有节点后的所有新节点K-V结构
  allNodes: [], // 去除视图中已有关联关系后的所有新关联关系所连接的节点列表，包含新节点及已有节点
  newEdges: [], // 去除视图中已有关联关系后的所有新关联关系列表
  currentNodes: [], // 当前展示的节点列表
  currentEdges: [], // 当前展示的边列表
  currentSolidNodeIds: [], // 当前保留的所有节点ID
  currentSolidEdgeIds: [], // 当前保留的所有边ID
  processingNodeIds: [], // 正在操作中的节点ID
  nextStartPos: 0, // 下一批要展示的关系起始位置
  currentStartPos: -1, // 当前展示的关系起始位置
  currentlyShowingAmount: 0, // 当前展示的关系数量
  status: NodeExpandingStatus.IDLE, // 扩展结果状态
  errorCode: 0, // 扩展结果错误码
  errorMsg: '', // 扩展结果错误信息
});

/**
 * @return {RelatedResourceResult}
 */
const getEmptyRelatedResourceResult = () => ({
  nodeId: false, // 当前联想结果对应的节点ID
  newNodes: [], // 去除视图中已有节点后的所有新节点列表
  newNodesMap: {}, // 去除视图中已有节点后的所有新节点K-V结构
  allNodes: [], // 去除视图中已有关联关系后的所有新关联关系所连接的节点列表，包含新节点及已有节点
  newEdges: [], // 去除视图中已有关联关系后的所有新关联关系列表
  currentNodes: [], // 当前展示的节点列表
  currentEdges: [], // 当前展示的边列表
  currentSolidNodeIds: [], // 当前保留的所有节点ID
  currentSolidEdgeIds: [], // 当前保留的所有边ID
  processingNodeIds: [], // 正在操作中的节点ID
  nextStartPos: 0, // 下一批要展示的关系起始位置
  currentStartPos: -1, // 当前展示的关系起始位置
  currentlyShowingAmount: 0, // 当前展示的关系数量
  status: NodeGrowingStatus.IDLE, // 联想结果状态
  errorCode: 0, // 联想结果错误码
  errorMsg: '', // 联想结果错误信息
});

const CURRENT_FILE_REMARK_VERSION = 1;

export const relatedClueResultPerStep = 10;

export const relatedResourceResultPerStep = 10;

@privateProtector
@bindUtil.asSourceClass
class ViewDataProvider {
  constructor(viewId = undefined) {
    if (viewId) this._viewId = viewId;
    this.registerListeners4RelatedClueResult();
    this.registerListeners4RelatedResourceResult();

    // 注册边创建、删除监听函数，处理最小路径
    this.with(this).subscribe(EdgeEvents.ADDED, (type) => {
      // noinspection JSBitwiseOperatorUsage
      if (type & ADD_TO_GRAPH) {
        // 只需要处理添加到画面的操作
        this._shortestPathResult = undefined;
      }
    }).subscribe(EdgeEvents.REMOVED, (type) => {
      // noinspection JSBitwiseOperatorUsage
      if (type & REMOVE_FROM_GRAPH) {
        // 只需要处理从画面移除的操作
        this._shortestPathResult = undefined;
      }
    });

    getCacheStore('view-cache-data').then(store => this._viewDataCacheStore = store);
  }

  /**
   * @private
   * @type {ViewDataProvider}
   */
  _self = this;

  /**
   * @private
   *
   * @type {IDBPObjectStore<*, ['view-cache-data'], 'view-cache-data', 'readwrite'>}
   */
  _viewDataCacheStore = undefined;

  /**
   * @private
   * 视图ID
   *
   * @type {string|undefined}
   */
  _viewId = undefined;

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 视图ID-Getter
   *
   * @return {string}
   */
  get viewId() {
    return this._viewId;
  };

  /**
   * @private
   * 视图信息
   *
   * @type {Object|undefined}
   */
  _viewInfo = undefined;

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 视图信息-Getter
   *
   * @return {Object|undefined}
   */
  get viewInfo() {
    return this._viewInfo;
  };

  /**
   * @private
   * 事件处理中间件
   *
   * @type {SimplePB}
   */
  _PB = new SimplePB();

  /**
   * @private
   * 缓存使用的存储空间
   *
   * @type {Store}
   * @private
   */
  _cacheStore = undefined;

  /**
   * 设置缓存使用的存储空间
   *
   * @param {Store} cacheStore
   */
  set cacheStore(cacheStore) {
    this._cacheStore = cacheStore;
    this._cacheStore.dispatch(validateStoreAction());
  };

  /**
   * @private
   * 关系图数据
   *
   * @type {{nodes: DataSet, edges: DataSet, withDetail: boolean, pendingChanges: object}}
   */
  _data = {'nodes': new DataSet(), 'edges': new DataSet(), withDetail: true, pendingChanges: {}};

  /**
   * @private
   * 视图数据加载状态
   *
   * @type {{timestamp: undefined|string, errorCode: number, status: string, errorMsg: string}}
   */
  _dataLoading = {
    timestamp: undefined,
    status: NetworkDataLoadingStatus.IDLE,
    errorCode: 0,
    errorMsg: '',
  };

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 视图数据加载状态-Getter
   *
   * @return {{timestamp: undefined|string, errorCode: number, status: string, errorMsg: string}}
   */
  get dataLoading() {
    return this._dataLoading;
  };

  /**
   * @private
   * 视图数据重新加载状态
   *
   * @type {{timestamp: undefined|string, errorCode: number, status: string, errorMsg: string}}
   */
  _dataReloading = {
    timestamp: undefined,
    status: NetworkDataReloadingStatus.IDLE,
    errorCode: 0,
    errorMsg: '',
  };

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 视图重新数据加载状态-Getter
   *
   * @return {{timestamp: undefined|string, errorCode: number, status: string, errorMsg: string}}
   */
  get dataReloading() {
    return this._dataReloading;
  };

  /**
   * @private
   * 处理过后的视图配置
   *
   * @type {ViewOptions|undefined}
   */
  _parsedViewOptions = undefined;

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 处理过后的视图配置-Getter
   *
   * @return {ViewOptions|undefined}
   */
  get parsedViewOptions() {
    return this._parsedViewOptions;
  };

  /**
   * @private
   * 搜索结果数据
   *
   * @type {DataSet}
   */
  _exactMatchResult = new DataSet();

  /**
   * @private
   * 子图搜索结果数据
   *
   * @type {DataSet}
   */
  _matchResultForSubView = new DataSet();

  /**
   * @private
   * 扩展结果数据
   *
   * @type {DataSet}
   */
  _relatedClueResult = new DataSet();

  /**
   * @private
   * 处理中的关联关系列表（请求已发送但尚未返回的添加、删除等操作）
   *
   * @type {[[string]]}
   */
  _relationProcessingList = [];

  /**
   * @private
   * 联想结果数据
   *
   * @type {DataSet}
   */
  _relatedResourceResult = new DataSet();

  /**
   * @private
   * 记录由于关系而保存下来的节点ID
   *
   * @type {[string]}
   */
  _solidNodeIdsByRelation = [];

  /**
   * @private
   * 所有子图信息
   *
   * @type {DataSet|undefined|string}
   */
  _subViewData = undefined;

  /**
   * @private
   * 与视图相关的数据集
   *
   * @type {undefined|Array|string}
   */
  _dataSets = undefined;

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 与视图相关的数据集-Getter
   *
   * @return {undefined|Array|string}
   */
  get dataSets() {
    return this._dataSets;
  }

  /**
   * @public
   * 数据集加载状态
   *
   * @type {{msg: string, code: number, status: string}}
   */
  _dataSetsLoadingStatus = {
    msg: '',
    code: 0,
    status: NetworkDataSetLoadingStatus.IDLE,
  };

  /**
   * @public
   * 数据集加载状态-Getter
   *
   * @return {{msg: string, code: number, status: string}}
   */
  get dataSetsLoadingStatus() {
    return this._dataSetsLoadingStatus;
  }

  /**
   * @public
   * @readonly
   * 与视图相关的文件
   *
   * @type {Array}
   */
  _files = [];

  // noinspection JSUnusedGlobalSymbols
  /**
   * @public
   * 与视图相关的文件-Getter
   *
   * @return {Array}
   */
  get files() {
    return this._files;
  }

  /**
   * 按节点分类的附件列表
   *
   * @type {Object}
   * @private
   */
  _filesByNode = undefined;

  /**
   * 按节点分类的附件列表
   *
   * @returns {Object|undefined}
   */
  get fileByNode() {
    if (this._filesByNode) return this._filesByNode;
    if (this._fileListLoadingStatus.status !== NetworkFileListLoadingStatus.SUCCESS) return undefined;
    this._filesByNode = {};
    this._files.forEach(file => {
      if (file['from']) {
        this._filesByNode[file['from']] = this._filesByNode[file['from']] || [];
        this._filesByNode[file['from']].push(file);
      } else {
        this._filesByNode['_global'] = [];
        this._filesByNode['_global'].push(file);
      }
    });
    return this._filesByNode;
  }

  /**
   * @public
   * 视图文件列表加载状态
   *
   * @type {{msg: string, code: number, status: string}}
   */
  _fileListLoadingStatus = {
    msg: '',
    code: 0,
    status: NetworkFileListLoadingStatus.IDLE,
  };

  /**
   * @public
   * 视图文件列表加载状态-Getter
   *
   * @return {{msg: string, code: number, status: string}}
   */
  get fileListLoadingStatus() {
    return this._fileListLoadingStatus;
  }

  _shortestPathResult = undefined;

  get shortestPathResult() {
    if (!this._data || !this._viewId) return undefined;
    this._shortestPathResult = this._shortestPathResult || getShortestPath(this._data.nodes.get(),
      this._data.edges.get().filter(edge => edge.visible), false);
    return this._shortestPathResult;
  }

  accessNode = nodeId => {
    let me = this._self;

    return new Promise((resolve, reject) => {
      me.assertDataReady();
      let node = me.getNode(nodeId);
      if (!node) {
        reject({code: 404, msg: `Node ${nodeId} not found.`});
        return;
      }

      let now = moment(), nowStr = now.format('YYYY-MM-DD HH:mm:ss');
      if (now.subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss') <= node['accessTimestamp']) {
        resolve();
        return;
      }

      API_AccessNode(me._viewId, nodeId)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            let node = me.getNode(nodeId);
            if (node) {
              node.accessTimestamp = nowStr;
              me._data.nodes.update(node);
              let connectedEdgeIds = me.getConnectedEdgeIds(node.id);
              if (connectedEdgeIds && connectedEdgeIds.length > 0) {
                let connectedEdges = me._data.edges.get(connectedEdgeIds);
                if (connectedEdges && connectedEdges.length > 0) {
                  connectedEdges.forEach(edge => edge._brightnessFromAccessTimestamp = 1);
                  me._data.edges.update(connectedEdges);
                }
              }
            }
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  accessView = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_AccessView(me._viewId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 添加附件至指定节点或图谱
   *
   * @param {string} [nodeId] 节点ID，不填则上传至图谱（视图）
   * @param {string} [comment] 备注信息
   * @param {File[]} [files] 文件列表
   * @param {Object[]} [metaList] 额外信息列表
   * @param {Function} [progressCallback] 进度回调函数
   *
   * @return {Promise}
   */
  addFiles = (nodeId, comment, files, metaList, progressCallback) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      if (!comment && (!files || !_.isArray(files) || files.length <= 0)) {
        reject({code: 400, msg: '备注信息和文件不可都为空！'});
      }
      me._PB.emit('default', NetworkEvents.FILE_UPLOADING, me._viewId);
      if (nodeId) {
        me._PB.emit('default', NodeEvents.PROPERTY_UPLOADING, nodeId);
      }
      this.addFilesPromise(
        nodeId,
        JSON.stringify({
          comment,
          version: CURRENT_FILE_REMARK_VERSION,
          meta: files.map((file, idx) => {
            return {
              i: -1, // 展示索引
              c: null, // 文件备注（默认为原始文件名）
              m: (new Date()).getTime(), // 最后更新时间
              ...((metaList && metaList[idx]) ? metaList[idx] : {}),
            };
          }),
        }),
        files,
        progressCallback
      ).then(() => {
        me._PB.emit('default', NetworkEvents.FILE_UPLOADED, me._viewId);
        if (nodeId) {
          let node = me._data.nodes.get(nodeId);
          if (node) {
            me.loadNodeDetailInfo(nodeId).then(() => {
              node = me._data.nodes.get(nodeId);
              me._PB.emit('default', NodeEvents.UPDATED, [nodeId], [node]);
            });
          }
          me._PB.emit('default', NodeEvents.ATTACHMENT_UPLOADED, nodeId);
        }
        resolve();
      }).catch(errorInfo => {
        me._PB.emit('default', NetworkEvents.FILE_UPLOAD_FAILED, me._viewId, errorInfo);
        if (nodeId) {
          me._PB.emit('default', NodeEvents.PROPERTY_UPLOAD_FAILED, nodeId, errorInfo);
        }
        reject(errorInfo);
      });
    });
  };

  /**
   * 添加节点至已有相连节点
   *
   * @param {Object[]} nodes 要添加的简单节点列表
   * @param {string|boolean} linkedToNodeId 要关联的节点ID
   * @param {string|boolean|undefined} [toNodeId]
   * 指定一个已有节点，新节点初始位置将位于该指定节点周围，如不指定则自动判定，为false则初始位于画面中心附近
   * @param {boolean} [testOnly] 是否仅测试
   * @param {String} [senderId] 发送者标识
   * @param {boolean|undefined} [doAnalyze] 是否启动智能分析
   * @return {Promise}
   */
  addNodeLinkedTo = (
    nodes, linkedToNodeId = false, toNodeId = undefined, testOnly = false,
    senderId = undefined, doAnalyze = undefined
  ) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      let linkedToNode = undefined;
      if (linkedToNodeId) {
        linkedToNode = me._data.nodes.get(linkedToNodeId);
        if (!linkedToNode) {
          reject({code: 404, msg: `Node ${linkedToNodeId} not found.`});
          return;
        }
      }
      let edgesToSave = [];
      if (linkedToNode) {
        edgesToSave = nodes.map((node, idx) => {
          return {
            from: linkedToNodeId,
            toIndex: idx,
            userConfirmed: node.userConfirmed === true,
            meta: {text2text: node[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && linkedToNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT},
          };
        })
      }
      me.saveRelationGraph(nodes, edgesToSave, (toNodeId === undefined) ? linkedToNodeId : toNodeId,
        testOnly, senderId, doAnalyze).then(resolve).catch(reject);
    });
  };

  /**
   * 添加关联关系
   *
   * @param {string} fromNodeId 来源节点ID
   * @param {string|[string]} toNodeIds 目的节点ID列表
   * @param {String} [senderId] 发送者标识
   *
   * @return {Promise}
   */
  addRelation = (fromNodeId, toNodeIds, senderId = undefined) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (_.isString(toNodeIds)) {
        toNodeIds = [toNodeIds];
      }
      const fromNode = me._data.nodes.get(fromNodeId);
      if (!fromNode) {
        reject({code: 404, msg: `Node ${fromNodeId} not found.`});
        return;
      }

      let nodesToSave = [];
      let edgesToSave = [];
      if (fromNode.meta && fromNode.meta.status === 0) {
        nodesToSave.push({
          ...fromNode,
          id: fromNode.id,
          meta: {...fromNode.meta, status: 1},
          userConfirmed: true,
        });
      } else if (!fromNode.userConfirmed) {
        nodesToSave.push({
          ...fromNode,
          id: fromNode.id,
          userConfirmed: true,
        });
      }

      toNodeIds = toNodeIds.filter(toNodeId => {
        const relatedProcessingList =
          me._relationProcessingList.filter(e => e.includes(fromNodeId) && e.includes(toNodeId));
        if (relatedProcessingList && relatedProcessingList.length > 0) {
          // 对应关系正在操作中，忽略该请求
          console.log(`Relation between ${fromNodeId} and ${toNodeId} is currently processing...`);
          return false;
        }
        return true;
      });
      const toNodes = me._data.nodes.get(toNodeIds);
      if (!toNodes || !_.isArray(toNodes) || toNodes.length <= 0) {
        reject({code: 400, msg: `No relations to save.`});
        return;
      }
      toNodes.forEach(node => {
        if (node.meta && node.meta.status === 0) {
          nodesToSave.push({
            ...node,
            id: node.id,
            meta: {...node.meta, status: 1},
            userConfirmed: true,
          });
        } else if (!node.userConfirmed) {
          nodesToSave.push({
            ...node,
            id: node.id,
            userConfirmed: true,
          });
        }

        edgesToSave.push({
          size: 0,
          from: fromNodeId,
          to: node.id,
          userConfirmed: true,
          meta: {
            text2text: fromNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && node[TYPE_FIELD_NAME] === NODE_TYPE_TEXT,
          },
        });
      });
      me.saveRelationGraph(nodesToSave, edgesToSave, false, false, senderId, false)
        .then(resolve).catch(reject);
    });
  };

  /**
   * 批量添加纯文本节点
   *
   * @param {object[]} textNodeList 文本节点列表
   * @param {string|boolean} [toNodeId] 要关联到的节点ID，为空则自动判定，为false则不关联
   * @param {String} [senderId] 发送者标识
   *
   * @return {Promise}
   */
  addTextNodes = (textNodeList, toNodeId = false, senderId = undefined) => {
    let me = this._self;

    return new Promise((resolve, reject) => {
      let toNode = undefined;
      if (toNodeId) {
        if (!_.isString(toNodeId)) {
          reject({code: 500, msg: `Invalid to node id ${toNodeId}.`});
        }
        toNode = this._data.nodes.get(toNodeId);
        if (!toNode) {
          reject({code: 500, msg: `Node not found, id: ${toNodeId}.`});
        }
      }
      const edgesToAdd = [];
      const nodesToAdd = textNodeList.map((nodeInfo, idx) => {
        if (toNode) {
          edgesToAdd.push({
            from: toNodeId,
            toIndex: idx,
            userConfirmed: true,
            meta: {
              text2text: toNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT,
            },
          });
        } else if (idx > 0) {
          edgesToAdd.push({
            fromIndex: (idx - 1),
            toIndex: idx,
            userConfirmed: true,
            meta: {
              text2text: true,
            },
          });
        }
        return {
          ...nodeInfo,
          [TYPE_FIELD_NAME]: NODE_TYPE_TEXT,
          userConfirmed: true,
          meta: nodeInfo.meta ? (
            {...nodeInfo.meta, status: nodeInfo.meta.status === undefined ? undefined : 1}
          ) : undefined,
        };
      });
      me.saveRelationGraph(nodesToAdd, edgesToAdd, toNodeId, false, senderId).then(resolve).catch(reject);
    });
  };

  /**
   * 应用重新加载的数据
   *
   * @param {undefined|Function} [nodeFilterFn] 节点过滤函数
   * @param {undefined|Function} [edgeFilterFn] 边过滤函数
   * @param {undefined|Object} [extraPendingChanges] 额外延时加载数据
   */
  applyPendingChanges = (nodeFilterFn, edgeFilterFn, extraPendingChanges) => {
    let me = this._self;
    me.assertDataReady();
    let pendingChanges = {
      nodesToAdd: [],
      nodesToUpdate: [],
      nodesToRemove: [],
      edgesToAdd: [],
      edgesToRemove: [],
      ...me._data.pendingChanges,
    };
    me._data.pendingChanges = {
      nodesToAdd: [],
      nodesToUpdate: [],
      nodesToRemove: [],
      edgesToAdd: [],
      edgesToRemove: [],
    };

    if (!nodeFilterFn && !edgeFilterFn) {
      me._dataLoading.timestamp = me._dataReloading.timestamp || me._dataLoading.timestamp;
    }

    let edgesToUpdate = [],
      edgesToRemoveMap = {},
      nodesToAdd,
      nodesToUpdate,
      nodesToRemove,
      edgesToAdd,
      edgesToRemove = [];

    pendingChanges.edgesToRemove.forEach(edgeId => {
      if (!edgeFilterFn || edgeFilterFn(edgeId, this._data.edges.get(edgeId), 'remove', this)) {
        edgesToRemoveMap[edgeId] = true;
      } else {
        me._data.pendingChanges.edgesToRemove.push(edgeId);
      }
    });

    if (extraPendingChanges && extraPendingChanges.edgesToRemove) {
      extraPendingChanges.edgesToRemove.forEach(edgeId => edgesToRemoveMap[edgeId] = true);
    }

    nodesToAdd = nodeFilterFn ? pendingChanges.nodesToAdd.filter(node => {
      if (nodeFilterFn(node.id, node, 'add', this, pendingChanges)) {
        return true;
      } else {
        me._data.pendingChanges.nodesToAdd.push(node);
        return false;
      }
    }) : pendingChanges.nodesToAdd;

    if (extraPendingChanges && extraPendingChanges.nodesToAdd) {
      nodesToAdd = nodesToAdd.concat(extraPendingChanges.nodesToAdd);
    }

    nodesToUpdate = nodeFilterFn ? pendingChanges.nodesToUpdate.filter(node => {
      if (nodeFilterFn(node.id, node, 'update', this, pendingChanges)) {
        return true;
      } else {
        me._data.pendingChanges.nodesToUpdate.push(node);
        return false;
      }
    }) : pendingChanges.nodesToUpdate;

    if (extraPendingChanges && extraPendingChanges.nodesToUpdate) {
      nodesToUpdate = nodesToUpdate.concat(extraPendingChanges.nodesToUpdate);
    }

    nodesToRemove = nodeFilterFn ? pendingChanges.nodesToRemove.filter(nodeId => {
      if (nodeFilterFn(nodeId, this._data.nodes.get(nodeId), 'remove', this, pendingChanges)) {
        return true;
      } else {
        me._data.pendingChanges.nodesToRemove.push(nodeId);
        return false;
      }
    }) : pendingChanges.nodesToRemove;

    if (extraPendingChanges && extraPendingChanges.nodesToRemove) {
      nodesToRemove = nodesToRemove.concat(extraPendingChanges.nodesToRemove);
    }

    edgesToAdd = edgeFilterFn ? pendingChanges.edgesToAdd.filter(edge => {
      if (edgeFilterFn(edge.id, edge, 'add', this, pendingChanges)) {
        return true;
      } else {
        me._data.pendingChanges.edgesToAdd.push(edge);
        return false;
      }
    }) : pendingChanges.edgesToAdd;

    if (extraPendingChanges && extraPendingChanges.edgesToAdd) {
      edgesToAdd = edgesToAdd.concat(extraPendingChanges.edgesToAdd);
    }

    let relationChanged = false;
    if (nodesToAdd || nodesToUpdate) {
      me._data.nodes.update([].concat(nodesToAdd).concat(nodesToUpdate));
      relationChanged = true;
    }
    if (nodesToRemove) {
      me._data.nodes.remove(nodesToRemove);
      relationChanged = true;
    }
    if (edgesToAdd) edgesToUpdate = [...edgesToAdd];
    if (nodesToUpdate) {
      let relatedEdges = {};
      nodesToUpdate.forEach(node => {
        let connectedEdgeIds = me.getConnectedEdgeIds(node.id);
        connectedEdgeIds.forEach(edgeId => {
          if (relatedEdges[edgeId]) return;
          if (edgesToRemoveMap[edgeId]) return;
          let edge = me._data.edges.get(edgeId);
          if (!edge) return;
          let fromNode = me._data.nodes.get(edge.from),
            toNode = me._data.nodes.get(edge.to);
          if (!fromNode || !toNode) return;
          edge.updateBrightnessFromAccessTimestamp(fromNode, toNode);
          relatedEdges[edge.id] = edge;
          edgesToUpdate.push(edge);
        });
      })
    }
    if (edgesToUpdate.length > 0) {
      me._data.edges.update(edgesToUpdate);
      relationChanged = true;
    }
    if (edgesToRemove) {
      me._data.edges.remove(edgesToRemove);
      relationChanged = true;
    }

    if (relationChanged) me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
  };

  /**
   * 清除扩展节点数据
   *
   * @param {string} nodeId 要清除扩展节点数据的节点ID
   * @param {Node[]} [connectedNodes] 指定已连接的节点
   */
  clearRelatedClueResult = (nodeId, connectedNodes) => {
    connectedNodes = connectedNodes || this._data.nodes.get(this.getConnectedNodeIdsByNodeId(nodeId));
    let relatedNodeIds = [];
    let me = this._self;
    connectedNodes.forEach(node => {
      if (!node) return;
      if (node[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT) return;
      let connectedNodeIds = me.getConnectedNodeIdsByNodeId(node.id);
      if (node.status === 0 && (!connectedNodeIds || connectedNodeIds.length <= 1)) {
        relatedNodeIds.push(node.id)
      }
    });
    let connectedEdges = this._data.edges.get(this.getConnectedEdgeIds(nodeId));
    let edgeIdsToRemove = connectedEdges.filter(edge => {
      let targetNode = me._data.nodes.get(edge.from === nodeId ? edge.to : edge.from);
      return targetNode && targetNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && edge.status === 0;
    }).map(edge => edge.id);

    const removedNodes = this._data.nodes.get(relatedNodeIds);
    const removedEdges = this._data.edges.get(edgeIdsToRemove);
    this._data.nodes.remove(relatedNodeIds);
    this._data.edges.remove(edgeIdsToRemove);
    this._PB.emit('default', NodeEvents.REMOVED, REMOVE_FROM_GRAPH, removedNodes);
    this._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_GRAPH, removedEdges);
    this._PB.emit('default', NetworkEvents.RELATION_CHANGED);
    this._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);

    this.setRelatedClueResult(nodeId, 'currentlyShowingAmount', 0);

    let edges = this._data.edges.get(this.getConnectedEdgeIds(nodeId));
    let nodes = [];
    edges.forEach(edge => edge.from === nodeId ? nodes.push(me._data.nodes.get(edge.to)) :
      nodes.push(me._data.nodes.get(edge.from)));
    this._PB.emit('default', NodeEvents.CONNECTED_NODES_UPDATED, nodeId, {nodes, edges});
  };

  /**
   * 清除联想节点数据
   *
   * @param {string} nodeId 要清除联想节点数据的节点ID
   * @param {Node[]} [connectedNodes] 指定已连接的节点
   */
  clearRelatedResourceResult = (nodeId, connectedNodes) => {
    connectedNodes = connectedNodes || this._data.nodes.get(this.getConnectedNodeIdsByNodeId(nodeId));
    let relatedNodeIds = [];
    let me = this._self;
    connectedNodes.forEach(node => {
      if (node[TYPE_FIELD_NAME] === NODE_TYPE_TEXT) return;
      let connectedNodeIds = me.getConnectedNodeIdsByNodeId(node.id);
      if (node.status === 0 && (!connectedNodeIds || connectedNodeIds.length <= 1)) {
        relatedNodeIds.push(node.id)
      }
    });
    let connectedEdges = this._data.edges.get(this.getConnectedEdgeIds(nodeId));
    let edgeIdsToRemove = connectedEdges.filter(edge => {
      let targetNode = me._data.nodes.get(edge.from === nodeId ? edge.to : edge.from);
      return targetNode && targetNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && edge.status === 0;
    }).map(edge => edge.id);

    const removedNodes = this._data.nodes.get(relatedNodeIds);
    const removedEdges = this._data.edges.get(edgeIdsToRemove);
    this._data.nodes.remove(relatedNodeIds);
    this._data.edges.remove(edgeIdsToRemove);
    this._PB.emit('default', NodeEvents.REMOVED, REMOVE_FROM_GRAPH, removedNodes);
    this._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_GRAPH, removedEdges);
    this._PB.emit('default', NetworkEvents.RELATION_CHANGED);
    this._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);

    this.setRelatedResourceResult(nodeId, 'currentlyShowingAmount', 0);

    let edges = this._data.edges.get(this.getConnectedEdgeIds(nodeId));
    let nodes = [];
    edges.forEach(edge => edge.from === nodeId ? nodes.push(me._data.nodes.get(edge.to)) :
      nodes.push(me._data.nodes.get(edge.from)));
    this._PB.emit('default', NodeEvents.CONNECTED_NODES_UPDATED, nodeId, {nodes, edges});
  };

  /**
   * 异步微服务
   *
   * @param {string} [serviceId] 服务ID
   * @param {object} [target] 计算目标
   * @param {object} [parameters] 参数
   * @param {number} [start] 起始位置
   * @param {number} [limit] 数量限制
   */
  doMicroServiceByView = (serviceId, target, parameters, start, limit) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_DoMicroServiceByView(me._viewId, serviceId, target, parameters, start, limit).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 异步发现
   *
   * @param {string} [nodeId] 起始节点ID
   */
  exploreAsync = (nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_ExploreAsync(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 异步发现（数值）
   *
   * @param {string} [nodeId] 起始节点ID
   */
  exploreAsyncByNum = (nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_ExploreAsyncByNum(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 异步发现（属性/人脉）
   *
   * @param {string} [nodeId] 起始节点ID
   */
  exploreAsyncByProps = (nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_ExploreAsyncByProps(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 通过指定子图探索
   *
   * @param {string} subViewId 要探索的节点所在子图ID
   * @param {string} nodeId 要探索的节点ID
   * @param {ViewOptions} [options] 搜索条件配置，默认使用视图配置
   * @param {Object|Node} [nodeInfo] 节点信息
   * @param {number} [size] 结果集每种节点最大数量限制
   * @param {boolean} [withRelation] 结果集是否包含关系
   * @param {number} [algorithm] 算法ID
   * @param {string|undefined} [sender] 发送者ID
   * @param {boolean} [withTagSummary] 结果集是否包含标签统计信息
   *
   * @return {Promise}
   */
  exploreByNode = (subViewId, nodeId, options, nodeInfo, size = 100, withRelation = false, algorithm = 0,
                   sender = undefined, withTagSummary = false) => {

    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      nodeInfo = (nodeInfo instanceof Node) ? nodeInfo : new Node({
        fname: '加载中...',
        tag: '加载中...',
        ...(nodeInfo || {}),
      });
      const subViewData = me.getSubViewInfo(subViewId);
      if (subViewData && subViewData.nodes.length > 0) {
        const {area, types, time, keyword} = me.stringifyViewOptions(options);
        me._PB.emit('default', NodeEvents.EXPLORING, {nodeId, nodeInfo, sender});
        nodeInfo = (nodeInfo instanceof Node) ? nodeInfo : new Node({
          fname: '加载失败，请重试',
          tag: '<空>',
          ...(nodeInfo || {}),
        });
        // 发送节点联想请求
        API_ExploreByNode(me._viewId, subViewId, nodeId, [nodeInfo],
          {area, types: types.split(','), time, keyword}, size, withRelation, algorithm, withTagSummary)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              const vote = response.data.data['vote'] || {};
              const userVote = response.data.data['userVote'] || {};
              const nodes = response.data.data['nodes'].map(nodeInfo => {
                return new Node({
                  ...nodeInfo,
                  fnameHtml: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].fname) || undefined,
                  keyStcHtml: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].keyStc) || undefined,
                  htmlTags: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].tag) ?
                    nodeInfo.meta['highlights'].tag.split(' ') : undefined,
                  // status: me._data.nodes.get(nodeInfo.id) ? 1 : 0
                  status: subViewData.resourceIds.includes(nodeInfo.id) ? 1 : 0,
                  position: (nodeInfo.meta && nodeInfo.meta['position']) || nodeInfo.position,
                  meta: {
                    ...nodeInfo.meta,
                    vote: _.get(vote, `${nodeInfo.id}.vote`, 0),
                    userVote: _.get(userVote, `${nodeInfo.id}.vote`, 0),
                  },
                });
              });
              nodeInfo = nodes.shift();
              const edges = [];
              if (withRelation) {
                // noinspection JSCheckFunctionSignatures
                (response.data.data['edges'] || []).forEach(edgeInfo => edges.push(new Edge({
                  ...edgeInfo,
                  status: 1
                })));
              }
              // 尝试获取词云数据
              const tags = [];
              // noinspection SpellCheckingInspection
              if (withTagSummary && response.data.data['wordcloud']) {
                // noinspection SpellCheckingInspection
                const wordCloudData = response.data.data['wordcloud'];
                for (let wordIdx = 0; wordIdx < wordCloudData['tags'].length; wordIdx++) {
                  tags.push({
                    text: wordCloudData['tags'][wordIdx],
                    value: wordCloudData['values'][wordIdx]
                  });
                }
              }
              me._PB.emit('default', NodeEvents.EXPLORING_SUCCESS, {
                nodeId,
                nodeInfo,
                nodes,
                edges,
                sources: response.data.data['sources'] || [],
                sender,
                tags,
                score: response.data['sim'] || 0
              });
              resolve({
                nodeId, nodeInfo, nodes, edges, sources: response.data.data['sources'] || [], sender,
                tags, score: response.data['sim'] || 0
              });
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me._PB.emit('default', NodeEvents.EXPLORING_FAILED, {nodeId, nodeInfo, code, msg, sender});
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me._PB.emit('default', NodeEvents.EXPLORING_FAILED, {nodeId, nodeInfo, code, msg, sender});
            reject({code, msg});
          })
      } else if (!subViewData) {
        reject({code: 404, msg: 'SubView not found.'});
      } else {
        reject({code: 412, msg: 'No nodes in subView'});
      }
    });
  };

  /**
   * 通过指定子图探索
   *
   * @param {string} subViewId 要探索的节点所在子图ID
   * @param {string} nodeId 要探索的节点ID
   * @param {ViewOptions} [options] 搜索条件配置，默认使用视图配置
   * @param {object[]} [userSelectedObjects] 用户选择的节点对象列表
   * @param {string[]} [userSelectedTags] 用户选择的标签
   * @param {number} [size] 结果集每种节点最大数量限制
   * @param {boolean} [withRelation] 结果集是否包含关系
   * @param {number} [algorithm] 算法ID
   * @param {string|undefined} [sender] 发送者ID
   * @param {boolean} [withTagSummary] 结果集是否包含标签统计信息
   *
   * @return {Promise}
   */
  exploreBySubview = (subViewId, nodeId, options, userSelectedObjects = [], userSelectedTags = [], size = 100,
                      withRelation = false, algorithm = 0, sender = undefined, withTagSummary = false) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const subViewData = me.getSubViewInfo(subViewId);
      if (subViewData && subViewData.nodes.length > 0) {
        const {area, types, time, keyword} = me.stringifyViewOptions(options);
        // 忽略清理流程
        me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING, {subViewId, sender});
        // 发送节点联想请求
        API_ExploreBySubView(me._viewId, nodeId, userSelectedObjects, userSelectedTags,
          {area, types: types.split(','), time, keyword}, size, withRelation === true,
          algorithm, withTagSummary)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              const vote = response.data.data['vote'] || {};
              const userVote = response.data.data['userVote'] || {};
              const nodes = response.data.data['nodes'].map(nodeInfo => {
                return new Node({
                  ...nodeInfo,
                  fnameHtml: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].fname) || undefined,
                  keyStcHtml: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].keyStc) || undefined,
                  htmlTags: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].tag) ?
                    nodeInfo.meta['highlights'].tag.split(' ') : undefined,
                  // status: me._data.nodes.get(nodeInfo.id) ? 1 : 0
                  status: subViewData.resourceIds.includes(nodeInfo.id) ? 1 : 0,
                  position: (nodeInfo.meta && nodeInfo.meta['position']) || nodeInfo.position,
                  meta: {
                    ...nodeInfo.meta,
                    vote: _.get(vote, `${nodeInfo.id}.vote`, 0),
                    userVote: _.get(userVote, `${nodeInfo.id}.vote`, 0),
                  },
                });
              });
              const edges = [];
              if (withRelation) {
                response.data.data['edges'].forEach(edgeInfo => edges.push(new Edge({
                  ...edgeInfo,
                  status: 1
                })));
              }
              // 尝试获取词云数据
              const tags = [];
              // noinspection SpellCheckingInspection
              if (withTagSummary && response.data.data['wordcloud']) {
                // noinspection SpellCheckingInspection
                const wordCloudData = response.data.data['wordcloud'];
                for (let wordIdx = 0; wordIdx < wordCloudData['tags'].length; wordIdx++) {
                  tags.push({
                    text: wordCloudData['tags'][wordIdx],
                    value: wordCloudData['values'][wordIdx]
                  });
                }
              }
              me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING_SUCCESS, {
                subViewId,
                nodeId,
                nodes,
                edges,
                sources: response.data.data['sources'] || [],
                version: response.data.data['version'] || 0,
                sender,
                tags,
                score: response.data['sim'] || 0
              });
              resolve({
                subViewId,
                nodeId,
                nodes,
                edges,
                sources: response.data.data['sources'] || [],
                version: response.data.data['version'] || 0,
                sender,
                tags,
                score: response.data.data['sim'] || 0
              });
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING_FAILED, {
                subViewId,
                code,
                msg,
                sender
              });
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING_FAILED, {subViewId, code, msg, sender});
            reject({code, msg});
          });
      } else if (!subViewData) {
        reject({code: 404, msg: 'SubView not found.'});
      } else {
        reject({code: 412, msg: 'No nodes in subView'});
      }
    });
  };

  /**
   * 通过视图整体探索
   *
   * @param {string|undefined} [sender] 发送者ID
   * @param {number} [size] 结果集每种节点最大数量限制
   * @param {boolean} [withRelation] 结果集是否包含关系
   * @param {number} [algorithm] 算法ID
   * @param {boolean} [withTagSummary] 结果集是否包含标签统计信息
   * @return {Promise}
   */
  exploreByView = (sender = undefined, size = 100, withRelation = false, algorithm = 0, withTagSummary = false) => {
    let me = this._self, subViewId = me._viewId;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const subViewData = me.getSubViewInfo(subViewId);
      if (subViewData && subViewData.nodes.length > 0) {
        // 忽略清理流程
        me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING, {subViewId, sender});
        // 发送节点联想请求
        API_ExploreBySubView(me._viewId, undefined, undefined, undefined,
          undefined, size, withRelation === true, algorithm, withTagSummary)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              const vote = response.data.data['vote'] || {};
              const userVote = response.data.data['userVote'] || {};
              const nodes = (response.data.data['nodes'] || []).map(nodeInfo => {
                // noinspection JSCheckFunctionSignatures
                return new Node({
                  ...nodeInfo,
                  fnameHtml: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].fname) || undefined,
                  keyStcHtml: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].keyStc) || undefined,
                  htmlTags: (nodeInfo.meta && nodeInfo.meta['highlights'] && nodeInfo.meta['highlights'].tag) ?
                    nodeInfo.meta['highlights'].tag.split(' ') : undefined,
                  // status: me._data.nodes.get(nodeInfo.id) ? 1 : 0
                  status: subViewData.resourceIds.includes(nodeInfo.id) ? 1 : 0,
                  position: (nodeInfo.meta && nodeInfo.meta['position']) || nodeInfo.position,
                  meta: {
                    ...nodeInfo.meta,
                    vote: _.get(vote, `${nodeInfo.id}.vote`, 0),
                    userVote: _.get(userVote, `${nodeInfo.id}.vote`, 0),
                  },
                });
              });
              const edges = [];
              if (withRelation) {
                // noinspection JSCheckFunctionSignatures
                (response.data.data['edges'] || []).forEach(edgeInfo => edges.push(new Edge({
                  ...edgeInfo,
                  status: 1
                })));
              }
              // 尝试获取词云数据
              const tags = [];
              // noinspection SpellCheckingInspection
              if (withTagSummary && response.data.data['wordcloud']) {
                // noinspection SpellCheckingInspection
                const wordCloudData = response.data.data['wordcloud'];
                for (let wordIdx = 0; wordIdx < wordCloudData['tags'].length; wordIdx++) {
                  tags.push({
                    text: wordCloudData['tags'][wordIdx],
                    value: wordCloudData['values'][wordIdx]
                  });
                }
              }
              me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING_SUCCESS, {
                subViewId,
                nodes,
                edges,
                sources: response.data.data['sources'] || [],
                version: response.data.data['version'] || 0,
                sender,
                tags,
                score: response.data['sim'] || 0
              });
              resolve({
                subViewId,
                nodes,
                edges,
                sources: response.data.data['sources'] || [],
                version: response.data.data['version'] || 0,
                sender,
                tags,
                score: response.data.data['sim'] || 0
              });
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING_FAILED, {
                subViewId,
                code,
                msg,
                sender
              });
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me._PB.emit('default', NetworkEvents.SUB_VIEW_EXPLORING_FAILED, {subViewId, code, msg, sender});
            reject({code, msg});
          });
      }
    });
  };

  /**
   * 同步发现（连接）
   *
   * @param {string} [nodeId] 起始节点ID
   * @param {number} [userId] 当前用户ID，同步发现出的节点所有者为当前用户
   */
  exploreSyncByConnection = (nodeId, {userId}) => {
    let me = this._self, nowStr = moment().format("YYYY-MM-DD HH:mm:ss");
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_ExploreSyncByConnection(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          let nodes = response.data.data.map(n => {
            let data = {
              ...n,
              id: `${nodeId}#${Math.random()}`,
              status: 0,
              "delete": 0,
              updateTime: nowStr,
              updateTimestamp: nowStr,
              userId,
              viewId: me._viewId,
              aiRelatedTo: nodeId,
              linkTime: nowStr,
              linkTimestamp: nowStr,
              accessTimestamp: nowStr,
              userPreferredType: n.aiPreferredType,
              vrVisibleType: n.aiPreferredType,
              vrDisplayText: n.fname,
              meta: {...n.meta, replaceNodeId: true, scale: 1},
              type: NODE_TYPE_TEXT,
              owner: 0,
            };
            return new Node(data);
          });
          resolve({nodes, edges: []});
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 同步发现（同领域人物）
   *
   * @param {string} [nodeId] 起始节点ID
   * @param {number} [userId] 当前用户ID，同步发现出的节点所有者为当前用户
   */
  exploreSyncByDomainPerson = (nodeId, {userId}) => {
    let me = this._self, nowStr = moment().format("YYYY-MM-DD HH:mm:ss");
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_ExploreSyncByDomainPerson(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          let nodes = response.data.data['nodes'].map(n => {
            let data = {
              ...n,
              status: 0,
              "delete": 0,
              updateTime: nowStr,
              updateTimestamp: nowStr,
              userId,
              viewId: me._viewId,
              aiRelatedTo: nodeId,
              linkTime: nowStr,
              linkTimestamp: nowStr,
              accessTimestamp: nowStr,
              userPreferredType: n.aiPreferredType,
              vrVisibleType: n.aiPreferredType,
              vrDisplayText: n.fname,
              meta: {replaceNodeId: true},
            };
            return new Node(data);
          });
          let edges = response.data.data['edges'].map(e => {
            let data = {
              ...e,
              viewId: me._viewId,
              size: 0,
              userConfirmed: false,
              status: 0,
              version: undefined,
              meta: {relatedTo: nodeId},
            };
            let edge = new Edge(data);
            if (e.from === nodeId || e.to === nodeId) {
              edge.visible = false;
            }
            return edge;
          });
          resolve({nodes, edges});
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 同步发现（相似发现）
   *
   * @param {string} [nodeId] 起始节点ID
   * @param {number} [userId] 当前用户ID，同步发现出的节点所有者为当前用户
   */
  exploreSyncBySimilarity = (nodeId, {userId}) => {
    let me = this._self, nowStr = moment().format("YYYY-MM-DD HH:mm:ss");
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_ExploreSyncBySimilarity(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          let nodes = response.data.data['nodes'].filter(n => !!n['fname']).map(n => {
            let data = {
              ...n,
              status: 0,
              "delete": 0,
              updateTime: nowStr,
              updateTimestamp: nowStr,
              userId,
              viewId: me._viewId,
              aiRelatedTo: nodeId,
              linkTime: nowStr,
              linkTimestamp: nowStr,
              accessTimestamp: nowStr,
              userPreferredType: n.aiPreferredType,
              vrVisibleType: n.aiPreferredType,
              vrDisplayText: n.fname,
              meta: {replaceNodeId: true},
            };
            return new Node(data);
          });
          let edges = response.data.data['edges'].map(e => {
            let data = {
              ...e,
              viewId: me._viewId,
              size: 0,
              userConfirmed: false,
              status: 0,
              version: undefined,
              meta: {relatedTo: nodeId},
            };
            let edge = new Edge(data);
            if (e.from === nodeId || e.to === nodeId) {
              edge.visible = false;
            }
            return edge;
          });
          resolve({nodes, edges});
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 固定指定节点
   *
   * @param {string} nodeId 要固定的节点ID
   * @param {number} x X坐标值
   * @param {number} y Y坐标值
   *
   * @return {Promise}
   */
  fixNodeTo = (nodeId, x, y) => {
    let me = this._self;
    return me.updateNodeInfo({id: nodeId, fixed: true, fx: x, fy: y, _locked: false, silentlyUpdate: true}, true);
  };

  /**
   * 获取指定节点所有搜索结果
   *
   * @param {string} nodeId 节点ID
   * @param {number} [category] 分类
   * @param {number} limit 总数限制
   *
   * @return {{
   *   nodes: [Node],
   *   edges: [Edge],
   *   totalAmount: number,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string
   * }}
   */
  getAllExactMatchResult = (nodeId, category = -1, limit = -1) => {
    let exactMatchResult = this.getExactMatchResult(nodeId) || {};

    if (category < 0) {
      const length = exactMatchResult.nodes ? exactMatchResult.nodes.length : 0;
      if (limit === -1 || limit > length) {
        return {
          nodes: exactMatchResult.nodes || [],
          edges: exactMatchResult.edges || [],
          totalAmount: length,
          status: exactMatchResult.status || NodeExactMatchingStatus.IDLE,
          errorCode: exactMatchResult.errorCode || 0,
          errorMsg: exactMatchResult.errorMsg || '',
        };
      } else {
        const nodeSet = new DataSet();
        const allNodeSet = new DataSet(exactMatchResult.nodes);
        const categories = Object.values(MatchCategories)
          .filter(category => category.enabled)
          .map(category => category.key);
        let pos = 0;
        let found = false;
        const callbackFn = c => {
          let nodes = exactMatchResult.classifiedNodes[c];
          if (nodes.length > pos) {
            if (!nodeSet.get(nodes[pos].id)) {
              nodeSet.add(allNodeSet.get(nodes[pos].id));
              found = true;
            }
          }
        };
        while (nodeSet.length < limit) {
          found = false;
          categories.forEach(callbackFn);
          if (!found) break;
          pos++;
        }
        // noinspection JSCheckFunctionSignatures
        return {
          nodes: nodeSet.get() || [],
          edges: exactMatchResult.edges || [],
          totalAmount: nodeSet.length,
          status: exactMatchResult.status || NodeExactMatchingStatus.IDLE,
          errorCode: exactMatchResult.errorCode || 0,
          errorMsg: exactMatchResult.errorMsg || '',
        }
      }
    } else {
      const length = exactMatchResult.classifiedNodes[category] ? exactMatchResult.classifiedNodes[category].length : 0;
      const nodes = (limit === -1 || limit > length) ? exactMatchResult.classifiedNodes[category] || [] :
        _.slice(exactMatchResult.classifiedNodes[category], limit);
      return {
        nodes,
        edges: exactMatchResult.edges || [],
        totalAmount: nodes.length,
        status: exactMatchResult.status || NodeExactMatchingStatus.IDLE,
        errorCode: exactMatchResult.errorCode || 0,
        errorMsg: exactMatchResult.errorMsg || '',
      }
    }
  };

  /**
   * 获取指定子图所有搜索结果
   *
   * @param {string} subViewId 子图ID
   * @param {number} [category] 分类
   * @param {number} limit 总数限制
   *
   * @return {{
   *   nodes: [Node],
   *   edges: [Edge],
   *   totalAmount: number,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string
   * }}
   */
  getAllMatchResultForSubView = (subViewId, category = -1, limit = -1) => {
    let matchResult = this.getMatchResultForSubView(subViewId) || {};
    const allNodeSet = new DataSet(matchResult.nodes);
    const subViewNodeIds = this.getSubViewInfo(subViewId).nodes.map(node => node.id);
    const nodeSet = new DataSet();

    if (category < 0) {
      const length = matchResult.nodes ? matchResult.nodes.length : 0;
      if (limit === -1 || limit >= length) {
        return {
          nodes: matchResult.nodes || [],
          edges: matchResult.edges || [],
          totalAmount: length,
          status: matchResult.status || NetworkSubViewMatchingStatus.IDLE,
          errorCode: matchResult.errorCode || 0,
          errorMsg: matchResult.errorMsg || '',
        };
      } else {
        const categories = Object.values(MatchCategories)
          .filter(category => category.enabled)
          .map(category => category.key);

        const allNodeIdsBySubViewNodeByCategory = {};
        subViewNodeIds.forEach(nodeId => {
          allNodeIdsBySubViewNodeByCategory[nodeId] = {};
          categories.forEach(category => allNodeIdsBySubViewNodeByCategory[nodeId][category] = []);
        });

        const allNodeSetByCategory = {};
        categories.forEach(category =>
          allNodeSetByCategory[category] = new DataSet(matchResult.classifiedNodes[category]));

        matchResult.edges.forEach(edge => {
          if (allNodeIdsBySubViewNodeByCategory[edge.from] && allNodeSet.get(edge.to)) {
            categories.forEach(category => {
              if (allNodeSetByCategory[category].get(edge.to)) {
                allNodeIdsBySubViewNodeByCategory[edge.from][category].push(edge.to)
              }
            });
          }
          if (allNodeIdsBySubViewNodeByCategory[edge.to] && allNodeSet.get(edge.from)) {
            categories.forEach(category => {
              if (allNodeSetByCategory[category].get(edge.from)) {
                allNodeIdsBySubViewNodeByCategory[edge.to][category].push(edge.from)
              }
            });
          }
        });

        let pos = 0;
        let found = false;
        const callbackFn = nodeId => {
          categories.forEach(c => {
            let nodeIds = allNodeIdsBySubViewNodeByCategory[nodeId][c];
            if (nodeIds.length > pos) {
              if (!nodeSet.get(nodeIds[pos])) {
                nodeSet.add(allNodeSet.get(nodeIds[pos]));
                found = true;
              }
            }
          });
        };
        while (nodeSet.length < limit) {
          found = false;
          subViewNodeIds.forEach(callbackFn);
          if (!found) break;
          pos++;
        }
        // noinspection JSCheckFunctionSignatures
        return {
          nodes: nodeSet.get() || [],
          edges: matchResult.edges || [],
          totalAmount: nodeSet.length,
          status: matchResult.status || NetworkSubViewMatchingStatus.IDLE,
          errorCode: matchResult.errorCode || 0,
          errorMsg: matchResult.errorMsg || '',
        }
      }
    } else {
      const length = matchResult.classifiedNodes[category] ? matchResult.classifiedNodes[category].length : 0;
      if (limit === -1 || limit >= length) {
        return {
          nodes: matchResult.classifiedNodes[category] || [],
          edges: matchResult.edges || [],
          totalAmount: length,
          status: matchResult.status || NetworkSubViewMatchingStatus.IDLE,
          errorCode: matchResult.errorCode || 0,
          errorMsg: matchResult.errorMsg || '',
        };
      } else {
        const allNodeIdsBySubViewNode = {};
        subViewNodeIds.forEach(nodeId => allNodeIdsBySubViewNode[nodeId] = []);
        const allNodeSetByCategory = new DataSet(matchResult.classifiedNodes[category]);

        matchResult.edges.forEach(edge => {
          if (allNodeIdsBySubViewNode[edge.from] && allNodeSetByCategory.get(edge.to)) {
            allNodeIdsBySubViewNode[edge.from].push(edge.to);
          }
          if (allNodeIdsBySubViewNode[edge.to] && allNodeSetByCategory.get(edge.from)) {
            allNodeIdsBySubViewNode[edge.to].push(edge.from);
          }
        });

        let pos = 0;
        let found = false;
        const callbackFn = nodeId => {
          let nodeIds = allNodeIdsBySubViewNode[nodeId];
          if (nodeIds.length > pos) {
            if (!nodeSet.get(nodeIds[pos])) {
              nodeSet.add(allNodeSet.get(nodeIds[pos]));
              found = true;
            }
          }
        };
        while (nodeSet.length < limit) {
          found = false;
          subViewNodeIds.forEach(callbackFn);
          if (!found) break;
          pos++;
        }
        // noinspection JSCheckFunctionSignatures
        return {
          nodes: nodeSet.get() || [],
          edges: matchResult.edges || [],
          totalAmount: nodeSet.length,
          status: matchResult.status || NetworkSubViewMatchingStatus.IDLE,
          errorCode: matchResult.errorCode || 0,
          errorMsg: matchResult.errorMsg || '',
        }
      }
    }
  };

  /**
   * 通过节点ID获取相连的边ID列表
   *
   * @param {string} nodeId 节点ID
   * @param {boolean} [visibleOnly] 仅获取可见边
   *
   * @return {[string]} 连接的边ID列表
   */
  getConnectedEdgeIds = (nodeId, visibleOnly = false) => {
    this.assertDataReady();
    let result = [];
    if (this._data.nodes.get(nodeId)) {
      this._data.edges.forEach(edge => {
        if ((edge.from === nodeId || edge.to === nodeId) && (!visibleOnly || edge.visible)) {
          result.push(edge.id);
        }
      });
    }
    return result;
  };

  /**
   * 通过ID查找相连的节点ID列表
   *
   * @param {string} objectId 边或节点的ID
   * @param {boolean} [visibleOnly] 仅获取可见边关联的节点
   *
   * @return {[string]} 连接的节点ID列表
   */
  getConnectedNodeIds = (objectId, visibleOnly = false) => {
    const result = this.getConnectedNodeIdsByNodeId(objectId, visibleOnly);
    if (result && result.length > 0) {
      return result;
    }
    return this.getConnectedNodeIdsByEdgeId(objectId);
  };

  /**
   * 通过节点ID查找连接的节点ID列表
   *
   * @param {string} nodeId 节点ID
   * @param {boolean} [visibleOnly] 仅获取可见边关联的节点
   *
   * @return {Array} 连接的节点ID列表
   */
  getConnectedNodeIdsByNodeId = (nodeId, visibleOnly = true) => {
    this.assertDataReady();
    let result = [];
    if (this._data.nodes.get(nodeId)) {
      this._data.edges.forEach(edge => {
        if (visibleOnly && (!edge.visible)) return;
        if (edge.from === nodeId) {
          result.push(edge.to);
        } else if (edge.to === nodeId) {
          result.push(edge.from);
        }
      });
    }
    return result;
  };

  /**
   * 通过边ID查找相关联的节点ID列表
   *
   * @param {string} edgeId 边ID
   * @return {Array} 相关联的节点ID列表
   */
  getConnectedNodeIdsByEdgeId = (edgeId) => {
    this.assertDataReady();
    let result = [];
    let edgeInfo = this._data.edges.get(edgeId);
    if (edgeInfo) {
      result = [edgeInfo.from, edgeInfo.to];
    }
    return result;
  };

  /**
   * 通过ID查找相连的节点列表
   *
   * @param {string} objectId 边或节点的ID
   * @param {boolean} [visibleOnly] 仅获取可见边关联的节点
   *
   * @return {[Node]} 连接的节点列表
   */
  getConnectedNodes = (objectId, visibleOnly = true) => {
    return this._data.nodes.get(this.getConnectedNodeIds(objectId, visibleOnly));
  };

  /**
   * 获取当前展示出的扩展数据结果
   *
   * @param {string} nodeId 节点ID
   *
   * @return {{
   *   nodes: [Node],
   *   edges: [Edge],
   *   solidEdgeIds: [string],
   *   totalAmount: number,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string
   * }}
   */
  getCurrentShownRelatedClueResult = (nodeId) => {
    let relatedClueResult = this.getRelatedClueResult(nodeId) || {};

    return {
      nodes: relatedClueResult.currentNodes || [],
      edges: relatedClueResult.currentEdges || [],
      solidEdgeIds: relatedClueResult.currentSolidEdgeIds || [],
      totalAmount: relatedClueResult.allNodes ? relatedClueResult.allNodes.length : 0,
      status: relatedClueResult.status || NodeExpandingStatus.IDLE,
      errorCode: relatedClueResult.errorCode || 0,
      errorMsg: relatedClueResult.errorMsg || '',
    };
  };

  /**
   * 获取当前展示出的联想数据结果
   *
   * @param {string} nodeId 节点ID
   *
   * @return {{
   *   nodes: [Node],
   *   edges: [Edge],
   *   solidEdgeIds: [string],
   *   totalAmount: number,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string
   * }}
   */
  getCurrentShownRelatedResourceResult = (nodeId) => {
    let relatedResourceResult = this.getRelatedResourceResult(nodeId) || {};

    return {
      nodes: relatedResourceResult.currentNodes || [],
      edges: relatedResourceResult.currentEdges || [],
      solidEdgeIds: relatedResourceResult.currentSolidEdgeIds || [],
      totalAmount: relatedResourceResult.allNodes ? relatedResourceResult.allNodes.length : 0,
      status: relatedResourceResult.status || NodeGrowingStatus.IDLE,
      errorCode: relatedResourceResult.errorCode || 0,
      errorMsg: relatedResourceResult.errorMsg || '',
    };
  };

  /**
   * 获取关系图数据
   *
   * @return {{
   *   viewId: string|undefined,
   *   viewInfo: Object|undefined,
   *   viewOptions: ViewOptions|undefined,
   *   data: {nodes: DataSet, edges: DataSet}|undefined,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string
   * }}
   */
  getData = () => ({
    viewId: this._viewId,
    viewInfo: this._viewInfo,
    viewOptions: this._parsedViewOptions,
    data: this._data,
    timestamp: this._dataLoading.timestamp,
    status: this._dataLoading.status,
    errorCode: this._dataLoading.errorCode,
    errorMsg: this._dataLoading.errorMsg,
  });

  /**
   * 获取关系信息
   *
   * @param {string|string[]|{filter: (function(*): boolean)}} [edgeId] 关系ID
   *
   * @return {Edge|Edge[]}
   */
  getEdge = edgeId => this._data.edges.get(edgeId);

  /**
   * 获取指定节点信息
   *
   * @param {string|string[]|{filter?: (function(*): boolean), order?: string}} [nodeId] 节点ID
   *
   * @return {Node|Node[]}
   */
  getNode = nodeId => this._data.nodes.get(nodeId);

  /**
   * 获取关系主要结构子图
   *
   * @return {Promise}
   */
  getStructureGraph = (limit = 10) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_GetViewStructureGraph(me._viewId, limit).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取指定或全部子图信息
   *
   * @param {string} [subViewId] 子图ID或视图ID
   *
   * @return {[SubViewInfo]|SubViewInfo}
   */
  getSubViewInfo = (subViewId) => {
    if (subViewId === this._viewId) {
      const nodes = this._data.nodes.get() || [];
      /**
       * @type {string[]}
       */
      const nodeIds = nodes.map(node => node.id);
      const name = this._viewInfo ? this._viewInfo.name : '';
      return {
        id: this._viewId,
        name,
        displayName: '',
        nodes,
        nodeIds,
        edges: this._data.edges.get() || [],
        resourceIds: [],
        resources: [],
        tags: [],
        version: 0,
      };
    } else if (subViewId) {
      return this._subViewData && (this._subViewData instanceof DataSet) ? this._subViewData.get(subViewId) : undefined;
    } else {
      return this._subViewData && (this._subViewData instanceof DataSet) ? this._subViewData.get() : undefined;
    }
  };

  /**
   * 通过断言函数隐藏符合条件的节点
   *
   * @param {NodePredicate|string|string[]} predicate 显示节点断言函数、节点ID或节点ID列表
   */
  hideNodes = (predicate) => {
    const nodesToHide = (_.isFunction(predicate) ? this._data.nodes.get() :
      this._data.nodes.get(_.isArray(predicate) ? predicate : []))
      .filter((node, idx, nodes) => node.hidden !== true && (!_.isFunction(predicate) || predicate(node, idx, nodes)))
      .map(node => {
        node.hidden = true;
        return node;
      });
    if (nodesToHide && nodesToHide.length > 0) {
      this._data.nodes.update(nodesToHide);
      this._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
    }
  };

  /**
   * 通过指定图谱或图谱节点加载简报信息列表
   *
   * @param {string[]|string|undefined} [nodeId] 指定节点ID（列表）
   */
  loadBriefingInfoList = (nodeId = undefined) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      const nodeIds = nodeId ? (_.isArray(nodeId) ? nodeId : [`${nodeId}`]) : [];
      API_LoadBriefingInfoList(me._viewId, nodeIds).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

   /**
   * 通过指定图谱或图谱节点加载简报信息列表
   *
   * @param {string[]|string|undefined} [nodeId] 指定节点ID（列表）
   */
    loadBriefingInfoListViewId = (nodeId = undefined,viewId) => {
      let me = this._self;
      return new Promise((resolve, reject) => {
  
        const nodeIds = nodeId ? (_.isArray(nodeId) ? nodeId : [`${nodeId}`]) : [];
        API_LoadBriefingInfoList(viewId||me._viewId, nodeIds).then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response.data.data);
          } else {
            reject(me.getErrorInfo({response}));
          }
        }).catch(error => {
          reject(me.getErrorInfo(error));
        });
      });
    };

  /**
   * 加载图谱配置
   *
   * @param {string} key 配置KEY
   * @param {boolean} forDisplayOnly 是否仅显示用，非仅显示用则记录最后访问时间
   */
  loadConfig = (key, forDisplayOnly = true) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_LoadViewConfig(me._viewId, key, forDisplayOnly).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 加载关系图数据
   *
   * @param {string} [viewId] 关系图ID，可选，不指定则刷新当前关系图数据
   * @param {string} [type] 关系图类型，可选，不指定则加载原始关系图数据，否则根据type返回洞察的分析视图
   * @param {boolean} [withDetail] 是否加载详情
   *
   * @return {Promise}
   */
  loadData = (viewId, type, withDetail = true) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      viewId = viewId || me._viewId;
      if (!viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      if (me._dataLoading.status === NetworkDataLoadingStatus.PROCESSING) {
        // 正在加载，忽略该请求
        reject({code: 102, msg: 'Request is already processing.'});
        return;
      }

      me._PB.emit('default', NetworkEvents.LOADING_DATA, viewId);

      me._viewId = viewId;
      me._viewInfo = undefined;
      me._dataLoading = {
        timestamp: me._dataLoading.timestamp,
        status: NetworkDataLoadingStatus.PROCESSING,
        errorCode: 0,
        errorMsg: '',
      };
      if (me._data.withDetail) {
        // 刷新数据
        me._data.nodes.clear();
        me._data.edges.clear();
      }

      API_GetRelationData(viewId, type, withDetail).then(response => {
        if (me._viewId === viewId) {
          if (response && response.data && response.data.code === 0) {
            let timestamp = me._dataLoading.timestamp;
            me._viewInfo = response.data.data['view'];
            // noinspection JSIgnoredPromiseFromCall
            me.loadViewCover();
            me.parseViewOptions();
            me._dataLoading = {
              timestamp: me._dataLoading.timestamp,
              status: NetworkDataLoadingStatus.SUCCESS,
              errorCode: 0,
              errorMsg: '',
            };
            me._data.nodes.update(response.data.data['nodes']
              .map(node => {
                let newNode = new Node(node);
                let nodeTimestamp = newNode.updateTime || newNode.linkTime;
                if (nodeTimestamp && (timestamp === undefined || timestamp < nodeTimestamp)) {
                  timestamp = nodeTimestamp;
                }
                return newNode;
              }));
            me._dataLoading.timestamp = timestamp;
            me._data.edges.update(response.data.data['edges'].map(edge => {
              if (edge.from === edge.to) {
                return undefined;
              }
              edge._localOnly = false;
              let result = new Edge(edge),
                fromNode = me._data.nodes.get(result.from),
                toNode = me._data.nodes.get(result.to);
              if (!fromNode || !toNode) {
                return undefined;
              }
              result.updateBrightnessFromAccessTimestamp(fromNode, toNode);
              return result;
            }).filter(edge => !!edge));
            me._data.withDetail = withDetail;
            me._PB.emit('default', NetworkEvents.LOADING_DATA_SUCCESS, viewId);
            resolve(me.getData());
            me.internalLoadGravityEdgesForStandaloneNodes();
          } else {
            const {code, msg} = me.getErrorInfo({response});
            me._dataLoading = {
              timestamp: me._dataLoading.timestamp,
              status: NetworkDataLoadingStatus.FAILED,
              errorCode: code,
              errorMsg: msg,
            };
            me._PB.emit('default', NetworkEvents.LOADING_DATA_FAILED, viewId, code, msg);
            reject({code, msg});
          }
        } else {
          reject({code: 190, msg: 'Request expired.'});
        }
      }).catch(error => {
        if (me._viewId === viewId) {
          const {code, msg} = me.getErrorInfo(error);
          me._dataLoading = {
            timestamp: me._dataLoading.timestamp,
            status: NetworkDataLoadingStatus.FAILED,
            errorCode: code,
            errorMsg: msg,
          };
          me._PB.emit('default', NetworkEvents.LOADING_DATA_FAILED, viewId, code, msg);
          reject({code, msg});
        } else {
          reject({code: 190, msg: 'Request expired.'});
        }
      });
    });
  };

  /**
   * 逐步加载关系图数据
   *
   * @param {string} [viewId] 关系图ID，可选，不指定则刷新当前关系图数据
   */
  loadDataGradually = (viewId) => {
    let me = this._self, edgeLimit = 100, round = 0;
    let viewInfoCacheAvailable = false;
    return new Promise((resolve, reject) => {
      viewId = viewId || me._viewId;
      if (!viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      if (me._dataLoading.status === NetworkDataLoadingStatus.PROCESSING) {
        // 正在加载，忽略该请求
        reject({code: 102, msg: 'Request is already processing.'});
        return;
      }

      me._PB.emit('default', NetworkEvents.LOADING_DATA, viewId);

      me._viewId = viewId;
      me._viewInfo = undefined;
      me._dataLoading = {
        timestamp: me._dataLoading.timestamp,
        status: NetworkDataLoadingStatus.PROCESSING,
        errorCode: 0,
        errorMsg: '',
      };
      if (me._data.withDetail) {
        // 刷新数据
        me._data.nodes.clear();
        me._data.edges.clear();
      }

      let loadFailedCatch = error => {
        if (me._viewId === viewId) {
          const {code, msg} = me.getErrorInfo(error);
          me._dataLoading = {
            timestamp: me._dataLoading.timestamp,
            status: NetworkDataLoadingStatus.FAILED,
            errorCode: code,
            errorMsg: msg,
          };
          me._PB.emit('default', NetworkEvents.LOADING_DATA_FAILED, viewId, code, msg);
          reject({code, msg});
        } else {
          reject({code: 190, msg: 'Request expired.'});
        }
      };

      // 加载图谱关系图结构
      API_GetRelationStructure(viewId, edgeLimit).then(response => {
        if (me._viewId === viewId) {
          if (response && response.data && response.data.code === 0) {
            let timestamp = me._dataLoading.timestamp, allNodesLoaded = false, nodesToUpdate = undefined,
              allNodesToUpdate = [], updateLimit = 100;

            // 处理信息
            let handleStructureData = ({edges, nodes}) => {
              me._data.nodes.update(nodes.map(node => new Node({
                ...node,
                type: NODE_TYPE_TEXT,
                // _brightness: 1,
                _structOnly: true,
              })));
              me._data.edges.update(edges.map(edge => {
                if (edge.from === edge.to) {
                  return undefined;
                }
                edge._localOnly = false;
                let result = new Edge(edge),
                  fromNode = me._data.nodes.get(result.from),
                  toNode = me._data.nodes.get(result.to);
                if (!fromNode || !toNode) {
                  return undefined;
                }
                result.becomeHidden();
                result.cancelDarken(0.6);
                // result.cancelDarken(1);
                return result;
              }).filter(edge => !!edge));
              me._data.withDetail = false;
            };

            // 收尾处理
            let afterUpdateNodeFn = () => {
              if (me._cacheStore && me._viewDataCacheStore && (!viewInfoCacheAvailable
                || getCachedViewNodesStatus(me._cacheStore.getState(), {viewId}) !== 'success')) {
                // 更新缓存
                me._viewDataCacheStore.put(JSON.stringify(allNodesToUpdate), `cache:view:${me._viewId}:nodes`).then(() => {
                  me._cacheStore.dispatch(loadViewNodesSuccessAction(viewId, allNodesToUpdate));
                });
              }
              me._dataLoading = {
                timestamp: me._dataLoading.timestamp,
                status: NetworkDataLoadingStatus.SUCCESS,
                errorCode: 0,
                errorMsg: '',
              };
              me._dataLoading.timestamp = timestamp;
              // 删除用户未确认节点
              me._data.nodes.remove(me._data.nodes.get({filter: node => !node.userConfirmed}).map(n => n.id));
              me._data.edges.update(me._data.edges.map(edge => {
                let fromNode = me._data.nodes.get(edge.from), toNode = me._data.nodes.get(edge.to);
                if (!fromNode || !toNode) {
                  return undefined;
                }
                edge.updateBrightnessFromAccessTimestamp(fromNode, toNode);
                return edge;
              }).filter(edge => !!edge));
              me._data.withDetail = true;
              me._PB.emit('default', NetworkEvents.LOADING_DATA_SUCCESS, viewId);
              resolve(me.getData());
              me.internalLoadGravityEdgesForStandaloneNodes();
            };

            // 将节点数据更新到画面中
            let updateNodeFn = () => {
              let nodeList = [], updatedNodeMap = {},
                maxNodesToUpdate = Math.max(updateLimit, nodesToUpdate.length / Math.max(1, 5 - round));
              round++;
              while (nodesToUpdate.length > 0 && nodeList.length < maxNodesToUpdate) {
                nodeList.push(nodesToUpdate.shift());
              }
              me._data.nodes.update(nodeList.map(node => {
                let newNode = new Node({
                  ...node,
                  _brightness: 1,
                  _structOnly: false,
                });
                let nodeTimestamp = newNode.updateTime || newNode.linkTime;
                if (nodeTimestamp && (timestamp === undefined || timestamp < nodeTimestamp)) {
                  timestamp = nodeTimestamp;
                }

                updatedNodeMap[node.id] = true;

                return newNode;
              }));

              me._data.edges.update(me._data.edges.get({
                filter: edge => edge && edge._brightness !== 1
                  && (updatedNodeMap[edge.from] || updatedNodeMap[edge.to]),
              }).map(edge => {
                edge.cancelDarken(1);
                return edge;
              }));

              if (allNodesLoaded && nodesToUpdate.length <= 0) {
                afterUpdateNodeFn();
              } else if (nodesToUpdate.length > 0) {
                setTimeout(() => updateNodeFn(), 5);
              } else {
                nodesToUpdate = undefined;
              }
            };

            let loadNodeListInfoFromRemote = (start = 0, limit = 1000) => {
              API_GetRelationNodeList(
                viewId, {userConfirmed: 1}, start, limit, 'sequence_node_ex', 'ASC'
              ).then(response => {
                if (me._viewId === viewId) {
                  if (response && response.data && response.data.code === 0) {
                    if (response.data.data.length >= limit) {
                      setTimeout(() => loadNodeListInfoFn(start + limit, limit), 10);
                    } else {
                      allNodesLoaded = true;
                    }
                    round = 0;

                    if (nodesToUpdate === undefined) {
                      nodesToUpdate = [];
                      allNodesToUpdate.push.apply(allNodesToUpdate, response.data.data);
                      nodesToUpdate.push.apply(nodesToUpdate, response.data.data);
                      updateNodeFn();
                    } else {
                      allNodesToUpdate.push.apply(allNodesToUpdate, response.data.data);
                      nodesToUpdate.push.apply(nodesToUpdate, response.data.data);
                    }
                  } else {
                    loadFailedCatch({response});
                  }
                } else {
                  reject({code: 190, msg: 'Request expired.'});
                }
              }).catch(loadFailedCatch);
            };

            let loadNodeListInfoFn = (start = 0, limit = 1000) => {
              // 尝试从缓存中读取数据
              if (start === 0 && me._cacheStore && me._viewDataCacheStore && viewInfoCacheAvailable
                && getCachedViewNodesStatus(me._cacheStore.getState(), {viewId}) === 'success') {

                me._viewDataCacheStore.get(`cache:view:${me._viewId}:nodes`).then(dataStr => {
                  if (dataStr) {
                    nodesToUpdate = [];
                    nodesToUpdate.push.apply(nodesToUpdate, JSON.parse(dataStr));
                    allNodesLoaded = true;
                    setTimeout(updateNodeFn, Math.min(3000, Math.round(nodesToUpdate.length / 2)));
                  } else {
                    loadNodeListInfoFromRemote(start, limit);
                  }
                }).catch(() => loadNodeListInfoFromRemote(start, limit));
              } else {
                // 加载节点列表
                loadNodeListInfoFromRemote(start, limit);
              }
            };

            let loadStructureDataFromRemote = () => {
              handleStructureData(response.data.data);
              if (response.data.data['edges'].length >= edgeLimit) {
                // 加载全部结构
                API_GetRelationStructure(viewId).then(response => {
                  if (me._viewId === viewId) {
                    if (response && response.data && response.data.code === 0) {
                      handleStructureData(response.data.data);
                      if (me._cacheStore && me._viewDataCacheStore) {
                        let viewStructure = {
                          nodes: response.data.data.nodes,
                          edges: response.data.data.edges
                        };
                        me._viewDataCacheStore.put(JSON.stringify(viewStructure), `cache:view:${me._viewId}:struct`).then(() => {
                          me._cacheStore.dispatch(loadViewStructureSuccessAction(viewId, viewStructure));
                        });
                      }
                      me._data.withDetail = false;
                      me._PB.emit('default', NetworkEvents.LOADING_STRUCTURE_SUCCESS, viewId);
                      loadNodeListInfoFn();
                    } else {
                      loadFailedCatch({response});
                    }
                  } else {
                    reject({code: 190, msg: 'Request expired.'});
                  }
                }).catch(loadFailedCatch);
              } else {
                if (me._cacheStore && me._viewDataCacheStore) {
                  let viewStructure = {
                    nodes: response.data.data.nodes,
                    edges: response.data.data.edges
                  };
                  me._viewDataCacheStore.put(JSON.stringify(viewStructure), `cache:view:${me._viewId}:struct`).then(() => {
                    me._cacheStore.dispatch(loadViewStructureSuccessAction(viewId, viewStructure));
                  });
                }
                me._data.withDetail = false;
                me._PB.emit('default', NetworkEvents.LOADING_STRUCTURE_SUCCESS, viewId);
                loadNodeListInfoFn();
              }
            }

            let afterClearInvalidCache = () => {
              // noinspection JSIgnoredPromiseFromCall
              me.loadViewCover();

              if (me._cacheStore && me._viewDataCacheStore && viewInfoCacheAvailable
                && getCachedViewStructureStatus(me._cacheStore.getState(), {viewId}) === 'success') {

                me._viewDataCacheStore.get(`cache:view:${me._viewId}:struct`).then(dataStr => {
                  if (dataStr) {
                    handleStructureData(JSON.parse(dataStr));
                    me._data.withDetail = false;
                    me._PB.emit('default', NetworkEvents.LOADING_STRUCTURE_SUCCESS, viewId);
                    loadNodeListInfoFn();
                  } else {
                    loadStructureDataFromRemote();
                  }
                }).catch(() => loadStructureDataFromRemote());
              } else {
                loadStructureDataFromRemote();
              }
            };

            // 检查缓存是否有效
            if (me._cacheStore) {
              let cachedViewInfo = getCachedViewInfo(me._cacheStore.getState(), {viewId});
              if (cachedViewInfo) {
                console.info("d-1:",cachedViewInfo);
                console.info("d-2:",response.data.data['view']);
                if (cachedViewInfo.updateTime === response.data.data['view'].updateTime) {
                  viewInfoCacheAvailable = true;
                  me._viewInfo = cachedViewInfo;
                }
              }
            }

            if (!viewInfoCacheAvailable) {
              me._viewInfo = response.data.data['view'];
              me.parseViewOptions();

              if (me._cacheStore && me._viewDataCacheStore) {
                // 更新缓存
                me._cacheStore.dispatch(invalidateViewDataCacheAction(me._viewId));
                me._cacheStore.dispatch(loadViewInfoSuccessAction(me._viewId, me._viewInfo));
                Promise.all([
                  me._viewDataCacheStore.delete(`cache:view:${me._viewId}:struct`),
                  me._viewDataCacheStore.delete(`cache:view:${me._viewId}:nodes`),
                ]).then(afterClearInvalidCache);
                return;
              }
            }

            afterClearInvalidCache();
          } else {
            loadFailedCatch({response});
          }
        } else {
          reject({code: 190, msg: 'Request expired.'});
        }
      }).catch(loadFailedCatch);
    });
  };

  /**
   * 搜索（精确匹配）指定节点信息
   *
   * @param {string} nodeId 要搜索的节点ID
   * @param {ViewOptions} [options] 搜索条件配置，默认使用视图配置
   * @param {number} [size] 每种类型数据最多获取的结果个数
   *
   * @return {Promise}
   */
  loadExactMatch = (nodeId, options, size = 100) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      if (me._data.nodes.get(nodeId)) {
        if (me.getExactMatchResult(nodeId, 'status') === NodeExactMatchingStatus.PROCESSING) {
          // 操作进行中，忽略多次请求
          reject({code: 102, msg: 'Request is already processing.'});
          return;
        }
        const {area, types, time, keyword} = me.stringifyViewOptions(options);
        // 忽略清理流程
        me.initExactMatchResult(nodeId);
        me.setExactMatchResult(nodeId, 'status', NodeExactMatchingStatus.PROCESSING);
        me._PB.emit('default', NodeEvents.EXACT_MATCHING, nodeId);
        // 发送节点联想请求
        // API_ExactMatchesByNode(this._viewId, nodeId, {area, types, time, keyword},axios)
        API_ExactMatchesByNode(me._viewId, nodeId, {area, types: types.split(','), time, keyword}, size)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              me.onExactMatchResultReceived(nodeId, response.data.data['nodes'], response.data.data['edges']);
              me._PB.emit('default', NodeEvents.EXACT_MATCHING_SUCCESS, nodeId, response.data.data['nodes'],
                response.data.data['edges']);
              resolve(nodeId, response.data.data['nodes'], response.data.data['edges'])
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me.setExactMatchResult(nodeId, {
                status: NodeExactMatchingStatus.FAILED,
                errorCode: code,
                errorMsg: msg,
              });
              me._PB.emit('default', NodeEvents.EXACT_MATCHING_FAILED, nodeId, code, msg);
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me.setExactMatchResult(nodeId, {
              status: NodeExactMatchingStatus.FAILED,
              errorCode: code,
              errorMsg: msg,
            });
            me._PB.emit('default', NodeEvents.EXACT_MATCHING_FAILED, nodeId, code, msg);
            reject({code, msg});
          });
      }
    });
  };

  /**
   * 加载全图文件列表
   *
   * @return {Promise}
   */
  loadFileList = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      let viewId = me._viewId;
      if (me._fileListLoadingStatus.status === NetworkFileListLoadingStatus.PROCESSING) {
        // 操作进行中，忽略多次请求
        reject({code: 102, msg: 'Request is already processing.'});
        return;
      }
      if (me._fileListLoadingStatus.status === NetworkFileListLoadingStatus.IDLE) {
        me._PB.sub(me, 'default',
          [NetworkEvents.FILE_UPLOADED, NetworkEvents.FILE_UPDATED, NetworkEvents.FILE_REMOVED],
          () => me.loadFileList());
      }
      me._files = [];
      me._fileListLoadingStatus = {
        code: 0,
        msg: '',
        status: NetworkFileListLoadingStatus.PROCESSING,
      };
      API_GetFileList(viewId)
        .then(response => {
          if (viewId === me._viewId) {
            if (response && response.data && response.data.code === 0) {
              const fromIdSet = {};
              const multimediaIdSet = {};
              me._files = response.data.data.filter(info => info.fileList && info.fileList.length > 0)
                .map(info => {
                  try {
                    if (info['fileList'] && info['fileList'].find(fileInfo => {
                      const segments = fileInfo['fileName'].split(/\./g);
                      const type = segments.pop().toLowerCase();
                      return segments.length > 0 && MULTIMEDIA_EXT.includes(`.${type}`);
                    })) {
                      multimediaIdSet[info.from] = true;
                      delete fromIdSet[info.from];
                    } else if (!multimediaIdSet[info.from]) {
                      fromIdSet[info.from] = true;
                    }
                    let tmpDecoded = JSON.parse(info['remark']);
                    tmpDecoded = _.isPlainObject(tmpDecoded) ? tmpDecoded : undefined;
                    const {comment, meta, version} = tmpDecoded;
                    return {
                      ...info,
                      remark: comment,
                      meta: _.isArray(meta) ? meta : (
                        info['fileList'] && info['fileList'].length === 1 ? [meta] :
                          info.fileList.map(() => ({i: -1, c: null, m: 0}))
                      ),
                      version,
                    }
                  } catch (e) {
                    return {
                      ...info,
                      meta: info.fileList.map(() => ({i: -1, c: null, m: 0})),
                      version: 0,
                    }
                  }
                });
              me._fileListLoadingStatus = {
                code: 0,
                msg: '',
                status: NetworkFileListLoadingStatus.SUCCESS,
              };
              let nodes = me._data.nodes.get(Object.keys(fromIdSet));
              if (nodes) {
                me._data.nodes.update(nodes.filter(node => !!node).map(node => new Node({
                  ...node,
                  hasProps: true
                })));
              }
              nodes = me._data.nodes.get(Object.keys(multimediaIdSet));
              if (nodes) {
                me._data.nodes.update(nodes.filter(node => !!node).map(node => new Node({
                  ...node,
                  hasMultimedia: true
                })));
              }
              resolve(me.files);
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me._fileListLoadingStatus = {
                code,
                msg,
                status: NetworkFileListLoadingStatus.FAILED,
              };
              reject({code, msg});
            }
          } else {
            reject({code: 190, msg: 'Request expired.'});
          }
        })
        .catch(error => {
          if (viewId === me._viewId) {
            const {code, msg} = me.getErrorInfo(error);
            me._fileListLoadingStatus = {
              code,
              msg,
              status: NetworkFileListLoadingStatus.FAILED,
            };
            reject({code, msg});
          } else {
            reject({code: 190, msg: 'Request expired.'});
          }
        });
    });
  };

  /**
   * 获取与指定节点相关的引力节点列表
   *
   * @param {string} sourceNodeId 指定节点ID
   *
   * @return {Promise}
   */
  loadGravityNodeList = sourceNodeId => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      let _node = me._data.nodes.get(sourceNodeId)
      if (!_node) {
        reject({code: 404, msg: '找不到指定的节点'});
      }
      API_SearchWordListInView(me._viewId, _node.fname).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(me.getNode(response.data.data).filter(n => !!n));
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 加载图谱指标值
   *
   * @param {string} filter
   * @param {number} alg
   * @param {string[]} modifiedNodeIds
   * @param {string} hash
   *
   * @return {Promise}
   */
  loadIndexValue = (filter, alg, modifiedNodeIds, hash) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_LoadViewIndexValue(me._viewId, filter, alg, hash, modifiedNodeIds).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 搜索指定子图信息
   *
   * @param {string} [subViewId] 要搜索的子图ID，子图ID为空时表示全局搜索
   * @param {ViewOptions} [options] 搜索条件配置，默认使用视图配置
   *
   * @return {Promise}
   */
  loadMatchForSubView = (subViewId = this._viewId, options) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const subViewData = me.getSubViewInfo(subViewId);
      if (subViewData && subViewData.nodes.length > 0) {
        if (me.getMatchResultForSubView(subViewId, 'status') === NetworkSubViewMatchingStatus.PROCESSING) {
          // 操作进行中，忽略多次请求
          reject({code: 102, msg: 'Request is already processing.'});
          return;
        }
        const {area, types, time, keyword} = me.stringifyViewOptions(options);
        // 忽略清理流程
        this.initMatchResultForSubView(subViewId);
        this.setMatchResultForSubView(subViewId, 'status', NetworkSubViewMatchingStatus.PROCESSING);
        this._PB.emit('default', NetworkEvents.SUB_VIEW_MATCHING, subViewId);
        // 发送节点联想请求
        API_MatchesBySubView(me._viewId, subViewId, {area, types: types.split(','), time, keyword})
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              me.onMatchResultForSubViewReceived(subViewId, response.data.data['nodes'], response.data.data['edges']);
              me._PB.emit('default', NetworkEvents.SUB_VIEW_MATCHING_SUCCESS, subViewId,
                response.data.data['nodes'], response.data.data['edges']);
              resolve(subViewId, response.data.data['nodes'], response.data.data['edges']);
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me.setMatchResultForSubView(subViewId, {
                status: NetworkSubViewMatchingStatus.FAILED,
                errorCode: code,
                errorMsg: msg,
              });
              me._PB.emit('default', NetworkEvents.SUB_VIEW_MATCHING_FAILED, subViewId, code, msg);
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me.setMatchResultForSubView(subViewId, {
              status: NetworkSubViewMatchingStatus.FAILED,
              errorCode: code,
              errorMsg: msg,
            });
            me._PB.emit('default', NetworkEvents.SUB_VIEW_MATCHING_FAILED, subViewId, code, msg);
            reject({code, msg});
          })
      } else if (!subViewData) {
        reject({code: 404, msg: 'SubView not found.'});
      } else {
        reject({code: 412, msg: 'No nodes in subView'});
      }
    });
  };

  /**
   * 加载与节点匹配的资源
   *
   * @param {string} nodeId 节点ID
   */
  loadMatchedResources = nodeId => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const node = me._data.nodes.get(nodeId);
      // emit loading status
      if (!node) {
        const msg = `Node ${nodeId} not found.`;
        // emit failed status
        reject({code: 404, msg});
        return;
      }
      API_MatchResourcesByNode(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          let resources = [];
          if (response.data.data) {
            resources = response.data.data.map(info => new Node(info));
          }
          // emit success status
          resolve({nodeId, resources});
        } else {
          const {code, msg} = me.getErrorInfo({response});
          // emit failed status
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        // emit failed status
        reject({code, msg});
      });
    });
  };

  /**
   * 加载与节点匹配的标签
   *
   * @param {string} nodeId 节点ID
   */
  loadMatchedTags = nodeId => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const node = me._data.nodes.get(nodeId);
      // emit loading status
      if (!node) {
        const msg = `Node ${nodeId} not found.`;
        // emit failed status
        reject({code: 404, msg});
        return;
      }
      API_MatchTagsByNode(me._viewId, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          let tags = [];
          if (response.data.data) {
            const wordCloudData = response.data.data;
            const nodeText = node.fname;
            for (let wordIdx = 0; wordIdx < wordCloudData['tags'].length; wordIdx++) {
              tags.push({
                text: wordCloudData['tags'][wordIdx],
                value: wordCloudData['values'][wordIdx],
                longestLength: undefined,
              });
            }
            tags = tags.sort((a, b) => {
              let result = b.value - a.value;
              if (result === 0) {
                if (a.longestLength === undefined) {
                  a.longestLength = longestCommonSubstring(a.text, nodeText);
                }
                if (b.longestLength === undefined) {
                  b.longestLength = longestCommonSubstring(b.text, nodeText);
                }
                result = b.longestLength - a.longestLength;
              }
              return result;
            });
          }
          // emit success status
          resolve({nodeId, tags});
        } else {
          const {code, msg} = me.getErrorInfo({response});
          // emit failed status
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        // emit failed status
        reject({code, msg});
      });
    });
  };

  /**
   * 加载节点详细信息
   *
   * @param {string} nodeId 节点ID
   *
   * @return {Promise}
   */
  loadNodeDetailInfo = nodeId => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      let node = me._data.nodes.get(nodeId);
      if (!node) {
        const msg = `Node ${nodeId} not found.`;
        reject({code: 404, msg});
        return;
      }
      if (node && node._systemRecommend && node.aiRelatedTo) {
        // 通过连接自动发现的节点
        me.saveNodeWithRelations(node.id, node.aiRelatedTo, 'self')
          .then(data => {
            me.loadNodeDetailInfo(data['node_id_replacement'][node.id]).then(resolve).catch(reject);
          })
          .catch(reject);
      } else if (node && node.meta && node.meta.replaceNodeId) {
        me._PB.emit('default', NodeEvents.LOADING_DETAIL_INFO, nodeId);
        // 可能为虚假节点
        me._PB.emit('default', NodeEvents.DETAIL_INFO_LOADED, nodeId, node);
        resolve(nodeId, node);
      } else {
        me._PB.emit('default', NodeEvents.LOADING_DETAIL_INFO, nodeId);
        API_GetNodeInfo(me._viewId, node[TYPE_FIELD_NAME], nodeId).then(response => {
          if (response && response.data && response.data.code === 0) {
            node = me._data.nodes.get(nodeId);
            const detailNode = new Node({...node, ...response.data.data, detailLoaded: true});
            me._data.nodes.update(detailNode);
            me._PB.emit('default', NodeEvents.DETAIL_INFO_LOADED, nodeId, detailNode);
            if (node.url && node.url.length>1 && (node.description == undefined || node.description == '' || node.description == null)){
              window.open(`${node.url}`);
            }
            resolve(nodeId, detailNode);
          } else {
            const {code, msg} = me.getErrorInfo({response});
            me._PB.emit('default', NodeEvents.LOAD_DETAIL_INFO_FAILED, nodeId, code, msg);
            reject({code, msg});
          }
        }).catch(error => {
          const {code, msg} = me.getErrorInfo(error);
          me._PB.emit('default', NodeEvents.LOAD_DETAIL_INFO_FAILED, nodeId, code, msg);
          reject({code, msg});
        });
      }
    });
  };

  /**
   * 加载图谱信息排行榜
   *
   * @param {string} [type] 排行榜类型
   */
  /*
  loadRankData = type => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_LoadRankData(me._viewId, type).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };
  */
  loadRankData = type => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      let params = {
        view_id:me._viewId,
        particle:type=='phrase'?1:0
      };
      API_LoadRankData_V2(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 联想（线索）指定节点信息
   *
   * @param {string} nodeId 要联想的节点ID
   *
   * @return {Promise}
   */
  loadRelatedClue = (nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      if (me._data.nodes.get(nodeId)) {
        if (me.getRelatedClueResult(nodeId, 'status') === NodeExpandingStatus.PROCESSING) {
          // 操作进行中，忽略多次请求
          reject({code: 102, msg: 'Request is already processing.'});
          return;
        }
        me.clearRelatedClueResult(nodeId); // 清除已有的联想结果
        // 发送节点扩展请求
        me.initRelatedClueResult(nodeId); //
        me.setRelatedClueResult(nodeId, 'status', NodeExpandingStatus.PROCESSING);
        me._PB.emit('default', NodeEvents.EXPANDING, nodeId);
        API_ExpandNode(me._viewId, nodeId)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              me.onRelatedClueResultReceived(nodeId,
                response.data.data['nodes'].map(n => ({
                  ...n, type: NODE_TYPE_TEXT,
                  meta: {...(n.meta || {}), replaceNodeId: true}
                })),
                response.data.data['edges'].map(edge => {
                  if (edge.from !== nodeId) {
                    return edge;
                  } else {
                    return {...edge, from: edge.to, to: edge.from};
                  }
                }));
              me._PB.emit('default', NodeEvents.EXPANDING_SUCCESS, nodeId);
              resolve(me.getRelatedClueResult(nodeId));
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me.setRelatedClueResult(nodeId, {
                status: NodeExpandingStatus.FAILED,
                errorCode: code,
                errorMsg: msg,
              });
              me._PB.emit('default', NodeEvents.EXPANDING_FAILED, nodeId, code, msg);
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me.setRelatedClueResult(nodeId, {
              status: NodeExpandingStatus.FAILED,
              errorCode: code,
              errorMsg: msg,
            });
            me._PB.emit('default', NodeEvents.EXPANDING_FAILED, nodeId, code, msg);
            reject({code, msg});
          });
      } else {
        reject({code: 404, msg: `Node ${nodeId} not found.`});
      }
    });
  };

  /**
   * 联想（资源）指定节点信息
   *
   * @param {string} nodeId 要联想的节点ID
   *
   * @return {Promise}
   */
  loadRelatedResource = (nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      if (me._data.nodes.get(nodeId)) {
        if (me.getRelatedResourceResult(nodeId, 'status') === NodeGrowingStatus.PROCESSING) {
          // 操作进行中，忽略多次请求
          reject({code: 102, msg: 'Request is already processing.'});
          return;
        }
        me.clearRelatedResourceResult(nodeId);
        // 发送节点联想请求
        me.initRelatedResourceResult(nodeId);
        me.setRelatedResourceResult(nodeId, 'status', NodeGrowingStatus.PROCESSING);
        me._PB.emit('default', NodeEvents.GROWING, nodeId);
        API_GrowNode(me._viewId, nodeId)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              me.onRelatedResourceResultReceived(nodeId, response.data.data['nodes'], response.data.data['edges']);
              me._PB.emit('default', NodeEvents.GROWING_SUCCESS, nodeId);
              resolve(me.getRelatedResourceResult(nodeId));
            } else {
              const {code, msg} = me.getErrorInfo({response});
              me.setRelatedResourceResult(nodeId, {
                status: NodeGrowingStatus.FAILED,
                errorCode: code,
                errorMsg: msg,
              });
              me._PB.emit('default', NodeEvents.GROWING_FAILED, nodeId, code, msg);
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            me.setRelatedResourceResult(nodeId, {
              status: NodeGrowingStatus.FAILED,
              errorCode: code,
              errorMsg: msg,
            });
            me._PB.emit('default', NodeEvents.GROWING_FAILED, nodeId, code, msg);
            reject({code, msg});
          });
      } else {
        reject({code: 404, msg: `Node ${nodeId} not found.`});
      }
    });
  };

  /**
   * 加载视图的所有子图
   *
   * @return {Promise}
   */
  loadSubViewData = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      let viewId = me._viewId;
      me.assertDataReady();
      if (me._subViewData === viewId) {
        // 操作进行中，忽略多次请求
        reject({code: 102, msg: 'Request is already processing.'});
        return;
      }
      me._PB.emit('default', NetworkEvents.LOADING_SUB_VIEW, viewId);
      me._subViewData = viewId;
      // 这里不再获取真实的子图数据，为使改动最小，直接生成单张子图
      const nodeGroups = splitNodeGroups(me._data.nodes.get(), me._data.edges.get());
      me._subViewData = new DataSet();
      nodeGroups.forEach((nodeGroup, idx) => {
        const nodes = nodeGroup.nodes
          .filter(node => node[TYPE_FIELD_NAME] === NODE_TYPE_TAG || node[TYPE_FIELD_NAME] === NODE_TYPE_TEXT);
        const resources = nodeGroup.nodes
          .filter(node => node[TYPE_FIELD_NAME] !== NODE_TYPE_TAG && node[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT);
        me._subViewData.add({
          id: nodeGroup.nodes[0].id,
          displayName: `子图${idx + 1}`,
          name: `子图${idx + 1}`,
          nodeIds: nodes.map(node => node.id),
          nodes,
          edges: nodeGroup.edges,
          resourceIds: resources.map(resource => resource.id),
          resources,
          tags: [],
          version: 0,
        });
      });
      me._PB.emit('default', NetworkEvents.LOADING_SUB_VIEW_SUCCESS, viewId, me._subViewData);
      resolve(me.getSubViewInfo());
    });
  };

  /**
   * 加载当前用户的图谱配置
   *
   * @param {string} key 配置KEY
   * @param {boolean} forDisplayOnly 是否仅显示用，非仅显示用则记录最后访问时间
   */
  loadUserConfig = (key, forDisplayOnly = true) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_LoadViewUserConfig(me._viewId, key, forDisplayOnly).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取图谱封面
   *
   * @return {Promise}
   */
  loadViewCover = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      if (!me._viewInfo['hasCover']) {
        me._viewInfo.coverDataUrl = undefined;
        resolve(undefined);
        return;
      }

      API_GetViewCover(me._viewId).then(response => {
        if (response && response.data && response.data.code === 0) {
          me._viewInfo.coverDataUrl = (response.data.data === null ? undefined : response.data.data);
          resolve(me._viewInfo['coverDataUrl']);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取图谱信息
   *
   * @return {Promise}
   */
  loadViewInfo = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_GetViewById(me._viewId).then(response => {
        if (response && response.data && response.data.code === 0) {
          me._viewInfo = response.data.data;
          // noinspection JSIgnoredPromiseFromCall
          me.loadViewCover();
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 锁定图谱
   *
   * @return {Promise}
   */
  lock = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_LockView(me._viewId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  }

  /**
   * 添加单次事件监听处理函数
   *
   * @param self 事件处理函数所在对象
   * @param name 事件名称
   * @param handler 事件处理函数
   * @return {ViewDataProvider}
   */
  once = (self, name, handler) => {
    this._PB.once(self, 'default', name, handler);
    return this;
  };

  /**
   * 按图谱推荐节点
   *
   * @param {number} resultType
   * @param {number} [userId] 当前用户ID，同步发现出的节点所有者为当前用户
   *
   * @return {Promise}
   */
  recommendByView = (resultType, {userId}) => {
    let me = this._self, nowStr = moment().format("YYYY-MM-DD HH:mm:ss");
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_RecommendByView(me._viewId, resultType).then(response => {
        if (response && response.data && response.data.code === 0) {
          let nodes = response.data.data.map(n => {
            let data = {
              ...n,
              status: 0,
              "delete": 0,
              updateTime: nowStr,
              updateTimestamp: nowStr,
              userId,
              viewId: me._viewId,
              linkTime: nowStr,
              linkTimestamp: nowStr,
              accessTimestamp: nowStr,
              userPreferredType: n.aiPreferredType,
              vrVisibleType: n.aiPreferredType,
              vrDisplayText: n.fname,
              meta: {...n.meta, replaceNodeId: true, scale: 1},
              type: NODE_TYPE_TEXT,
              owner: 0,
            };
            return new Node(data);
          });
          me._data.nodes.update(nodes);
          me.internalLoadGravityEdgesForStandaloneNodes(nodes.map(n => n.id));
          me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
          resolve(nodes);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 按图谱分析线索推荐公司
   *
   * @param start {int} 线索起始位置
   * @param limit {int} 线索数量
   *
   * @return {Promise}
   */
  recommendCompanyByView = (start = 0, limit = 10) => {
    let me = this._self;

    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_RecommendCompanyByView(me._viewId, start, limit).then(response => {
        if (response && response.data && response.data.code === 0) {
          let {nodes, edges, total} = response.data.data;
          resolve({nodes, edges, total});
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 恢复关系图
   *
   * @param {Node[]} nodes
   * @param {Edge[]} edges
   */
  recoverRelationGraph = (nodes, edges) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      let nodesToAddToGraph = [], nodesToAddToView = [], allNodes = [];
      nodes.forEach(node => {
        if (me._data.nodes.get(node.id)) return;
        allNodes.push(node);
        if (node.status === 0 && (!node.aiRelatedTo)) {
          nodesToAddToGraph.push(node);
        } else {
          nodesToAddToView.push(node);
        }
      });

      let edgesToAddToGraph = [], edgesToAddToView = [], allEdges = [];
      edges.forEach(edge => {
        if (me._data.edges.get(edge.id)) return;
        allEdges.push(edge);
        if (edge.status === 1) {
          edgesToAddToView.push(edge);
        } else {
          edgesToAddToGraph.push(edge);
        }
      });

      const addToGraph = () => {
        me._data.nodes.add(allNodes);
        me._data.edges.add(allEdges);
        if (nodesToAddToGraph.length > 0) {
          me._PB.emit('default', NodeEvents.ADDED, ADD_TO_GRAPH, nodesToAddToGraph.map(n => n.id),
            nodesToAddToGraph);
        }
        if (nodesToAddToView.length > 0) {
          me._PB.emit('default', NodeEvents.ADDED, (ADD_TO_VIEW | ADD_TO_GRAPH),
            nodesToAddToView.map(n => n.id), nodesToAddToView);
        }
        if (edgesToAddToGraph.length > 0) {
          me._PB.emit('default', EdgeEvents.ADDED, ADD_TO_GRAPH, edgesToAddToGraph.map(e => e.id),
            edgesToAddToGraph);
        }
        if (edgesToAddToView.length > 0) {
          me._PB.emit('default', EdgeEvents.ADDED, ADD_TO_VIEW | ADD_TO_GRAPH, edgesToAddToGraph.map(e => e.id),
            edgesToAddToView);
        }
        me._PB.emit('default', NetworkEvents.RELATION_CHANGED);
        me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
      }
      if (nodesToAddToView.length === 0 && edgesToAddToView.length === 0) {
        addToGraph();
        resolve();
      } else {
        this.recoverRelationGraphPromise(
          nodesToAddToView.map(node => node.id),
          edgesToAddToView.map(edge => ({from: edge.from, to: edge.to}))
        ).then(() => {
          addToGraph();
          resolve();
        }).catch(errorInfo => {
          reject(errorInfo);
        });
      }
    });
  };

  /**
   * 重新加载并局部更新关系图数据
   *
   * @param {boolean} [ignoreRemovedElements] 更新时忽略删除项，忽略后可实现增量加载
   * @param {string} [type] 关系图类型，可选，不指定则加载原始关系图数据，否则根据type返回洞察的分析视图
   *
   * @return {Promise}
   */
  reloadData = (ignoreRemovedElements = true, type) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();

      if (me._dataReloading.status === NetworkDataReloadingStatus.PROCESSING) {
        // 正在加载，忽略该请求
        reject({code: 102, msg: 'Request is already processing.'});
        return;
      }

      me._PB.emit('default', NetworkEvents.RELOADING_DATA, me._viewId);

      let viewId = me._viewId;
      // me._viewInfo = undefined;
      me._dataReloading = {
        timestamp: me._dataReloading.timestamp || me._dataLoading.timestamp,
        status: NetworkDataReloadingStatus.PROCESSING,
        errorCode: 0,
        errorMsg: '',
      };

      API_GetRelationData(
        viewId, type, true, ignoreRemovedElements ? me._dataReloading.timestamp : undefined,
        false
      ).then(response => {
        if (me._viewId === viewId) {
          if (response && response.data && response.data.code === 0) {
            // me._viewInfo = response.data.data['view'];
            // me.parseViewOptions();
            me._dataReloading = {
              timestamp: me._dataReloading.timestamp,
              status: NetworkDataReloadingStatus.SUCCESS,
              errorCode: 0,
              errorMsg: '',
            };
            me._data.pendingChanges = {
              nodesToAdd: [],
              nodesToUpdate: [],
              nodesToRemove: [],
              edgesToAdd: [],
              edgesToRemove: [],
              ...me._data.pendingChanges,
            };
            let newNodeIds = {};
            let newEdgeIds = {};
            let timestamp = me._dataReloading.timestamp;
            response.data.data['nodes'].forEach(node => {
              let newNode = new Node(node);
              let originalNode = me._data.nodes.get(node.id);
              if (!originalNode) {
                me._data.pendingChanges.nodesToAdd.push(newNode);
              } else if (
                (originalNode.updateTime || originalNode.linkTime) !== (newNode.updateTime || newNode.linkTime)
              ) {
                me._data.pendingChanges.nodesToUpdate.push(newNode);
              }
              newNodeIds[node.id] = newNode;
              let nodeTimestamp = newNode.updateTime || newNode.linkTime;
              if (nodeTimestamp && (timestamp === undefined || timestamp < nodeTimestamp)) {
                timestamp = nodeTimestamp;
              }
            });
            me._dataReloading.timestamp = timestamp;
            response.data.data['edges'].forEach(edge => {
              edge._localOnly = false;
              let newEdge = new Edge(edge);
              let originalEdge = me._data.edges.get(newEdge.id);
              if (!originalEdge) {
                let fromNode = newNodeIds[newEdge.from], toNode = newNodeIds[newEdge.to];
                newEdge.updateBrightnessFromAccessTimestamp(fromNode, toNode);
                me._data.pendingChanges.edgesToAdd.push(newEdge);
              }
              newEdgeIds[newEdge.id] = newEdge;
            });
            if (!ignoreRemovedElements) {
              me._data.nodes.get().forEach(node => {
                if (!newNodeIds[node.id]) me._data.pendingChanges.nodesToRemove.push(node.id);
              });
              me._data.edges.get().forEach(edge => {
                if (!newEdgeIds[edge.id]) me._data.pendingChanges.edgesToRemove.push(edge.id);
              });
            }
            me._data.pendingChanges = {
              nodesToAdd: _.uniqBy(me._data.pendingChanges.nodesToAdd, _.property('id')),
              nodesToUpdate: _.uniqBy(me._data.pendingChanges.nodesToUpdate, _.property('id')),
              nodesToRemove: _.uniqBy(me._data.pendingChanges.nodesToRemove, _.property('id')),
              edgesToAdd: _.uniqBy(me._data.pendingChanges.edgesToAdd, _.property('id')),
              edgesToRemove: _.uniqBy(me._data.pendingChanges.edgesToRemove, _.property('id')),
            };
            me._PB.emit('default', NetworkEvents.RELOADING_DATA_SUCCESS, viewId);
            resolve(me.getData());
          } else {
            const {code, msg} = me.getErrorInfo({response});
            me._dataReloading = {
              timestamp: me._dataReloading.timestamp,
              status: NetworkDataReloadingStatus.FAILED,
              errorCode: code,
              errorMsg: msg,
            };
            me._PB.emit('default', NetworkEvents.RELOADING_DATA_FAILED, viewId, code, msg);
            reject({code, msg});
          }
        } else {
          reject({code: 190, msg: 'Request expired.'});
        }
      }).catch(error => {
        if (me._viewId === viewId) {
          const {code, msg} = me.getErrorInfo(error);
          me._dataReloading = {
            timestamp: me._dataReloading.timestamp,
            status: NetworkDataReloadingStatus.FAILED,
            errorCode: code,
            errorMsg: msg,
          };
          me._PB.emit('default', NetworkEvents.RELOADING_DATA_FAILED, viewId, code, msg);
          reject({code, msg});
        } else {
          reject({code: 190, msg: 'Request expired.'});
        }
      });
    });
  };

  /**
   * 从指定节点删除指定附件
   *
   * @param {string} propertyId 附件ID
   * @param {string} [nodeId] 节点ID
   *
   * @return {Promise}
   */
  removeFiles = (propertyId, nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      me._PB.emit('default', NetworkEvents.FILE_REMOVING, me._viewId, propertyId);
      if (nodeId) {
        me._PB.emit('default', NodeEvents.PROPERTY_REMOVING, nodeId, propertyId);
      }
      this.removeFilesPromise(propertyId, nodeId).then(() => {
        me._PB.emit('default', NetworkEvents.FILE_REMOVED, me._viewId, propertyId);
        if (nodeId) {
          const node = me._data.nodes.get(nodeId);
          if (node) {
            me._PB.emit('default', NodeEvents.UPDATED, [nodeId], [node]);
          }
          me._PB.emit('default', NodeEvents.PROPERTY_REMOVED, nodeId, propertyId);
        }
        resolve();
      }).catch(errorInfo => {
        me._PB.emit('default', NetworkEvents.FILE_REMOVE_FAILED, me._viewId, propertyId, errorInfo);
        if (nodeId) {
          me._PB.emit('default', NodeEvents.PROPERTY_REMOVE_FAILED, nodeId, propertyId, errorInfo);
        }
        reject(errorInfo);
      });
    });
  };

  /**
   * 从指定节点删除指定某一个附件
   *
   * @param {string} propertyId 附件某一个文件ID
   * @param {string} [nodeId] 节点ID
   *
   * @return {Promise}
   */
  removeFileItem = (propertyId, nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      me._PB.emit('default', NetworkEvents.FILE_REMOVING, me._viewId, propertyId);
      if (nodeId) {
        me._PB.emit('default', NodeEvents.PROPERTY_REMOVING, nodeId, propertyId);
      }
      this.removeFileItemPromise(propertyId, nodeId).then(() => {
        me._PB.emit('default', NetworkEvents.FILE_REMOVED, me._viewId, propertyId);
        if (nodeId) {
          const node = me._data.nodes.get(nodeId);
          if (node) {
            me._PB.emit('default', NodeEvents.UPDATED, [nodeId], [node]);
          }
          me._PB.emit('default', NodeEvents.PROPERTY_REMOVED, nodeId, propertyId);
        }
        resolve();
      }).catch(errorInfo => {
        me._PB.emit('default', NetworkEvents.FILE_REMOVE_FAILED, me._viewId, propertyId, errorInfo);
        if (nodeId) {
          me._PB.emit('default', NodeEvents.PROPERTY_REMOVE_FAILED, nodeId, propertyId, errorInfo);
        }
        reject(errorInfo);
      });
    });
  };

  /**
   * 从画面中删除节点
   *
   * @param {string} nodeId 要删除的节点ID
   *
   * @return {Promise}
   */
  removeNode = (nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      const node = me._data.nodes.get(nodeId);
      if (!node) {
        reject({code: 404, msg: `Node ${nodeId} not found.`});
      } else {
        const removeFromGraph = () => {
          // 从画面中删除
          const relatedEdgeIds = me.getConnectedEdgeIds(nodeId);
          if (_.isArray(relatedEdgeIds)) {
            const relatedEdges = me._data.edges.get(relatedEdgeIds);
            const solidEdgeIds = relatedEdges.filter(edge => edge.status === 1).map(edge => edge.id);
            const tempEdgeIds = relatedEdges.filter(edge => edge.status === 0).map(edge => edge.id);
            const solidEdges = me._data.edges.get(solidEdgeIds);
            const tempEdges = me._data.edges.get(tempEdgeIds);
            me._data.edges.remove(solidEdgeIds);
            // me._data.edges.remove(tempEdgeIds); 不删除临时边，页面不会显示，且恢复时较为方便
            me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH, solidEdges);
            me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_GRAPH, tempEdges);
          }
          const removedNode = me._data.nodes.get(nodeId);
          me._data.nodes.remove(nodeId);
          if (removedNode) {
            me._PB.emit('default', NodeEvents.REMOVED,
              removedNode.status === 1 ? (REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH) : REMOVE_FROM_GRAPH, [removedNode]);
            me._PB.emit('default', NetworkEvents.RELATION_CHANGED);
            me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
          }
        };
        if (node.status === 0 && (!node.aiRelatedTo)) {
          me._PB.emit('default', NodeEvents.REMOVING, REMOVE_FROM_GRAPH, [nodeId]);
          removeFromGraph();
          resolve();
        } else {
          this.removeNodeInfoPromise(nodeId).then(() => {
            me._PB.emit('default', NodeEvents.REMOVING, (REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH), [nodeId]);
            removeFromGraph();
            resolve();
          }).catch(errorInfo => {
            me._PB.emit('default', NodeEvents.REMOVE_FAILED, [nodeId], errorInfo);
            reject(errorInfo);
          });
        }
      }
    });
  };

  /**
   * 从画面中批量删除节点
   *
   * @param {string[]} nodeIds 要删除的节点ID列表
   *
   * @return {Promise}
   */
  removeNodes = nodeIds => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      const nodes = me._data.nodes.get(nodeIds);
      if (!nodes || nodes.length <= 0) {
        reject({code: 404, msg: `Nodes not found.`});
      } else {
        let nodesToRemoveFromGraph = [], nodesToRemoveFromView = [], allNodes = [];
        nodes.forEach(node => {
          allNodes.push(node);
          if (node.status === 0 && (!node.aiRelatedTo)) {
            nodesToRemoveFromGraph.push(node);
          } else {
            nodesToRemoveFromView.push(node);
          }
        });

        const removeFromGraph = () => {
          // 从画面中删除
          let relatedEdgeIds = [];
          allNodes.forEach(node => relatedEdgeIds.push.apply(relatedEdgeIds, me.getConnectedEdgeIds(node.id)));
          relatedEdgeIds = _.uniq(relatedEdgeIds);
          if (_.isArray(relatedEdgeIds)) {
            const relatedEdges = me._data.edges.get(relatedEdgeIds);
            const solidEdgeIds = relatedEdges.filter(edge => edge.status === 1).map(edge => edge.id);
            const tempEdgeIds = relatedEdges.filter(edge => edge.status === 0).map(edge => edge.id);
            const solidEdges = me._data.edges.get(solidEdgeIds);
            const tempEdges = me._data.edges.get(tempEdgeIds);
            me._data.edges.remove(relatedEdgeIds);
            // me._data.edges.remove(tempEdgeIds); 不删除临时边，页面不会显示，且恢复时较为方便
            me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH, solidEdges);
            me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_GRAPH, tempEdges);
          }

          me._data.nodes.remove(nodeIds);
          if (me._exploreResult && me._exploreResult.nodes.length > 0) {
            nodeIds.forEach(nodeId => {
              if (me._exploreResult.nodes[0].aiRelatedTo === nodeId) {

                me._data.nodes.remove(me._exploreResult.nodes);
                me._data.edges.remove(me._exploreResult.edges);
                me._exploreResult = undefined;
              }
            });
          }

          if (nodesToRemoveFromGraph.length > 0) {
            me._PB.emit('default', NodeEvents.REMOVED, REMOVE_FROM_GRAPH, nodesToRemoveFromGraph);
          }
          if (nodesToRemoveFromView.length > 0) {
            me._PB.emit('default', NodeEvents.REMOVED, (REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH),
              nodesToRemoveFromView);
          }
          me._PB.emit('default', NetworkEvents.RELATION_CHANGED);
          me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
        };

        if (nodesToRemoveFromGraph.length > 0) {
          me._PB.emit('default', NodeEvents.REMOVING, REMOVE_FROM_GRAPH,
            nodesToRemoveFromGraph.map(node => node.id));
        }
        if (nodesToRemoveFromView.length > 0) {
          me._PB.emit('default', NodeEvents.REMOVING, (REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH),
            nodesToRemoveFromView.map(node => node.id));
        }
        if (nodesToRemoveFromView.length === 0) {
          removeFromGraph();
          resolve();
        } else {
          this.removeNodeInfoListPromise(nodesToRemoveFromView.map(node => node.id)).then(() => {
            removeFromGraph();
            resolve();
          }).catch(errorInfo => {
            me._PB.emit('default', NodeEvents.REMOVE_FAILED, allNodes.map(node => node.id), errorInfo);
            reject(errorInfo);
          });
        }
      }
    });
  };

  /**
   * 删除未固定节点的位置
   */
  removeNoneFixedNodePosition = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_RemoveNoneFixedNodePositionsByView(me._viewId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 删除关联关系
   *
   * @param {string} fromNodeId 来源节点ID
   * @param {string} toNodeId 目的节点ID
   * @param {boolean} preferUnSolidNode 是否自动虚化因固化操作保留下来的节点，虚化后关联关系也将虚化，否则直接删除
   *
   * @return {Promise}
   */
  removeRelation = (fromNodeId, toNodeId, preferUnSolidNode = false) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      const fromNode = me._data.nodes.get(fromNodeId);
      const toNode = me._data.nodes.get(toNodeId);
      if (!fromNode || !toNode) {
        reject({code: 404, msg: `Node ${fromNode ? toNodeId : fromNodeId} not found.`});
        return;
      }
      const relatedProcessingList =
        me._relationProcessingList.filter(e => e.includes(fromNodeId) && e.includes(toNodeId));
      if (relatedProcessingList && relatedProcessingList.length > 0) {
        // 对应关系正在操作中，忽略该请求
        console.log(`Relation between ${fromNodeId} and ${toNodeId} is currently removing...`);
        reject({code: 102, msg: 'Request is already processing.'});
        return;
      }
      me._relationProcessingList.push([fromNodeId, toNodeId]);
      me._PB.emit('default', EdgeEvents.REMOVING, [{fromNodeId, toNodeId}]);

      // 如果目标节点是因为固化操作而保留下来的，且指定需要自动删除，则直接删除节点，否则仅删关联关系
      /**
       * @type {undefined|string}
       */
      let nodeIdToRemove = undefined;
      let relatedEdges = [];
      if (preferUnSolidNode) {
        // 当前关系两端节点仅可能有一个是因为固化操作而保留下来的，因此做如下判断
        if (me._solidNodeIdsByRelation.includes(fromNodeId)) {
          relatedEdges = me._data.edges.get(me.getConnectedEdgeIds(fromNodeId)) || [];
          if (relatedEdges.length === 1) nodeIdToRemove = fromNodeId;
        } else if (me._solidNodeIdsByRelation.includes(toNodeId)) {
          relatedEdges = me._data.edges.get(me.getConnectedEdgeIds(toNodeId)) || [];
          if (relatedEdges.length === 1) nodeIdToRemove = toNodeId;
        }
      }
      if (nodeIdToRemove) {
        this.removeNodeInfoPromise(nodeIdToRemove).then(() => {
          // 将节点改为未固化状态，将边改为未固化状态
          const unSolidNode = me._data.nodes.get(nodeIdToRemove);
          if (unSolidNode) {
            unSolidNode.cancelSolid();
            me._data.nodes.update(unSolidNode);
          }
          const edgeInfo = me._data.edges.get(relatedEdges[0].id);
          if (edgeInfo) {
            edgeInfo.cancelSolid();
            me._data.edges.update(edgeInfo);
          }
          me._solidNodeIdsByRelation = me._solidNodeIdsByRelation.filter(nodeId => nodeId !== nodeIdToRemove);
          me._relationProcessingList =
            me._relationProcessingList.filter(e => !(e.includes(fromNodeId) && e.includes(toNodeId)));
          if (unSolidNode) me._PB.emit('default', NodeEvents.REMOVED, REMOVE_FROM_VIEW, [unSolidNode]);
          if (edgeInfo) me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW, [edgeInfo]);
          me._PB.emit('default', NetworkEvents.RELATION_CHANGED);
          me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
          resolve();
        }).catch(errorInfo => {
          me._relationProcessingList =
            me._relationProcessingList.filter(e => !(e.includes(fromNodeId) && e.includes(toNodeId)));
          me._PB.emit('default', EdgeEvents.REMOVE_FAILED, [{fromNodeId, toNodeId}], errorInfo);
          reject(errorInfo);
        });
      } else {
        relatedEdges = me._data.edges.get(me.getConnectedEdgeIds(fromNodeId)).filter(edge =>
          (edge.from === fromNodeId && edge.to === toNodeId) || (edge.from === toNodeId && edge.to === fromNodeId)
        );
        const relatedEdgeIds = relatedEdges.map(edge => edge.id);
        if (relatedEdgeIds && relatedEdgeIds.length > 0) {
          const removedEdgeIds = [];
          relatedEdgeIds.forEach(edgeId => {
            me.removeEdgeInfoPromise(edgeId).then(() => {
              removedEdgeIds.push(edgeId);
              if (removedEdgeIds.length === relatedEdgeIds.length) {
                me._relationProcessingList =
                  me._relationProcessingList.filter(e => !(e.includes(fromNodeId) && e.includes(toNodeId)));
                if (preferUnSolidNode) {
                  // 将关系改为未固化状态
                  const edgeInfo = me._data.edges.get(relatedEdgeIds[0]);
                  if (edgeInfo) {
                    edgeInfo.cancelSolid();
                    me._data.edges.update(edgeInfo);
                  }
                  me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW, [edgeInfo]);
                } else {
                  // 将关系删除
                  const edgeInfo = me._data.edges.get(relatedEdgeIds[0]);
                  if (edgeInfo) {
                    me._data.edges.remove(edgeId);
                  }
                  me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH, [edgeInfo]);
                }
                resolve();
              } else {
                // 删除多余的关系，仅容错用
                const edgeInfo = me._data.edges.get(relatedEdgeIds[0]);
                if (edgeInfo) {
                  me._data.edges.remove(edgeId);
                }
                me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH, [edgeInfo]);
              }
              me._PB.emit('default', NetworkEvents.RELATION_CHANGED);
              me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
            }).catch(errorInfo => {
              me._relationProcessingList =
                me._relationProcessingList.filter(e => !(e.includes(fromNodeId) && e.includes(toNodeId)));
              me._PB.emit('default', EdgeEvents.REMOVE_FAILED, [{fromNodeId, toNodeId}], errorInfo);
              reject(errorInfo);
            });
          });
        } else {
          resolve();
        }
      }
    });
  };

  /**
   * 删除关系图（用于撤销添加子图操作）
   *
   * @param {string[]} nodeIds
   * @param {{from: string, to: string}[]} edgeKeyList
   */
  removeRelationGraph = (nodeIds, edgeKeyList) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      const nodes = me._data.nodes.get(nodeIds);
      const edges = me._data.edges.get(edgeKeyList.map(({from, to}) => defaultEdgeId(from, to)));

      if ((!nodes || nodes.length <= 0) && (!edges || edges.length <= 0)) {
        reject({code: 404, msg: `Nothing to do.`});
        return;
      }

      let nodesToRemoveFromGraph = [], nodesToRemoveFromView = [], allNodes = [];
      nodes.forEach(node => {
        allNodes.push(node);
        if (node.status === 0 && (!node.aiRelatedTo)) {
          nodesToRemoveFromGraph.push(node);
        } else {
          nodesToRemoveFromView.push(node);
        }
      });

      let /*edgesToRemoveFromGraph = [],*/ edgesToRemoveFromView = [], allEdges = [];
      edges.forEach(edge => {
        allEdges.push(edge);
        if (!edge._localOnly) {
          edgesToRemoveFromView.push(edge);
        }/* else {
          edgesToRemoveFromGraph.push(edge);
        }*/
      });

      const removeFromGraph = () => {
        // 从画面中删除
        let relatedEdgeIds = [];
        allNodes.forEach(node => relatedEdgeIds.push.apply(relatedEdgeIds, me.getConnectedEdgeIds(node.id)));
        edges.forEach(edge => relatedEdgeIds.push(edge.id));
        relatedEdgeIds = _.uniq(relatedEdgeIds);
        if (_.isArray(relatedEdgeIds)) {
          const relatedEdges = me._data.edges.get(relatedEdgeIds);
          const solidEdgeIds = relatedEdges.filter(edge => edge.status === 1).map(edge => edge.id);
          const tempEdgeIds = relatedEdges.filter(edge => edge.status === 0).map(edge => edge.id);
          const solidEdges = me._data.edges.get(solidEdgeIds);
          const tempEdges = me._data.edges.get(tempEdgeIds);
          me._data.edges.remove(relatedEdgeIds);
          // me._data.edges.remove(tempEdgeIds); 不删除临时边，页面不会显示，且恢复时较为方便
          if (solidEdges.length > 0) {
            me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH, solidEdges);
          }
          if (tempEdges.length > 0) {
            me._PB.emit('default', EdgeEvents.REMOVED, REMOVE_FROM_GRAPH, tempEdges);
          }
        }

        me._data.nodes.remove(nodeIds);
        if (me._exploreResult && me._exploreResult.nodes.length > 0) {
          nodeIds.forEach(nodeId => {
            if (me._exploreResult.nodes[0].aiRelatedTo === nodeId) {

              me._data.nodes.remove(me._exploreResult.nodes);
              me._data.edges.remove(me._exploreResult.edges);
              me._exploreResult = undefined;
            }
          });
        }

        if (nodesToRemoveFromGraph.length > 0) {
          me._PB.emit('default', NodeEvents.REMOVED, REMOVE_FROM_GRAPH, nodesToRemoveFromGraph);
        }
        if (nodesToRemoveFromView.length > 0) {
          me._PB.emit('default', NodeEvents.REMOVED, (REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH),
            nodesToRemoveFromView);
        }
        me._PB.emit('default', NetworkEvents.RELATION_CHANGED);
        me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
      };

      if (nodesToRemoveFromGraph.length > 0) {
        me._PB.emit('default', NodeEvents.REMOVING, REMOVE_FROM_GRAPH,
          nodesToRemoveFromGraph.map(node => node.id));
      }
      if (nodesToRemoveFromView.length > 0) {
        me._PB.emit('default', NodeEvents.REMOVING, (REMOVE_FROM_VIEW | REMOVE_FROM_GRAPH),
          nodesToRemoveFromView.map(node => node.id));
      }
      if (nodesToRemoveFromView.length === 0 && edgesToRemoveFromView.length === 0) {
        removeFromGraph();
        resolve();
      } else {
        this.removeRelationGraphPromise(
          nodesToRemoveFromView.map(node => node.id),
          edgesToRemoveFromView.map(edge => ({from: edge.from, to: edge.to}))
        ).then(() => {
          removeFromGraph();
          resolve();
        }).catch(errorInfo => {
          me._PB.emit('default', NodeEvents.REMOVE_FAILED, allNodes.map(node => node.id), errorInfo);
          me._PB.emit('default', EdgeEvents.REMOVE_FAILED, allEdges.map(edge => edge.id), errorInfo);
          reject(errorInfo);
        });
      }
    });
  }

  /**
   * 将指定节点的下一批扩展结果数据展示出来，同时返回展示数据
   *
   * @param {string} nodeId 节点ID
   *
   * @return {{
   *   nodes: [Node],
   *   edges: [Edge],
   *   solidEdgeIds: [string],
   *   totalAmount: number,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string,
   *   changed: boolean
   * }}
   */
  replaceWithNextRelatedClueResult = (nodeId) => {
    let result = {...this.getCurrentShownRelatedClueResult(nodeId), changed: false};
    let me = this._self;

    if (result.status !== NodeExpandingStatus.SUCCESS) return result;

    let relatedClueResult = this.getRelatedClueResult(nodeId);
    if (relatedClueResult.newEdges.length <= 0) {
      // 提示用户没有扩展出数据
      result.totalAmount = 0;
      return result;
    }
    if (relatedClueResult.currentStartPos === 0 && relatedClueResult.newEdges.length < relatedClueResultPerStep) {
      // 提示用户没有更多数据可以展示
      return result;
    }
    this.clearRelatedClueResult(nodeId);

    // 将数据加入图中
    const currentNodes = [];
    const nodesToAdd = [];
    const edgesToAdd = [];
    relatedClueResult.currentStartPos = relatedClueResult.nextStartPos;
    while (edgesToAdd.length < relatedClueResultPerStep && relatedClueResult.nextStartPos < relatedClueResult.allNodes.length) {
      let node = relatedClueResult.allNodes[relatedClueResult.nextStartPos];
      let idx = relatedClueResult.newEdges.findIndex(edge => (
        (edge.from === nodeId && edge.to === node.id) || (edge.from === node.id && edge.to === nodeId)
      ));
      if (idx >= 0) {
        edgesToAdd.push(relatedClueResult.newEdges[idx]);
      } else {
        throw new Error(`Edge not found, two node ids: ${nodeId} and ${node.id}`);
      }
      if (!this._data.nodes.get(node.id)) nodesToAdd.push(node);
      currentNodes.push(node);
      relatedClueResult.nextStartPos++;
    }
    if (relatedClueResult.nextStartPos >= relatedClueResult.newEdges.length) {
      relatedClueResult.nextStartPos = 0;
    }
    relatedClueResult.currentlyShowingAmount = edgesToAdd.length;
    relatedClueResult.currentNodes = currentNodes;
    relatedClueResult.currentEdges = edgesToAdd;
    // 过滤掉已有数据
    const filteredNodesToAdd = nodesToAdd.filter(node => !relatedClueResult.currentSolidNodeIds.includes(node.id));
    filteredNodesToAdd.forEach(node => node.withNodeId = nodeId);
    const filteredEdgesToAdd = edgesToAdd.filter(edge => !relatedClueResult.currentSolidEdgeIds.includes(edge.id));
    const nodeIdsAdded = this._data.nodes.add(filteredNodesToAdd);
    const edgeIdsAdded = this._data.edges.add(filteredEdgesToAdd);
    this._PB.emit('default', EdgeEvents.ADDED, ADD_TO_GRAPH, edgeIdsAdded, filteredEdgesToAdd);
    this._PB.emit('default', NodeEvents.ADDED, ADD_TO_GRAPH, nodeIdsAdded, filteredNodesToAdd);
    this._PB.emit('default', NetworkEvents.RELATION_CHANGED);
    this._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);

    this.setRelatedClueResult(nodeId, relatedClueResult);

    let edges = this._data.edges.get(this.getConnectedEdgeIds(nodeId));
    let nodes = [];
    edges.forEach(edge => edge.from === nodeId ? nodes.push(me._data.nodes.get(edge.to)) :
      nodes.push(me._data.nodes.get(edge.from)));
    this._PB.emit('default', NodeEvents.CONNECTED_NODES_UPDATED, nodeId, {nodes, edges});

    return {...this.getCurrentShownRelatedClueResult(nodeId), changed: true};
  };

  /**
   * 将指定节点的下一批联想结果数据展示出来，同时返回展示数据
   *
   * @param {string} nodeId 节点ID
   *
   * @return {{
   *   nodes: [Node],
   *   edges: [Edge],
   *   solidEdgeIds: [string],
   *   totalAmount: number,
   *   status: string,
   *   errorCode: number,
   *   errorMsg: string,
   *   changed: boolean
   * }}
   */
  replaceWithNextRelatedResourceResult = (nodeId) => {
    let result = {...this.getCurrentShownRelatedResourceResult(nodeId), changed: false};
    let me = this._self;

    if (result.status !== NodeGrowingStatus.SUCCESS) return result;

    let relatedResourceResult = this.getRelatedResourceResult(nodeId);
    if (relatedResourceResult.newEdges.length <= 0) {
      // 提示用户没有扩展出数据
      result.totalAmount = 0;
      return result;
    }
    if (relatedResourceResult.currentStartPos === 0 &&
      relatedResourceResult.newEdges.length < relatedResourceResultPerStep) {

      // 提示用户没有更多数据可以展示
      return result;
    }
    this.clearRelatedResourceResult(nodeId);

    // 将数据加入图中
    const currentNodes = [];
    const nodesToAdd = [];
    const edgesToAdd = [];
    relatedResourceResult.currentStartPos = relatedResourceResult.nextStartPos;
    while (edgesToAdd.length < relatedResourceResultPerStep &&
    relatedResourceResult.nextStartPos < relatedResourceResult.allNodes.length) {

      let node = relatedResourceResult.allNodes[relatedResourceResult.nextStartPos];
      let idx = relatedResourceResult.newEdges.findIndex(edge => (
        (edge.from === nodeId && edge.to === node.id) || (edge.from === node.id && edge.to === nodeId)
      ));
      if (idx >= 0) {
        edgesToAdd.push(relatedResourceResult.newEdges[idx]);
      } else {
        throw new Error(`Edge not found, two node ids: ${nodeId} and ${node.id}`);
      }
      if (!this._data.nodes.get(node.id)) nodesToAdd.push(node);
      currentNodes.push(node);
      relatedResourceResult.nextStartPos++;
    }
    if (relatedResourceResult.nextStartPos >= relatedResourceResult.newEdges.length) {
      relatedResourceResult.nextStartPos = 0;
    }
    relatedResourceResult.currentlyShowingAmount = edgesToAdd.length;
    relatedResourceResult.currentNodes = currentNodes;
    relatedResourceResult.currentEdges = edgesToAdd;
    // 过滤掉已有数据
    const filteredNodesToAdd = nodesToAdd.filter(node => !relatedResourceResult.currentSolidNodeIds.includes(node.id));
    filteredNodesToAdd.forEach(node => node.withNodeId = nodeId);
    const filteredEdgesToAdd = edgesToAdd.filter(edge => !relatedResourceResult.currentSolidEdgeIds.includes(edge.id));
    const nodeIdsAdded = this._data.nodes.add(filteredNodesToAdd);
    const edgeIdsAdded = this._data.edges.add(filteredEdgesToAdd);
    this._PB.emit('default', EdgeEvents.ADDED, ADD_TO_GRAPH, edgeIdsAdded, filteredEdgesToAdd);
    this._PB.emit('default', NodeEvents.ADDED, ADD_TO_GRAPH, nodeIdsAdded, filteredNodesToAdd);
    this._PB.emit('default', NetworkEvents.RELATION_CHANGED);
    this._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);

    this.setRelatedResourceResult(nodeId, relatedResourceResult);

    let edges = this._data.edges.get(this.getConnectedEdgeIds(nodeId));
    let nodes = [];
    edges.forEach(edge => edge.from === nodeId ? nodes.push(me._data.nodes.get(edge.to)) :
      nodes.push(me._data.nodes.get(edge.from)));
    this._PB.emit('default', NodeEvents.CONNECTED_NODES_UPDATED, nodeId, {nodes, edges});

    return {...this.getCurrentShownRelatedResourceResult(nodeId), changed: true};
  };

  /**
   * 添加节点及其相关的所有关联关系
   *
   * @param {string} nodeId 待保留节点ID
   * @param {string} [ignoreVisibilityCheckToNodeId] 不论到指定节点的关系是否可见，都保留
   * @param {String} [senderId] 发送者标识
   *
   * @return {Promise}
   */
  saveNodeWithRelations = (nodeId, ignoreVisibilityCheckToNodeId = undefined, senderId = undefined) => {
    this.assertDataReady();
    let me = this._self;
    return new Promise((resolve, reject) => {
      const node = me._data.nodes.get(nodeId);
      if (!node) {
        reject({code: 404, msg: `Node ${nodeId} not found.`});
        return;
      }
      let connectedEdges = [];
      this._data.edges.forEach(edge => {
        let anotherNode = undefined;
        if (edge.from === nodeId) {
          anotherNode = me._data.nodes.get(edge.to);
        } else if (edge.to === nodeId) {
          anotherNode = me._data.nodes.get(edge.from);
        }
        if (!edge.visible && (!anotherNode || anotherNode.id !== ignoreVisibilityCheckToNodeId)) return;
        if (anotherNode && anotherNode.userConfirmed) {
          connectedEdges.push({...edge, userConfirmed: true, status: 1});
        }
      });
      let tmpNode = {...node, userConfirmed: true, meta: {...(node.meta || {}), status: 1}};
      if (tmpNode.meta['replaceNodeId']) {
        delete tmpNode.meta['replaceNodeId'];
        tmpNode.replaceNodeId = true;
        tmpNode.forceAdd = true;
      }
      me.saveRelationGraph(
        [tmpNode], connectedEdges, undefined, false, senderId, false
      ).then(resolve).catch(reject);
    });
  };

  /**
   * 保存简单关系图至图谱
   *
   * @param {Object[]} nodes 要添加的简单节点列表
   * @param {Object[]} edges 要添加的简单边列表
   * @param {string|boolean} [toNodeId]
   * 指定一个已有节点，新节点初始位置将位于该指定节点周围，如不指定则自动判定，为false则初始位于画面中心附近
   * @param {boolean} [testOnly] 是否仅测试
   * @param {String} [senderId] 发送者标识
   * @param {boolean|undefined} [doAnalyze] 是否启动智能分析
   * @return {Promise}
   */
  saveRelationGraph = (
    nodes, edges, toNodeId = false, testOnly = false,
    senderId = undefined, doAnalyze = undefined
  ) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      let relatedNodeIds = [];
      // 遍历需要操作的边，获取与操作相关的节点ID
      edges = edges.filter(edge => {
        if (edge.from && edge.to) {
          let relatedProcessingList =
            me._relationProcessingList.filter(e => e.includes(edge.from) && e.includes(edge.to));
          if (relatedProcessingList && relatedProcessingList.length > 0) {
            // 对应关系正在操作中，忽略该请求
            console.log(`Relation between ${edge.from} and ${edge.to} is currently processing...`);
            return false;
          } else {
            relatedNodeIds.push(edge.from);
            relatedNodeIds.push(edge.to);
            return true;
          }
        } else {
          if (edge.from) {
            relatedNodeIds.push(edge.from);
          }
          if (edge.to) {
            relatedNodeIds.push(edge.to);
          }
          return true;
        }
      });
      nodes.forEach(node => {
        if (node.id && me._data.nodes.get(node.id)) {
          relatedNodeIds.push(node.id);
        }
      });
      relatedNodeIds = _.uniq(relatedNodeIds);
      for (let i = 0; i < relatedNodeIds.length; i++) {
        if (!me._data.nodes.get(relatedNodeIds[i])) {
          reject({code: 404, msg: `Node ${relatedNodeIds[i]} not found.`});
          return;
        }
      }
      if (!testOnly) {
        edges.forEach(edge => {
          if (edge.from && edge.to) {
            me._relationProcessingList.push([edge.from, edge.to]);
            me._PB.emit('default', EdgeEvents.ADDING, [{fromNodeId: edge.from, toNodeId: edge.to}]);
          }
        });
      }
      me.addRelationPromise(nodes, edges, testOnly, (doAnalyze === undefined ? !testOnly : !!doAnalyze)).then(data => {
        const {
          existed_nodes,
          existed_edges,
          existed_node_ids,
          node_match,
          extra_properties,
        } = data;
        let changedNodes = data['changed_nodes'],
          changedEdges = data['changed_edges'],
          addedNodes = data['nodes'],
          addedEdges = data['edges'],
          nodeIdReplacement = data['node_id_replacement'];
        let nodeIdReplacementRevert = {};
        let resultNodes = [];
        let resultEdges = [];
        // 将不同事件记录下来，最后一次性触发
        const events = {
          [NodeEvents.ADDED]: {
            [ADD_TO_VIEW]: {nodeIds: [], nodes: []},
            [ADD_TO_GRAPH | ADD_TO_VIEW]: {nodeIds: [], nodes: []},
          },
          [NodeEvents.UPDATED]: {nodeIds: [], nodes: []},
          [EdgeEvents.ADDED]: {
            [ADD_TO_VIEW]: {edgeIds: [], edges: []},
            [ADD_TO_GRAPH | ADD_TO_VIEW]: {edgeIds: [], edges: []},
          },
          [EdgeEvents.UPDATED]: {edgeIds: [], edges: []},
        };

        // 优先处理替换问题
        if (nodeIdReplacement) {
          // 替换节点、替换边
          let originalNodeIds = Object.keys(nodeIdReplacement), nodesToReplace = [],
            edgesToReplace = me._data.edges.get({
              filter: edge => originalNodeIds.includes(edge.from) || originalNodeIds.includes(edge.to)
            });
          for (let nodeId in nodeIdReplacement) {
            if (Object.hasOwnProperty.call(nodeIdReplacement, nodeId)) {
              nodeIdReplacementRevert[nodeIdReplacement[nodeId]] = nodeId;
              let nodeToReplace = me._data.nodes.get(nodeId);
              if (nodeToReplace) {
                nodesToReplace.push(nodeToReplace);
              }
            }
          }

          relatedNodeIds.forEach((nodeId, idx) => {
            if (nodeIdReplacement[nodeId]) {
              relatedNodeIds[idx] = nodeIdReplacement[nodeId];
            }
          });

          me._data.nodes.remove(originalNodeIds);
          me._data.edges.remove(edgesToReplace.map(edge => edge.id));

          nodesToReplace.forEach(node => node.id = nodeIdReplacement[node.id]);
          edgesToReplace = edgesToReplace.map(edge => {
            if (nodeIdReplacement[edge.from]) edge.from = nodeIdReplacement[edge.from];
            if (nodeIdReplacement[edge.to]) edge.to = nodeIdReplacement[edge.to];
            delete edge.id;
            return new Edge(edge);
          });

          me._data.nodes.add(nodesToReplace);
          me._data.edges.add(edgesToReplace);

          me._PB.emit('default', NodeEvents.ID_REPLACED, nodeIdReplacement, senderId);
        }

        const nodeMap = {};
        changedNodes.forEach(node => {
          nodeMap[node.id] = node;
          {
            // 尝试更新节点
            /**
             * @type {Node}
             */
            let oldNode = me._data.nodes.get(node.id);
            if (oldNode) {
              node = new Node({...oldNode, ...node});
              if (!testOnly) {
                if (oldNode.status !== 1) {
                  events[NodeEvents.ADDED][ADD_TO_VIEW].nodeIds.push(node.id);
                  events[NodeEvents.ADDED][ADD_TO_VIEW].nodes.push(node);
                }
                events[NodeEvents.UPDATED].nodeIds.push(node.id);
                events[NodeEvents.UPDATED].nodes.push(node);
                me._data.nodes.update(node);
                // 这里忽略：me._solidNodeIdsByRelation.push(node.id);
              }
              resultNodes.push(node);
            }
          }
        });
        addedNodes.forEach(node => {
          nodeMap[node.id] = node;
          if (relatedNodeIds.includes(node.id)) {
            // 画面中已有节点添加至数据库完成，尝试更新画面中节点
            /**
             * @type {Node}
             */
            let oldNode = me._data.nodes.get(node.id);
            if (oldNode) {
              node = new Node({...oldNode, ...node});
              if (oldNode.status !== 1) {
                if (!testOnly) {
                  me._data.nodes.update(node);
                  // 这里忽略：me._solidNodeIdsByRelation.push(node.id);
                  events[NodeEvents.ADDED][ADD_TO_VIEW].nodeIds.push(node.id);
                  events[NodeEvents.ADDED][ADD_TO_VIEW].nodes.push(node);
                  events[NodeEvents.UPDATED].nodeIds.push(node.id);
                  events[NodeEvents.UPDATED].nodes.push(node);
                }
              }
              resultNodes.push(node);
            }
          } else {
            // 添加点
            if (toNodeId) {
              node.withNodeId = toNodeId;
            }
            node = new Node(node);
            if (node.userId==undefined){
              node.userId = senderId;
            }
            if (!testOnly) {
              me._data.nodes.add(node);
              events[NodeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].nodeIds.push(node.id);
              events[NodeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].nodes.push(node);
            }
            resultNodes.push(node);
          }
        });

        // 更新与节点相关的边
        if (!testOnly) {
          resultNodes.forEach(node => {
            let connectedEdgeIds = me.getConnectedEdgeIds(node.id);
            if (connectedEdgeIds && connectedEdgeIds.length > 0) {
              let connectedEdges = me._data.edges.get(connectedEdgeIds);
              if (connectedEdges && connectedEdges.length > 0) {
                connectedEdges.forEach(edge => edge._brightnessFromAccessTimestamp = 1);
                me._data.edges.update(connectedEdges);
              }
            }
          });
        }

        let targetEdgeId, targetEdge;
        changedEdges.forEach(edge => {
          let relatedEdges = [];
          const relatedEdgeIds = me.getConnectedEdgeIds(edge.from);
          if (relatedEdgeIds) {
            relatedEdges = me._data.edges.get(relatedEdgeIds).filter(edgeInfo =>
              (edgeInfo.from === edge.from && edgeInfo.to === edge.to) ||
              (edgeInfo.from === edge.to && edgeInfo.to === edge.from)
            );
          }
          if (relatedEdges.length > 0) {
            targetEdge = new Edge({
              ...relatedEdges[0],
              ...edge,
              _localOnly: false,
            });
            // 更新边
            if (!testOnly) {
              if (!targetEdge.visible) {
                targetEdge.visible = true;
                targetEdge.hidden = false;
              }
              me._data.edges.update(targetEdge);
              targetEdgeId = targetEdge.id;
              events[EdgeEvents.UPDATED].edgeIds.push(targetEdgeId);
              events[EdgeEvents.UPDATED].edges.push(targetEdge);
            }
            resultEdges.push(targetEdge);
          }
        });
        addedEdges.forEach(edge => {
          let relatedEdges = [];
          const relatedEdgeIds = me.getConnectedEdgeIds(edge.from);
          if (relatedEdgeIds) {
            relatedEdges = me._data.edges.get(relatedEdgeIds).filter(edgeInfo =>
              (edgeInfo.from === edge.from && edgeInfo.to === edge.to) ||
              (edgeInfo.from === edge.to && edgeInfo.to === edge.from)
            );
          }
          let fromNode = me._data.nodes.get(edge.from) || nodeMap[edge.from];
          let toNode = me._data.nodes.get(edge.to) || nodeMap[edge.to];
          if (relatedEdges.length > 0) {
            targetEdge = new Edge({
              ...relatedEdges[0],
              ...edge,
              _localOnly: false,
            });
            // 更新边
            if (!testOnly) {
              if (fromNode && toNode) {
                targetEdge.updateBrightnessFromAccessTimestamp(fromNode, toNode);
              }
              if (!targetEdge.visible) {
                targetEdge.visible = true;
                targetEdge.hidden = false;
              }
              me._data.edges.update(targetEdge);
              targetEdgeId = targetEdge.id;
              events[EdgeEvents.ADDED][ADD_TO_VIEW].edgeIds.push(targetEdgeId);
              events[EdgeEvents.ADDED][ADD_TO_VIEW].edges.push(targetEdge);
              events[EdgeEvents.UPDATED].edgeIds.push(targetEdgeId);
              events[EdgeEvents.UPDATED].edges.push(targetEdge);
            }
            resultEdges.push(targetEdge);
          } else if (fromNode && toNode) {
            // 添加边
            edge._localOnly = false;
            targetEdge = new Edge(edge);
            targetEdge.updateBrightnessFromAccessTimestamp(fromNode, toNode);
            if (!testOnly) {
              targetEdgeId = me._data.edges.add(targetEdge)[0];
              events[EdgeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].edgeIds.push(targetEdgeId);
              events[EdgeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].edges.push(me._data.edges.get(targetEdgeId));
              targetEdge = me._data.edges.get(targetEdgeId);
            }
            resultEdges.push(targetEdge);
          }
          // 检查相连节点，处理相关隐藏边
          if (!testOnly) {
            [fromNode, toNode].forEach(node => {
              if (!node) return;
              let edges = me.getEdge(me.getConnectedEdgeIds(node.id));
              if (edges && edges.length > 1) {
                let hiddenEdgeIds = edges.filter(edge => {
                  if (!edge.visible) {
                    let anotherNodeId = edge.from === node.id ? edge.to : edge.from;
                    return !anotherNodeId || (me.getConnectedEdgeIds(anotherNodeId, true) || []).length > 0;
                  }
                  return false;
                }).map(edge => edge.id);
                hiddenEdgeIds && hiddenEdgeIds.length > 0 && me._data.edges.remove(hiddenEdgeIds);
              }
            });
          }
        });

        if (!testOnly) {
          resultEdges.forEach(edge => {
            me._relationProcessingList =
              me._relationProcessingList.filter(e => !(e.includes(edge.from) && e.includes(edge.to)));
          });
          if (events[EdgeEvents.ADDED][ADD_TO_VIEW].edgeIds.length > 0) {
            me._PB.emit('default', EdgeEvents.ADDED, ADD_TO_VIEW, events[EdgeEvents.ADDED][ADD_TO_VIEW].edgeIds,
              events[EdgeEvents.ADDED][ADD_TO_VIEW].edges, senderId);
          }
          if (events[EdgeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].edgeIds.length > 0) {
            me._PB.emit('default', EdgeEvents.ADDED, ADD_TO_GRAPH | ADD_TO_VIEW,
              events[EdgeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].edgeIds,
              events[EdgeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].edges, senderId);
          }
          if (events[EdgeEvents.UPDATED].edgeIds.length > 0) {
            me._PB.emit('default', EdgeEvents.UPDATED, events[EdgeEvents.UPDATED].edgeIds,
              events[EdgeEvents.UPDATED].edges, senderId);
          }
          if (events[NodeEvents.ADDED][ADD_TO_VIEW].nodeIds.length > 0) {
            me._PB.emit('default', NodeEvents.ADDED, ADD_TO_VIEW, events[NodeEvents.ADDED][ADD_TO_VIEW].nodeIds,
              events[NodeEvents.ADDED][ADD_TO_VIEW].nodes, toNodeId, senderId);
          }
          if (events[NodeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].nodeIds.length > 0) {
            me._PB.emit('default', NodeEvents.ADDED, ADD_TO_GRAPH | ADD_TO_VIEW,
              events[NodeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].nodeIds,
              events[NodeEvents.ADDED][ADD_TO_GRAPH | ADD_TO_VIEW].nodes, toNodeId, senderId);
          }
          if (events[NodeEvents.UPDATED].nodeIds.length > 0) {
            me._PB.emit('default', NodeEvents.UPDATED, events[NodeEvents.UPDATED].nodeIds,
              events[NodeEvents.UPDATED].nodes, senderId);
          }
          me._PB.emit('default', NetworkEvents.RELATION_CHANGED, senderId);
          me._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED, senderId);
          // me.internalLoadGravityEdgesForStandaloneNodes(addedNodes.map(node => node.id));
        }
        resolve({
          nodes: resultNodes,
          edges: resultEdges,
          existed_nodes,
          existed_edges,
          existed_node_ids,
          node_match,
          extra_properties
        });
      }).catch(errorInfo => {
        const edgeSimpleInfoList = [];
        edges.forEach(edge => {
          if (edge.from && edge.to) {
            me._relationProcessingList =
              me._relationProcessingList.filter(e => !(e.includes(edge.from) && e.includes(edge.to)));
            edgeSimpleInfoList.push({fromNodeId: edge.from, toNodeId: edge.to});
          }
        });
        me._PB.emit('default', EdgeEvents.ADD_FAILED, edgeSimpleInfoList, errorInfo, senderId);
        reject(errorInfo);
      });
    });
  };

  /**
   * 通过断言函数显示符合条件的节点
   *
   * @param {NodePredicate|string|string[]} predicate 显示节点断言函数、节点ID或节点ID列表
   */
  showNodes = (predicate) => {
    const nodesToShow = (_.isFunction(predicate) ? this._data.nodes.get() :
      this._data.nodes.get(_.isArray(predicate) ? predicate : []))
      .filter((node, idx, nodes) => node.hidden === true && (!_.isFunction(predicate) || predicate(node, idx, nodes)))
      .map(node => {
        node.hidden = false;
        return node;
      });
    if (nodesToShow && nodesToShow.length > 0) {
      this._data.nodes.update(nodesToShow);
      this._PB.emit('default', NetworkEvents.VISIBLE_RELATION_CHANGED);
    }
  };

  /**
   * 引力场搜索
   *
   * @param {String} text 待搜索文本
   *
   * @return {Promise}
   */
  smartSearch = (text) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_SmartSearchInView(me._viewId, text).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(me.getNode(response.data.data).filter(n => !!n && getNodeDisplayTitle(n).indexOf(text) < 0));
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 引力场搜索关系图（关系结构联想）
   *
   * @param {String} text 待搜索文本
   *
   * @return {Promise}
   */
  smartSearchGraphInAllView = (text) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_SmartSearchGraphInAllView(me._viewId, text).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 引力场搜索
   *
   * @param {String} text 待搜索文本
   * @param {number} [limit] 数量限制
   * @param {String} [vrVisibleType] 需要返回的节点可见类型
   *
   * @return {Promise}
   */
  smartSearchNodeInAllView = (text, limit = 30, vrVisibleType) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_SmartSearchNodeInAllView(me._viewId, text, limit, vrVisibleType).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 引力场搜索
   *
   * @param {String} text 待搜索文本
   * @param {String} [nodeId] 当前节点ID，系统会避免返回当前节点
   *
   * @return {Promise}
   */
  smartSearchUserInAllView = (text, nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      API_SmartSearchUserInAllView(me._viewId, text, nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          const {code, msg} = me.getErrorInfo({response});
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        reject({code, msg});
      });
    });
  };

  /**
   * 添加事件监听处理函数
   *
   * @param self 事件处理函数所在对象
   * @param name 事件名称
   * @param handler 事件处理函数
   * @return {ViewDataProvider}
   */
  subscribe = (self, name, handler) => {
    this._PB.sub(self, 'default', name, handler);
    return this;
  };

  sub = this.subscribe;

  /**
   * 取消某个对象对所有事件的监听
   *
   * @param self 事件处理函数所在对象
   * @return {ViewDataProvider}
   */
  unSubscribe = (self) => {
    this._PB.remove(self);
    return this;
  };

  unSub = this.unSubscribe;

  /**
   * 取消固定指定节点
   *
   * @param {string} nodeId 要取消固定的节点ID
   *
   * @return {Promise}
   */
  unFixNode = (nodeId) => {
    let me = this._self;
    return me.updateNodeInfo({id: nodeId, fixed: false, silentlyUpdate: true}, true);
  };

  /**
   * 解锁图谱
   *
   * @return {Promise}
   */
  unlock = () => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_UnlockView(me._viewId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  }

  updateEdgeInfo = edgeInfo => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const edgeId = edgeInfo ? edgeInfo.id : undefined;
      if (!edgeId) {
        reject({code: 500, msg: 'Invalid edge id.'});
        return;
      }

      const oldEdgeInfo = me._data.edges.get(edgeId);
      if (!oldEdgeInfo) {
        reject({code: 404, msg: `Edge ${edgeId} not found.`});
        return;
      }

      edgeInfo = {...oldEdgeInfo, ...edgeInfo};
      me._PB.emit('default', EdgeEvents.UPDATING, [edgeInfo]);
      API_UpdateEdgeInfoList(me._viewId, [edgeInfo]).then(response => {
        if (response && response.data && response.data.code === 0) {
          edgeInfo._localOnly = false;
          const newEdge = new Edge(edgeInfo);
          me._data.edges.update(newEdge);
          me._PB.emit('default', EdgeEvents.UPDATED, [edgeId], [newEdge]);
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          me._PB.emit('default', EdgeEvents.UPDATE_FAILED, [edgeId], code, msg);
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        me._PB.emit('default', EdgeEvents.UPDATE_FAILED, [edgeId], code, msg);
        reject({code, msg});
      });
    })
  };

  updateEdgeInfoList = edgeInfoList => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const edgeIdList = [];
      const edgeListToUpdate = [];
      edgeInfoList.forEach(edgeInfo => {
        if (edgeInfo && edgeInfo.id) {
          let oldEdgeInfo = me._data.edges.get(edgeInfo.id);
          if (oldEdgeInfo) {
            edgeIdList.push(edgeInfo.id);
            edgeListToUpdate.push({...oldEdgeInfo, ...edgeInfo});
          }
        }
      });
      if (edgeIdList.length <= 0) {
        reject({code: 404, msg: `No edge(s) found.`});
        return;
      }

      me._PB.emit('default', EdgeEvents.UPDATING, edgeListToUpdate);
      API_UpdateEdgeInfoList(me._viewId, edgeListToUpdate).then(response => {
        if (response && response.data && response.data.code === 0) {
          const newEdgeList = edgeListToUpdate.map(info => new Edge({...info, _localOnly: false}));
          me._data.edges.update(newEdgeList);
          me._PB.emit('default', EdgeEvents.UPDATED, edgeIdList, newEdgeList);
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          me._PB.emit('default', EdgeEvents.UPDATE_FAILED, edgeIdList, code, msg);
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        me._PB.emit('default', EdgeEvents.UPDATE_FAILED, edgeIdList, code, msg);
        reject({code, msg});
      });
    })
  };

  /**
   * 更新指定附件信息
   *
   * @param {string} propertyId 附件ID
   * @param {string} comment 附件备注信息
   * @param {*} meta 附件元信息
   * @param {string} [nodeId] 相关节点信息，可选
   *
   * @return {Promise}
   */
  updateFilesRemark = (propertyId, comment, meta, nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      me._PB.emit('default', NetworkEvents.FILE_UPDATING, me._viewId, propertyId);
      if (nodeId) {
        me._PB.emit('default', NodeEvents.PROPERTY_UPDATING, nodeId, propertyId);
      }
      this.updateFilesRemarkPromise(propertyId, comment, meta, nodeId).then(() => {
        me._PB.emit('default', NetworkEvents.FILE_UPDATED, me._viewId, propertyId);
        if (nodeId) {
          const node = me._data.nodes.get(nodeId);
          if (node) {
            me._PB.emit('default', NodeEvents.UPDATED, [nodeId], [node]);
          }
          me._PB.emit('default', NodeEvents.PROPERTY_UPDATED, nodeId, propertyId);
        }
        resolve();
      }).catch(errorInfo => {
        me._PB.emit('default', NetworkEvents.FILE_UPDATE_FAILED, me._viewId, propertyId, errorInfo);
        if (nodeId) {
          me._PB.emit('default', NodeEvents.PROPERTY_UPDATE_FAILED, nodeId, propertyId, errorInfo);
        }
        reject(errorInfo);
      });
    });
  };

  /**
   * 更新节点信息
   *
   * @param {{id: string}} nodeInfo
   * @param {boolean} partially 部分更新
   *
   * @return {Promise}
   */
  updateNodeInfo = (nodeInfo, partially = false) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const nodeId = nodeInfo ? nodeInfo.id : undefined;
      if (!nodeId) {
        reject({code: 500, msg: 'Invalid node id.'});
        return;
      }

      // 更新最后访问时间
      // noinspection JSIgnoredPromiseFromCall
      me.accessNode(nodeId);

      const oldNodeInfo = me._data.nodes.get(nodeInfo.id);
      if (!oldNodeInfo) {
        reject({code: 404, msg: `Node ${nodeId} not found.`});
        return;
      }
      me._PB.emit('default', NodeEvents.UPDATING, [nodeInfo]);
      if (nodeInfo['_locked']) {
        nodeInfo['fixed'] = false;
      }
      (partially ? API_UpdateNodeInfoPartially : API_UpdateNodeInfo)(me._viewId, nodeId, nodeInfo).then(response => {
        if (response && response.data && response.data.code === 0) {
          const detailNode = new Node({...oldNodeInfo, ...nodeInfo, ...response.data.data});
          me._data.nodes.update(detailNode);
          me._PB.emit('default', NodeEvents.UPDATED, [nodeId], [detailNode]);
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          me._PB.emit('default', NodeEvents.UPDATE_FAILED, [nodeId], code, msg);
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        me._PB.emit('default', NodeEvents.UPDATE_FAILED, [nodeId], code, msg);
        reject({code, msg});
      });
    });
  };

  /**
   * 批量更新节点信息
   *
   * @param {{id: string, [x]: Number, [y]: Number, [fixed]: Boolean, [meta]: Object}[]} nodeList
   * @param {boolean} refreshUpdate 更新修改时间
   */
  updateNodeInfoListPartially = (nodeList, refreshUpdate = true) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      me._PB.emit('default', NodeEvents.UPDATING, nodeList);
      API_BatchUpdateNodeInfoPartially(me._viewId, nodeList, refreshUpdate).then(response => {
        if (response && response.data && response.data.code === 0) {
          let newNodeList = [];
          nodeList.forEach(nodeInfo => {
            const oldNodeInfo = me._data.nodes.get(nodeInfo.id);
            if (oldNodeInfo) {
              newNodeList.push(new Node({...oldNodeInfo, ...nodeInfo}));
            }
          });
          me._data.nodes.update(newNodeList);
          me._PB.emit('default', NodeEvents.UPDATED, newNodeList.map(n => n.id), newNodeList);
          resolve();
        } else {
          const {code, msg} = me.getErrorInfo({response});
          me._PB.emit('default', NodeEvents.UPDATE_FAILED, nodeList.map(n => n.id), code, msg);
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = me.getErrorInfo(error);
        me._PB.emit('default', NodeEvents.UPDATE_FAILED, nodeList.map(n => n.id), code, msg);
        reject({code, msg});
      });
    });
  };

  /**
   * 更新图谱配置
   *
   * @param {string} key 配置KEY
   * @param {object} config 配置内容
   * @param {boolean} replaceAll 是否替换该KEY下的全部配置
   */
  updateConfig = (key, config, replaceAll = true) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_UpdateViewConfig(me._viewId, key, config, replaceAll).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 更新当前用户的图谱配置
   *
   * @param {string} key 配置KEY
   * @param {object} config 配置内容
   * @param {boolean} replaceAll 是否替换该KEY下的全部配置
   */
  updateUserConfig = (key, config, replaceAll = true) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }

      API_UpdateViewUserConfig(me._viewId, key, config, replaceAll).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * 更新节点与图谱的关联信息，如：位置、优先级、显示比例等
   *
   * @param {{id: string}} nodeInfo
   *
   * @return {Promise}
   */
  updateViewNodeInfo = nodeInfo => {
    let me = this._self;
    return me.updateNodeInfo(nodeInfo, true);
  };

  voteExplorationResultByNode = (subViewId, nodeId, alg, resultId, vote) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const subViewData = me.getSubViewInfo(subViewId);
      if (subViewData && subViewData.nodes.length > 0) {
        // 发送节点联想请求
        API_VoteExplorationResultByResource(me._viewId, nodeId, alg, resultId, vote)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve();
            } else {
              const {code, msg} = me.getErrorInfo({response});
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            reject({code, msg});
          })
      } else if (!subViewData) {
        reject({code: 404, msg: 'SubView not found.'});
      } else {
        reject({code: 412, msg: 'No nodes in subView'});
      }
    });
  };

  voteExplorationResultBySubview = (subViewId, alg, resultId, vote) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      me.assertDataReady();
      const subViewData = me.getSubViewInfo(subViewId);
      if (subViewData && subViewData.nodes.length > 0) {
        // 发送节点联想请求
        API_VoteExplorationResultBySubview(me._viewId, subViewId, subViewData.version, alg, resultId, vote)
          .then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve();
            } else {
              const {code, msg} = me.getErrorInfo({response});
              reject({code, msg});
            }
          })
          .catch(error => {
            const {code, msg} = me.getErrorInfo(error);
            reject({code, msg});
          })
      } else if (!subViewData) {
        reject({code: 404, msg: 'SubView not found.'});
      } else {
        reject({code: 412, msg: 'No nodes in subView'});
      }
    });
  };

  /**
   * 监听事件快捷方式，返回结果仅用于绑定或解绑事件
   *
   * @param self
   * @return {VisNetworkWrapper}
   */
  with = self => VisNetworkWrapper.get(self, this);

  /**
   * @private
   * 添加简单关系请求
   *
   * @param {Object[]} nodes 要添加的节点列表
   * @param {Object[]} edges 要添加的边列表
   * @param {boolean} testOnly 是否仅测试
   * @param {boolean} doAnalyze 是否启动智能分析
   *
   * @return {Promise}
   */
  addRelationPromise = (nodes, edges, testOnly,
                        doAnalyze, viewId=undefined) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_AddRelationGraph(
        viewId || me._viewId, nodes, edges, testOnly, doAnalyze
      ).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  /**
   * @private
   * 添加附件至节点请求
   *
   * @param {string} nodeId 节点ID
   * @param {string} [remark] 备注信息
   * @param {File[]} [files] 文件列表
   * @param {Function} [progressCallback] 进度回调函数
   *
   * @return {Promise}
   */
  addFilesPromise = (nodeId, remark, files, progressCallback) => {
    let me = this._self;
    if (nodeId) {
      if (!_.isString(nodeId)) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Invalid node id ${nodeId}.`}));
      }
      const node = me._data.nodes.get(nodeId);
      if (!node) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Node not found: ${nodeId}`}));
      }
    }
    const formData = new FormData();
    formData.append('viewId', me._viewId);
    formData.append('from', nodeId || '');
    formData.append('to', '');
    formData.append('type', '1');
    formData.append('remark', remark ? remark : '');
    if (_.isArray(files)) {
      // noinspection JSCheckFunctionSignatures
      files.forEach(file => formData.append('files', file));
    }

    return new Promise((resolve, reject) => {
      API_UploadProperty(formData, progressCallback)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response.data.data);
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 断言函数，判断自身数据是否有效
   */
  assertDataReady = () => {
    if (!this._data || !this._viewId) {
      throw new Error('Network data is not ready...');
    }
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param error
   *
   * @return {{code: number, msg: string}}
   */
  getErrorInfo = (error) => {
    let msg = '处理您的信息流时遇到异常，可能是网络原因，您可稍后再试一下。';
    let code = 500;
    if (error && error.response) {
      if (error.response.data) {
        code = error.response.data.code || error.response.data.status;
        msg = error.response.data.msg || msg;
      } else {
        code = error.response.status;
      }
    }
    return {code, msg};
  };

  /**
   * @private
   * 获取指定节点的搜索结果
   *
   * @param {string} [nodeId] 节点ID，可选
   * @param {string} [key] 字段名称，可选
   *
   * @return {[ExactMatchResult]|ExactMatchResult|undefined}
   */
  getExactMatchResult = (nodeId, key) => {
    // 不指定节点ID时返回所有数据
    if (!nodeId) return this._exactMatchResult.get();

    if (!this._data.nodes.get(nodeId)) return undefined;

    const exactMatchResult = this._exactMatchResult.get(nodeId);

    return exactMatchResult ? (key ? exactMatchResult[key] : exactMatchResult) : undefined;
  };

  /**
   * @private
   * 获取指定子图的搜索结果
   *
   * @param {string} [subViewId] 子图ID，可选
   * @param {string} [key] 字段名称，可选
   *
   * @return {[MatchResult]|MatchResult|undefined}
   */
  getMatchResultForSubView = (subViewId, key) => {
    // 不指定子图ID时返回所有数据
    if (!subViewId) return this._matchResultForSubView.get();

    if (!this._matchResultForSubView.get(subViewId)) return undefined;

    const exactMatchResult = this._matchResultForSubView.get(subViewId);

    return exactMatchResult ? (key ? exactMatchResult[key] : exactMatchResult) : undefined;
  };

  /**
   * @private
   * 获取指定节点的扩展结果
   *
   * @param {string} [nodeId] 节点ID，可选
   * @param {string} [key] 字段名称，可选
   *
   * @return {[RelatedClueResult]|RelatedClueResult|undefined}
   */
  getRelatedClueResult = (nodeId, key) => {
    // 不指定节点ID时返回所有数据
    if (!nodeId) return this._relatedClueResult.get();

    if (!this._data.nodes.get(nodeId)) return undefined;

    const relatedClueResult = this._relatedClueResult.get(nodeId);

    return relatedClueResult ? (key ? relatedClueResult[key] : relatedClueResult) : undefined;
  };

  /**
   * @private
   * 获取指定节点的联想结果
   *
   * @param {string} [nodeId] 节点ID，可选
   * @param {string} [key] 字段名称，可选
   *
   * @return {[RelatedResourceResult]|RelatedResourceResult|undefined}
   */
  getRelatedResourceResult = (nodeId, key) => {
    // 不指定节点ID时返回所有数据
    if (!nodeId) return this._relatedResourceResult.get();

    if (!this._data.nodes.get(nodeId)) return undefined;

    const relatedResourceResult = this._relatedResourceResult.get(nodeId);

    return relatedResourceResult ? (key ? relatedResourceResult[key] : relatedResourceResult) : undefined;
  };

  /**
   * @private
   * 初始化搜索结果
   *
   * @param {string} nodeId 节点ID
   */
  initExactMatchResult = (nodeId) => {
    this._exactMatchResult.remove(nodeId);
    this._exactMatchResult.add({
      ...getEmptyExactMatchResult(),
      nodeId,
      id: nodeId,
    });
  };

  /**
   * @private
   * 初始化子图搜索结果
   *
   * @param {string} subViewId 节点ID
   */
  initMatchResultForSubView = (subViewId) => {
    this._matchResultForSubView.remove(subViewId);
    this._matchResultForSubView.add({
      ...getEmptyMatchResult(),
      id: subViewId,
    });
  };

  /**
   * @private
   * 初始化扩展结果
   *
   * @param {string} nodeId 节点ID
   */
  initRelatedClueResult = (nodeId) => {
    this._relatedClueResult.remove(nodeId);
    this._relatedClueResult.add({
      ...getEmptyRelatedClueResult(),
      nodeId,
      id: nodeId,
    });
  };

  /**
   * @private
   * 初始化联想结果
   *
   * @param {string} nodeId 节点ID
   */
  initRelatedResourceResult = (nodeId) => {
    this._relatedResourceResult.remove(nodeId);
    this._relatedResourceResult.add({
      ...getEmptyRelatedResourceResult(),
      nodeId,
      id: nodeId,
    });
  };

  /**
   * @private
   * 内部加载孤立节点的引力边
   *
   * @param {string[]} [nodeIds] 指定要遍历的节点列表
   */
  internalLoadGravityEdgesForStandaloneNodes = nodeIds => {
    let me = this._self;
    try {
      me.assertDataReady();
      let directLinkedNodeMap = {};
      nodeIds = nodeIds || me._data.nodes.getIds({filter: n => !n.fixed});
      let allNodeIdMap = me._data.nodes.getIds().reduce((r, nodeId) => {
        r[nodeId] = true;
        return r;
      }, {});
      me._data.edges.get().forEach(edge => {
        if (allNodeIdMap[edge.from] && allNodeIdMap[edge.to]) {
          directLinkedNodeMap[edge.from] || (directLinkedNodeMap[edge.from] = []);
          directLinkedNodeMap[edge.from] && directLinkedNodeMap[edge.from].push(edge.to);
          directLinkedNodeMap[edge.to] || (directLinkedNodeMap[edge.to] = []);
          directLinkedNodeMap[edge.to] && directLinkedNodeMap[edge.to].push(edge.from);
        }
      });
      let standaloneNodeIds = nodeIds.reduce((idList, currentNodeId) => {
        // 与currentNodeId指定的节点直接相连的所有节点ID列表
        let directLinkedNodeIds = directLinkedNodeMap[currentNodeId];
        if (!directLinkedNodeIds) {
          // 该节点为孤立节点
          idList.push(currentNodeId);
        } else if (directLinkedNodeIds.findIndex(nodeId => directLinkedNodeMap[nodeId] && directLinkedNodeMap[nodeId].length > 1) < 0) {
          // 该列表中不存在并非仅与currentNodeId对应的节点相连的节点
          idList.push(currentNodeId);
        }
        return idList;
      }, []);
      let standaloneNodes = me._data.nodes.get(standaloneNodeIds);
      let gravityEdges = [];
      standaloneNodes.forEach(node => {
        if (node.aiLinkedToIdList) {
          node.aiLinkedToIdList.forEach(id => {
            if (me._data.nodes.get(id)) {
              gravityEdges.push(new Edge({
                id: (node.id > id ? `gravity|${id}|->|${node.id}|` : `gravity|${node.id}|->|${id}|`),
                from: node.id,
                to: id,
                viewId: me._viewId,
                userConfirmed: false,
                size: 0,
                visible: false,
                status: 0,
                _selectable: false,
              }));
            }
          })
        }
      });
      if (gravityEdges.length > 0) {
        me._data.edges.update(gravityEdges);
      }
    } catch (e) {
      // 忽略
    }
  };

  /**
   * @private
   * 搜索结果数据返回后的处理函数
   *
   * @param nodeId 被搜索的节点ID
   * @param {[Object]} nodes 节点信息列表
   * @param {[Object]} edges 边信息列表
   */
  onExactMatchResultReceived = (nodeId, nodes, edges) => {
    this.setExactMatchResult(nodeId, {
      status: NodeExactMatchingStatus.SUCCESS,
      errorCode: 0,
      errorMsg: '',
    });
    let exactMatchResult = this.getExactMatchResult(nodeId);
    if (!exactMatchResult) return;
    const typeList = Object.values(MatchCategories)
      .filter(c => c.enabled)
      .map(c => c.key);
    /**
     * @type {DataSet}
     */
    const nodeSet = new DataSet();
    nodes.forEach(node => {
      if (node.meta && node.meta['qt']) {
        const type = node.meta['qt'];
        if (!typeList.includes(type)) return;
        const nodeInfo = {
          ...node.meta['object'],
          fnameHtml: node.fname,
          keyStcHtml: node.keyStc,
          meta: {
            ...(node.meta['object']['meta'] || {}),
            belong: node.meta['belong'].filter(info => info.id !== node.meta['object'].id),
          },
        };
        const existedNode = nodeSet.get(nodeInfo.id);
        const nodeObj = new Node(nodeInfo);
        if (existedNode) {
          existedNode.meta.belong = existedNode.meta.belong.concat(nodeInfo.meta.belong);
          nodeSet.update(existedNode);
        } else {
          nodeSet.add(nodeObj);
        }
        exactMatchResult.classifiedNodes[type].push(nodeObj);
      }
    });
    exactMatchResult.nodes = nodeSet.get();
    edges.forEach(edge => exactMatchResult.edges.push(new Edge(edge)));
    this.setExactMatchResult(nodeId, exactMatchResult);
  };

  /**
   * @private
   * 搜索结果数据返回后的处理函数
   *
   * @param subViewId 被搜索的子图ID
   * @param {[Object]} nodes 节点信息列表
   * @param {[Object]} edges 边信息列表
   */
  onMatchResultForSubViewReceived = (subViewId, nodes, edges) => {
    this.setMatchResultForSubView(subViewId, {
      status: NodeExactMatchingStatus.SUCCESS,
      errorCode: 0,
      errorMsg: '',
    });
    let matchResultForSubView = this.getMatchResultForSubView(subViewId);
    if (!matchResultForSubView) return;
    const typeList = Object.values(MatchCategories)
      .filter(c => c.enabled)
      .map(c => c.key);
    /**
     * @type {DataSet}
     */
    const nodeSet = new DataSet();
    nodes.forEach(node => {
      if (node.meta && node.meta['qt']) {
        const type = node.meta['qt'];
        if (!typeList.includes(type)) return;
        const nodeInfo = {
          ...node.meta['object'],
          fnameHtml: node.fname,
          keyStcHtml: node.keyStc,
          meta: {
            ...(node.meta['object']['meta'] || {}),
            belong: node.meta['belong'].filter(info => info.id !== node.meta['object'].id),
          },
        };
        const existedNode = nodeSet.get(nodeInfo.id);
        const nodeObj = new Node(nodeInfo);
        if (existedNode) {
          existedNode.meta.belong = existedNode.meta.belong.concat(nodeInfo.meta.belong);
          nodeSet.update(existedNode);
        } else {
          nodeSet.add(nodeObj);
        }
        matchResultForSubView.classifiedNodes[type].push(nodeObj);
      }
    });
    matchResultForSubView.nodes = nodeSet.get();
    edges.forEach(edge => matchResultForSubView.edges.push(new Edge(edge)));
    this.setMatchResultForSubView(subViewId, matchResultForSubView);
  };

  /**
   * @private
   * 扩展结果数据返回后的处理函数
   *
   * @param nodeId 被扩展的节点ID
   * @param {[Object]} nodes 节点信息列表
   * @param {[Object]} edges 边信息列表
   */
  onRelatedClueResultReceived = (nodeId, nodes, edges) => {
    this.setRelatedClueResult(nodeId, {
      status: NodeExpandingStatus.SUCCESS,
      errorCode: 0,
      errorMsg: '',
    });
    let relatedClueResult = this.getRelatedClueResult(nodeId);
    if (!relatedClueResult) return;
    let me = this._self;
    let connectedNodeIds = me.getConnectedNodeIdsByNodeId(nodeId);
    nodes = nodes.filter(node => (!me._data.nodes.get(node.id)) || me._data.nodes.get(node.id).status === 0);
    edges = edges.filter(edge =>
      (!connectedNodeIds.includes(edge.from)) && (!connectedNodeIds.includes(edge.to)));
    nodes.forEach(node => {
      node.meta = node.meta || {};
      node.meta.relatedTo = nodeId;
      node.meta.relByClue = 1;
      node.aiRelatedTo = nodeId;
      relatedClueResult.newNodesMap[node.id] = new Node(node);
      relatedClueResult.newNodes.push(relatedClueResult.newNodesMap[node.id]);
    });

    let newNodeByType = {};
    let result = [];
    edges.forEach(edge => {
      let node = relatedClueResult.newNodesMap[edge.from];
      if (!node) node = me._data.nodes.get(edge.from);
      let categoryKey = `c_${node[TYPE_FIELD_NAME]}`;
      newNodeByType[categoryKey] = newNodeByType[categoryKey] || [];
      newNodeByType[categoryKey].push(node);
      relatedClueResult.newEdges.push(new Edge(edge));
    });
    let edgesLeft = edges.length;
    let currentNodeTypePos = 0;
    const allNodeTypeListFilterFn = (key, idx) => {
      let allNodeTypeList = Object.keys(newNodeByType);
      return (idx >= currentNodeTypePos && idx < (currentNodeTypePos + relatedClueResultPerStep)) ||
        ((idx + allNodeTypeList.length) >= currentNodeTypePos &&
          (idx + allNodeTypeList.length) < (currentNodeTypePos + relatedClueResultPerStep));
    };
    let amountLeft = [0];
    const nodeTypeListForEachFn = nodeTypeKey => {
      let expectedAmount = Math.ceil(newNodeByType[nodeTypeKey].length / edgesLeft * relatedClueResultPerStep) - 1;
      expectedAmount = Math.min(expectedAmount, amountLeft[0]);
      amountLeft[0] -= expectedAmount;
      expectedAmount++;
      result.push.apply(result, newNodeByType[nodeTypeKey].splice(0, expectedAmount));
      if (newNodeByType[nodeTypeKey].length <= 0) {
        delete newNodeByType[nodeTypeKey];
      }
    };
    let allNodeTypeList = Object.keys(newNodeByType);
    while (edgesLeft > relatedClueResultPerStep && allNodeTypeList.length > 1) {
      let nodeTypeList = (allNodeTypeList.length > relatedClueResultPerStep ?
        allNodeTypeList.filter(allNodeTypeListFilterFn) : allNodeTypeList);
      currentNodeTypePos = (allNodeTypeList.length > relatedClueResultPerStep ?
        ((currentNodeTypePos + relatedClueResultPerStep) % allNodeTypeList.length) : 0);
      // 每类数据至少1条，剩余按比例分配，此处通过数组将数值保留
      amountLeft[0] = relatedClueResultPerStep - nodeTypeList.length;
      nodeTypeList.forEach(nodeTypeListForEachFn);
      allNodeTypeList = Object.keys(newNodeByType);
      edgesLeft -= relatedClueResultPerStep;
    }
    if (edgesLeft > 0) {
      Object.keys(newNodeByType).forEach(k => result.push.apply(result, newNodeByType[k]))
    }
    relatedClueResult.allNodes = result;
    this.setRelatedClueResult(nodeId, relatedClueResult);

    // 自动展示扩展结果
    this.replaceWithNextRelatedClueResult(nodeId);
  };

  /**
   * @private
   * 联想结果数据返回后的处理函数
   *
   * @param nodeId 被联想的节点ID
   * @param {[Object]} nodes 节点信息列表
   * @param {[Object]} edges 边信息列表
   */
  onRelatedResourceResultReceived = (nodeId, nodes, edges) => {
    this.setRelatedResourceResult(nodeId, {
      status: NodeGrowingStatus.SUCCESS,
      errorCode: 0,
      errorMsg: '',
    });
    let relatedResourceResult = this.getRelatedResourceResult(nodeId);
    if (!relatedResourceResult) return;
    let me = this._self;
    let connectedNodeIds = me.getConnectedNodeIdsByNodeId(nodeId);
    nodes = nodes.filter(node => (!me._data.nodes.get(node.id)) || me._data.nodes.get(node.id).status === 0);
    edges = edges.filter(edge =>
      (!connectedNodeIds.includes(edge.from)) && (!connectedNodeIds.includes(edge.to)));
    nodes.forEach(node => {
      relatedResourceResult.newNodesMap[node.id] = new Node(node);
      relatedResourceResult.newNodes.push(relatedResourceResult.newNodesMap[node.id]);
    });

    let newNodeByType = {};
    let result = [];
    edges.forEach(edge => {
      let node = relatedResourceResult.newNodesMap[edge.to];
      if (!node) node = me._data.nodes.get(edge.to);
      let categoryKey = `c_${node[TYPE_FIELD_NAME]}`;
      newNodeByType[categoryKey] = newNodeByType[categoryKey] || [];
      newNodeByType[categoryKey].push(node);
      relatedResourceResult.newEdges.push(new Edge(edge));
    });
    let edgesLeft = edges.length;
    let currentNodeTypePos = 0;
    const allNodeTypeListFilterFn = (key, idx) => {
      let allNodeTypeList = Object.keys(newNodeByType);
      return (idx >= currentNodeTypePos && idx < (currentNodeTypePos + relatedResourceResultPerStep)) ||
        ((idx + allNodeTypeList.length) >= currentNodeTypePos &&
          (idx + allNodeTypeList.length) < (currentNodeTypePos + relatedResourceResultPerStep));
    };
    let amountLeft = [0];
    const nodeTypeListForEachFn = nodeTypeKey => {
      let expectedAmount = Math.ceil(newNodeByType[nodeTypeKey].length / edgesLeft *
        relatedResourceResultPerStep) - 1;
      expectedAmount = Math.min(expectedAmount, amountLeft[0]);
      amountLeft[0] -= expectedAmount;
      expectedAmount++;
      result.push.apply(result, newNodeByType[nodeTypeKey].splice(0, expectedAmount));
      if (newNodeByType[nodeTypeKey].length <= 0) {
        delete newNodeByType[nodeTypeKey];
      }
    };
    let allNodeTypeList = Object.keys(newNodeByType);
    while (edgesLeft > relatedResourceResultPerStep && allNodeTypeList.length > 1) {
      let nodeTypeList = (allNodeTypeList.length > relatedResourceResultPerStep ?
        allNodeTypeList.filter(allNodeTypeListFilterFn) : allNodeTypeList);
      currentNodeTypePos = (allNodeTypeList.length > relatedResourceResultPerStep ?
        ((currentNodeTypePos + relatedResourceResultPerStep) % allNodeTypeList.length) : 0);
      // 每类数据至少1条，剩余按比例分配，此处通过数组将数值保留
      amountLeft[0] = relatedResourceResultPerStep - nodeTypeList.length;
      nodeTypeList.forEach(nodeTypeListForEachFn);
      allNodeTypeList = Object.keys(newNodeByType);
      edgesLeft -= relatedResourceResultPerStep;
    }
    if (edgesLeft > 0) {
      Object.keys(newNodeByType).forEach(k => result.push.apply(result, newNodeByType[k]))
    }
    relatedResourceResult.allNodes = result;
    this.setRelatedResourceResult(nodeId, relatedResourceResult);

    // 自动展示扩展结果
    this.replaceWithNextRelatedResourceResult(nodeId);
  };

  /**
   * @private
   * 将后台返回的视图配置信息转换成界面展示结构
   */
  parseViewOptions = () => {
    const defaultOptions = getDefaultViewOptions();
    if (this._viewInfo) {
      const optionStr = this._viewInfo['viewOptions'];
      try {
        const options = {
          ...this.stringifyViewOptions(defaultOptions),
          ...JSON.parse(optionStr),
        };
        let area = JSON.parse(options.area);
        let areaObj;
        let areaItems;
        if (area.prov.length > 0) {
          areaItems = area.prov[0].split('-');
          areaObj = {
            province: areaItems[0],
            city: areaItems[1],
          };
        } else if (area.city.length > 0) {
          areaItems = area.city[0].split('-');
          areaObj = {
            province: areaItems[0],
            city: areaItems[1],
          };
        }
        this._parsedViewOptions = {
          time: options.time,
          types: options.types.split(','),
          areas: areaObj ? [areaObj] : [],
          keywords: options.keyword.split(' '),
        };
      } catch (e) {
        console.warn(e);
        this._parsedViewOptions = defaultOptions;
      }
    } else {
      this._parsedViewOptions = undefined;
    }
  };

  /**
   * @private
   * 注册部分事件监听函数，处理节点线索联想结果相关业务
   */
  registerListeners4RelatedClueResult = () => {
    const beforeRelationOperationHandler = (me, nodeId, relatedNodeId, event) => {
      let relatedResult = me.getRelatedClueResult(nodeId);

      if (!relatedResult) return;

      // 查询对应边是否在结果集中
      const edgeIdx = relatedResult.newEdges.findIndex(edge =>
        (edge.from === relatedNodeId && edge.to === relatedResult.nodeId) ||
        (edge.from === relatedResult.nodeId && edge.to === relatedNodeId)
      );
      if (edgeIdx < 0) return;

      if (relatedResult && relatedResult.processingNodeIds.includes(relatedNodeId)) return;
      this._PB.emit('default', event, nodeId, relatedNodeId);
      if (relatedResult) {
        relatedResult.processingNodeIds.push(relatedNodeId);
        me.setRelatedClueResult(nodeId, relatedResult);
      }
    };

    const onRelationOperationFailedHandler = (me, nodeId, relatedNodeId, event, errorInfo) => {
      let relatedResult = this.getRelatedClueResult(nodeId);

      if (!relatedResult) return;

      // 查询对应边是否在结果集中
      const edgeIdx = relatedResult.newEdges.findIndex(edge =>
        (edge.from === relatedNodeId && edge.to === relatedResult.nodeId) ||
        (edge.from === relatedResult.nodeId && edge.to === relatedNodeId)
      );
      if (edgeIdx < 0) return;

      if (relatedResult) {
        relatedResult.processingNodeIds =
          relatedResult.processingNodeIds.filter(nodeId => nodeId !== relatedNodeId);
        this.setRelatedClueResult(nodeId, relatedResult);
      }
      me._PB.emit('default', event, nodeId, relatedNodeId, errorInfo);
    };

    this.with(this).subscribe(
      EdgeEvents.ADDING,
      /**
       * @private
       * @function
       * @name beforeRelationSave4RelatedClueResult
       * @description 在关联关系保存前触发，处理节点线索联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       */
      (edgeSimpleInfoList) => {
        let me = this._self;
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && fromNode.status === 0) {
            // 对于toNode来说，此操作可能为保存线索联想结果操作
            beforeRelationOperationHandler(me, toNodeId, fromNodeId, EdgeEvents.SAVING_RELATED_CLUE_RELATION);
          }
          if (toNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && fromNode.status === 0) {
            // 对于fromNode来说，此操作可能为保存线索联想结果操作
            beforeRelationOperationHandler(me, fromNodeId, toNodeId, EdgeEvents.SAVING_RELATED_CLUE_RELATION);
          }
        });
      }
    ).subscribe(
      EdgeEvents.ADDED,
      /**
       * @private
       * @function
       * @name afterRelationSaved4RelatedClueResult
       * @description 当关联关系保存后触发，处理节点线索联想结果相关业务
       *
       * @param {number} type 添加类型
       * @param {[string]} edgeIds 关联关系ID列表
       * @param {[Edge]} edgeInfoList 已添加的关系信息列表
       */
      (type, edgeIds, edgeInfoList) => {
        let me = this._self;
        // 仅处理实线关系添加事件
        if (type & ADD_TO_VIEW) {
          let relatedResults = me.getRelatedClueResult();
          relatedResults.forEach(relatedResult => {
            edgeInfoList.forEach(edgeInfo => {
              const fromNodeId = edgeInfo.from;
              const toNodeId = edgeInfo.to;
              let relatedClueNodeId;
              if (relatedResult.nodeId === fromNodeId) {
                relatedClueNodeId = toNodeId;
              } else if (relatedResult.nodeId === toNodeId) {
                relatedClueNodeId = fromNodeId;
              } else {
                return;
              }
              if (relatedResult.status === NodeExpandingStatus.SUCCESS) {

                // 查询对应边是否在结果集中
                const edgeIdx = relatedResult.newEdges.findIndex(edge =>
                  (edge.from === relatedClueNodeId && edge.to === relatedResult.nodeId) ||
                  (edge.from === relatedResult.nodeId && edge.to === relatedClueNodeId)
                );
                if (edgeIdx >= 0) {
                  relatedResult.newEdges[edgeIdx].id = edgeInfo.id;
                  relatedResult.currentSolidNodeIds.push(relatedClueNodeId);
                  relatedResult.currentSolidEdgeIds.push(edgeInfo.id);
                  relatedResult.processingNodeIds =
                    relatedResult.processingNodeIds.filter(nodeId => nodeId !== relatedClueNodeId);
                  me.setRelatedClueResult(relatedResult.nodeId, relatedResult);
                  me._PB.emit('default', EdgeEvents.RELATED_CLUE_RELATION_SAVED, relatedResult.nodeId,
                    relatedClueNodeId, edgeInfo.id);
                }
              }
            });
          });
        }
      }
    ).subscribe(
      EdgeEvents.ADD_FAILED,
      /**
       * @private
       * @function
       * @name onRelationFailedToSave4RelatedClueResult
       * @description 当关联关系保存失败时触发，处理节点线索联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       * @param {{code: number, msg: string}} errorInfo 错误信息
       */
      (edgeSimpleInfoList, errorInfo) => {
        let me = this._self;
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && fromNode.status === 0) {
            onRelationOperationFailedHandler(me, toNodeId, fromNodeId, EdgeEvents.SAVE_RELATED_CLUE_RELATION_FAILED);
          }
          if (toNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && fromNode.status === 0) {
            onRelationOperationFailedHandler(me, fromNodeId, toNodeId, EdgeEvents.SAVE_RELATED_CLUE_RELATION_FAILED);
          }
        });
      }
    ).subscribe(
      EdgeEvents.REMOVING,
      /**
       * @private
       * @function
       * @name beforeRelationRemove4RelatedClueResult
       * @description 在关联关系删除前触发，处理节点线索联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       */
      (edgeSimpleInfoList) => {
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          let me = this._self;
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && fromNode.status === 0) {
            // 对于toNode来说，此操作可能为保存线索联想结果操作
            beforeRelationOperationHandler(me, toNodeId, fromNodeId, EdgeEvents.REMOVING_RELATED_CLUE_RELATION);
          }
          if (toNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && toNode.status === 0) {
            // 对于fromNode来说，此操作可能为保存线索联想结果操作
            beforeRelationOperationHandler(me, fromNodeId, toNodeId, EdgeEvents.REMOVING_RELATED_CLUE_RELATION);
          }
        });
      }
    ).subscribe(
      EdgeEvents.REMOVED,
      /**
       * @private
       * @function
       * @name afterRelationRemove4RelatedClueResult
       * @description 当关联关系删除后触发，处理节点线索联想结果相关业务
       *
       * @param {number} type 删除类型
       * @param {[Edge]} edgeInfoList 已删除的边信息列表
       */
      (type, edgeInfoList) => {
        let me = this._self;
        let relatedResults = me.getRelatedClueResult();
        // 仅处理实线关系删除事件
        if (type & REMOVE_FROM_VIEW) {
          edgeInfoList.forEach(edgeInfo => {
            let fromNodeId = edgeInfo.from;
            let toNodeId = edgeInfo.to;
            relatedResults.forEach(relatedResult => {
              let relatedClueNodeId;
              if (relatedResult.nodeId === fromNodeId) {
                relatedClueNodeId = toNodeId;
              } else if (relatedResult.nodeId === toNodeId) {
                relatedClueNodeId = fromNodeId;
              } else {
                return;
              }

              // 查询对应边是否在结果集中
              const edgeIdx = relatedResult.newEdges.findIndex(edge =>
                (edge.from === relatedClueNodeId && edge.to === relatedResult.nodeId) ||
                (edge.from === relatedResult.nodeId && edge.to === relatedClueNodeId)
              );
              if (edgeIdx < 0) return;

              // 查询节点所在位置
              const nodeIdx = relatedResult.allNodes.findIndex(node => node.id === relatedClueNodeId);
              if (nodeIdx < 0) return;

              let removeFromResult = false;
              if (type & REMOVE_FROM_GRAPH) {
                // 关系被直接删除，检查此关系是否在当前展示的批次中，如果在则需要从结果集中删除
                if (relatedResult.currentStartPos <= nodeIdx &&
                  (relatedResult.currentStartPos + relatedResult.currentlyShowingAmount) > nodeIdx) {

                  removeFromResult = true;
                }
              }

              relatedResult.currentSolidNodeIds =
                relatedResult.currentSolidNodeIds.filter(nodeId => relatedClueNodeId !== nodeId);
              relatedResult.currentSolidEdgeIds =
                relatedResult.currentSolidEdgeIds.filter(edgeId => edgeInfo.id !== edgeId);
              relatedResult.processingNodeIds =
                relatedResult.processingNodeIds.filter(nodeId => nodeId !== relatedClueNodeId);

              if (removeFromResult) {
                // nextStartPos可能为0
                relatedResult.nextStartPos = relatedResult.nextStartPos > relatedResult.currentStartPos ?
                  relatedResult.nextStartPos-- : relatedResult.nextStartPos;
                if (relatedResult.nextStartPos <= relatedResult.currentStartPos) {
                  relatedResult.currentStartPos = -1;
                }
                relatedResult.currentlyShowingAmount--;
                relatedResult.currentNodes = relatedResult.currentNodes
                  .filter(node => node.id !== relatedClueNodeId);
                relatedResult.currentEdges = relatedResult.currentEdges.filter(edge => edge.id !== edgeInfo.id);
              }

              this.setRelatedClueResult(relatedResult.nodeId, relatedResult);
              me._PB.emit('default', EdgeEvents.RELATED_CLUE_RELATION_REMOVED, relatedResult.nodeId,
                relatedClueNodeId, edgeInfo);
            });
          });
        }
      }
    ).subscribe(
      EdgeEvents.REMOVE_FAILED,
      /**
       * @private
       * @function
       * @name onRelationFailedToRemove4RelatedClueResult
       * @description 当关联关系删除失败时触发，处理节点线索联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       * @param {{code: number, msg: string}} errorInfo 错误信息
       */
      (edgeSimpleInfoList, errorInfo) => {
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          let me = this._self;
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && fromNode.status === 0) {
            onRelationOperationFailedHandler(me, toNodeId, fromNodeId, EdgeEvents.REMOVE_RELATED_CLUE_RELATION_FAILED);
          }
          if (toNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && toNode.status === 0) {
            onRelationOperationFailedHandler(me, fromNodeId, toNodeId, EdgeEvents.REMOVE_RELATED_CLUE_RELATION_FAILED);
          }
        });
      }
    ).subscribe(
      NodeEvents.REMOVING,
      /**
       * @private
       * @function
       * @name beforeNodeRemove4RelatedClueResult
       * @description 在节点删除前触发，处理节点线索联想结果相关业务
       *
       * @param {number} type 删除类型
       * @param {[string]} nodeIdList 待删除节点ID列表
       */
      (type, nodeIdList) => {
        nodeIdList.forEach(nodeId => {
          let me = this._self;
          let relatedResults = me.getRelatedClueResult();
          relatedResults.forEach(relatedResult => {
            // 查询节点所在位置
            const nodeIdx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
            if (nodeIdx < 0) return;

            beforeRelationOperationHandler(me, relatedResult.nodeId, nodeId,
              EdgeEvents.REMOVING_RELATED_CLUE_RELATION);
          });
        });
      }
    ).subscribe(
      NodeEvents.REMOVED,
      /**
       * @private
       * @function
       * @name afterNodeRemove4RelatedClueResult
       * @description 当节点删除后触发，处理节点线索联想结果相关业务
       *
       * @param {number} type 删除类型
       * @param {[Node]} nodeInfoList 已删除的节点列表
       */
      (type, nodeInfoList) => {
        let me = this._self;
        let relatedResults = me.getRelatedClueResult();

        // 仅处理实线关系删除事件
        if (type & REMOVE_FROM_VIEW) {
          nodeInfoList.forEach(nodeInfo => {
            let nodeId = nodeInfo.id;
            relatedResults.forEach(relatedResult => {
              if (relatedResult.nodeId === nodeId) {
                // 被扩展的节点被删除，清空扩展结果数据
                let connectedNodes =
                  me._relatedClueResult.get(nodeId) ? me._relatedClueResult.get(nodeId).currentNodes : [];
                me.clearRelatedClueResult(nodeId, connectedNodes);
                me.setRelatedClueResult(nodeId, null);
              } else if (relatedResult.status === NodeExpandingStatus.SUCCESS) {
                // 查询节点所在位置
                const idx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
                // 删除的节点不在结果集中
                if (idx === -1) {
                  // 删除的节点不在结果集中
                } else if (idx >= relatedResult.currentStartPos &&
                  idx < (relatedResult.currentStartPos + relatedResult.currentlyShowingAmount) &&
                  (type & REMOVE_FROM_GRAPH)) {

                  // 将数据从结果中删除，并修改nextStartPos及currentStartPos值
                  _.remove(relatedResult.newNodes, node => node.id === nodeId);
                  let removed = _.remove(relatedResult.allNodes, node => node.id === nodeId);
                  _.remove(relatedResult.currentNodes, node => node.id === nodeId);
                  relatedResult.currentlyShowingAmount -= removed.length;
                  if (relatedResult.nextStartPos > 0) {
                    relatedResult.nextStartPos -= removed.length;
                  }
                  if (relatedResult.nextStartPos <= relatedResult.currentStartPos) {
                    relatedResult.currentStartPos = -1;
                  }
                  let removedIds = removed.map(node => node.id);
                  relatedResult.currentSolidNodeIds =
                    relatedResult.currentSolidNodeIds.filter(nodeId => !removedIds.includes(nodeId));

                  let removedEdge = _.remove(relatedResult.newEdges,
                    edge => (edge.from === nodeId || edge.to === nodeId));
                  _.remove(relatedResult.currentEdges,
                    edge => (edge.from === nodeId || edge.to === nodeId));
                  if (removedEdge && removedEdge.length > 0) {
                    const removedEdgeIds = removedEdge.filter(edge => !!edge.id).map(edge => edge.id);
                    relatedResult.currentSolidEdgeIds =
                      relatedResult.currentSolidEdgeIds.filter(edgeId => !removedEdgeIds.includes(edgeId));
                  }
                  me.setRelatedClueResult(relatedResult.nodeId, relatedResult);
                  me._PB.emit('default', EdgeEvents.RELATED_CLUE_RELATION_REMOVED, relatedResult.nodeId,
                    nodeId, removedEdge && removedEdge.length > 0 ? removedEdge[0] : undefined);
                } else {
                  // 删除的节点在结果集中，但对扩展结果无影响，将节点状态改为未固化，并从已固化节点、边ID中删除
                  relatedResult.allNodes[idx].status = 0;
                  if (-1 === relatedResult.newNodes.findIndex(node => node.id === nodeId)) {
                    relatedResult.newNodes.push(relatedResult.allNodes[idx]);
                  }

                  relatedResult.currentSolidNodeIds =
                    relatedResult.currentSolidNodeIds.filter(nodeId => nodeInfo.id !== nodeId);
                  let relatedEdges = relatedResult.newEdges
                    .filter(edge => (edge.from === nodeId || edge.to === nodeId));
                  let relatedEdgeIds = relatedEdges.map(edge => edge.id);
                  if (relatedEdgeIds && relatedEdgeIds.length > 0) {
                    relatedResult.currentSolidEdgeIds =
                      relatedResult.currentSolidEdgeIds.filter(edgeId => !relatedEdgeIds.includes(edgeId));
                  }
                  me.setRelatedClueResult(relatedResult.nodeId, relatedResult);
                  if (relatedEdgeIds && relatedEdgeIds.length > 0) {
                    me._PB.emit('default', EdgeEvents.RELATED_CLUE_RELATION_REMOVED, relatedResult.nodeId,
                      nodeId, relatedEdges[0]);
                  }
                }
              }
            });
          });
        }
      }
    ).subscribe(
      NodeEvents.REMOVE_FAILED,
      /**
       * @private
       * @function
       * @name onNodeFailedToRemove4RelatedClueResult
       * @description 当节点删除失败时触发，处理节点线索联想结果相关业务
       *
       * @param {[string]} nodeIdList 待删除节点ID列表
       * @param {{code: number, msg: string}} errorInfo 错误信息
       */
      (nodeIdList, errorInfo) => {
        let me = this._self;
        let relatedResults = me.getRelatedClueResult();
        relatedResults.forEach(relatedResult => {
          nodeIdList.forEach(nodeId => {
            // 查询节点所在位置
            const nodeIdx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
            if (nodeIdx < 0) return;

            onRelationOperationFailedHandler(me, relatedResult.nodeId, nodeId,
              EdgeEvents.REMOVE_RELATED_CLUE_RELATION_FAILED);
          });
        });
      }
    ).subscribe(
      NodeEvents.ID_REPLACED,
      /**
       * @private
       * @function
       * @name afterNodeReplaced4RelatedClueResult
       * @description 当节点替换后触发，处理节点线索联想结果相关业务
       *
       * @param {Object} replacement 替换列表
       */
      (replacement) => {
        let me = this._self;
        let relatedResults = me.getRelatedClueResult();

        Object.keys(replacement).forEach(fromId => {
          let nodeId = fromId;
          relatedResults.forEach(relatedResult => {
            if (relatedResult.nodeId === nodeId) {
              // 被扩展的节点被替换，清空扩展结果数据，应该不存在此情况
              let connectedNodes =
                me._relatedClueResult.get(nodeId) ? me._relatedClueResult.get(nodeId).currentNodes : [];
              me.clearRelatedClueResult(nodeId, connectedNodes);
              me.setRelatedClueResult(nodeId, null);
            } else if (relatedResult.status === NodeExpandingStatus.SUCCESS) {
              // 查询节点所在位置
              const idx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
              // 删除的节点不在结果集中
              if (idx === -1) {
                // 删除的节点不在结果集中
              } else {
                // 替换ID
                relatedResult.newNodes.forEach(node => {
                  if (node.id === nodeId) node.id = replacement[nodeId];
                });
                relatedResult.currentNodes.forEach(node => {
                  if (node.id === nodeId) node.id = replacement[nodeId];
                });

                let index = _.findIndex(relatedResult.currentSolidNodeIds, id => id === nodeId);
                if (index >= 0) relatedResult.currentSolidNodeIds.splice(index, 1, replacement[nodeId]);

                let edgeReplacement = {};
                relatedResult.newEdges = relatedResult.newEdges.map(edge => {
                  if (edge.from === nodeId) {
                    edgeReplacement[edge.id] = new Edge({
                      ...edge,
                      from: replacement[nodeId],
                      id: undefined
                    });
                    return edgeReplacement[edge.id];
                  } else if (edge.to === nodeId) {
                    edgeReplacement[edge.id] = new Edge({
                      ...edge,
                      to: replacement[nodeId],
                      id: undefined
                    });
                    return edgeReplacement[edge.id];
                  } else {
                    return edge;
                  }
                });
                relatedResult.currentEdges = relatedResult.currentEdges.map(edge => edgeReplacement[edge.id] || edge);

                me.setRelatedClueResult(relatedResult.nodeId, relatedResult);
              }
            }
          });
        });
      }
    );
  };

  /**
   * @private
   * 注册部分事件监听函数，处理节点资源联想结果相关业务
   */
  registerListeners4RelatedResourceResult = () => {
    const beforeRelationOperationHandler = (me, nodeId, relatedNodeId, event) => {
      let relatedResult = me.getRelatedResourceResult(nodeId);

      if (!relatedResult) return;

      // 查询对应边是否在结果集中
      const edgeIdx = relatedResult.newEdges.findIndex(edge =>
        (edge.from === relatedNodeId && edge.to === relatedResult.nodeId) ||
        (edge.from === relatedResult.nodeId && edge.to === relatedNodeId)
      );
      if (edgeIdx < 0) return;

      if (relatedResult && relatedResult.processingNodeIds.includes(relatedNodeId)) return;
      this._PB.emit('default', event, nodeId, relatedNodeId);
      if (relatedResult) {
        relatedResult.processingNodeIds.push(relatedNodeId);
        me.setRelatedResourceResult(nodeId, relatedResult);
      }
    };

    const onRelationOperationFailedHandler = (me, nodeId, relatedNodeId, event, errorInfo) => {
      let relatedResult = this.getRelatedResourceResult(nodeId);

      if (!relatedResult) return;

      // 查询对应边是否在结果集中
      const edgeIdx = relatedResult.newEdges.findIndex(edge =>
        (edge.from === relatedNodeId && edge.to === relatedResult.nodeId) ||
        (edge.from === relatedResult.nodeId && edge.to === relatedNodeId)
      );
      if (edgeIdx < 0) return;

      if (relatedResult) {
        relatedResult.processingNodeIds =
          relatedResult.processingNodeIds.filter(nodeId => nodeId !== relatedNodeId);
        this.setRelatedResourceResult(nodeId, relatedResult);
      }
      me._PB.emit('default', event, nodeId, relatedNodeId, errorInfo);
    };

    this.with(this).subscribe(
      EdgeEvents.ADDING,
      /**
       * @private
       * @function
       * @name beforeRelationSave4RelatedResourceResult
       * @description 在关联关系保存前触发，处理节点资源联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       */
      (edgeSimpleInfoList) => {
        let me = this._self;
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && fromNode.status === 0) {
            // 对于toNode来说，此操作可能为保存资源联想结果操作
            beforeRelationOperationHandler(me, toNodeId, fromNodeId, EdgeEvents.SAVING_RELATED_RESOURCE_RELATION);
          }
          if (toNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && toNode.status === 0) {
            // 对于fromNode来说，此操作可能为保存资源联想结果操作
            beforeRelationOperationHandler(me, fromNodeId, toNodeId, EdgeEvents.SAVING_RELATED_RESOURCE_RELATION);
          }
        });
      }
    ).subscribe(
      EdgeEvents.ADDED,
      /**
       * @private
       * @function
       * @name afterRelationSaved4RelatedResourceResult
       * @description 当关联关系保存后触发，处理节点资源联想结果相关业务
       *
       * @param {number} type 添加类型
       * @param {[string]} edgeIds 关联关系ID列表
       * @param {[Edge]} edgeInfoList 已添加的关系信息列表
       */
      (type, edgeIds, edgeInfoList) => {
        let me = this._self;
        // 仅处理实线关系添加事件
        if (type & ADD_TO_VIEW) {
          let relatedResults = me.getRelatedResourceResult();
          relatedResults.forEach(relatedResult => {
            edgeInfoList.forEach(edgeInfo => {
              const fromNodeId = edgeInfo.from;
              const toNodeId = edgeInfo.to;
              let relatedResourceNodeId;
              if (relatedResult.nodeId === fromNodeId) {
                relatedResourceNodeId = toNodeId;
              } else if (relatedResult.nodeId === toNodeId) {
                relatedResourceNodeId = fromNodeId;
              } else {
                return;
              }
              if (relatedResult.status === NodeGrowingStatus.SUCCESS) {

                // 查询对应边是否在结果集中
                const edgeIdx = relatedResult.newEdges.findIndex(edge =>
                  (edge.from === relatedResourceNodeId && edge.to === relatedResult.nodeId) ||
                  (edge.from === relatedResult.nodeId && edge.to === relatedResourceNodeId)
                );
                if (edgeIdx >= 0) {
                  relatedResult.newEdges[edgeIdx].id = edgeInfo.id;
                  relatedResult.currentSolidNodeIds.push(relatedResourceNodeId);
                  relatedResult.currentSolidEdgeIds.push(edgeInfo.id);
                  relatedResult.processingNodeIds =
                    relatedResult.processingNodeIds.filter(nodeId => nodeId !== relatedResourceNodeId);
                  me.setRelatedResourceResult(relatedResult.nodeId, relatedResult);
                  me._PB.emit('default', EdgeEvents.RELATED_RESOURCE_RELATION_SAVED, relatedResult.nodeId,
                    relatedResourceNodeId, edgeInfo.id);
                }
              }
            });
          });
        }
      }
    ).subscribe(
      EdgeEvents.ADD_FAILED,
      /**
       * @private
       * @function
       * @name onRelationFailedToSave4RelatedResourceResult
       * @description 当关联关系保存失败时触发，处理节点资源联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       * @param {{code: number, msg: string}} errorInfo 错误信息
       */
      (edgeSimpleInfoList, errorInfo) => {
        let me = this._self;
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && fromNode.status === 0) {
            onRelationOperationFailedHandler(me, toNodeId, fromNodeId, EdgeEvents.SAVE_RELATED_RESOURCE_RELATION_FAILED);
          }
          if (toNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && toNode.status === 0) {
            onRelationOperationFailedHandler(me, fromNodeId, toNodeId, EdgeEvents.SAVE_RELATED_RESOURCE_RELATION_FAILED);
          }
        });
      }
    ).subscribe(
      EdgeEvents.REMOVING,
      /**
       * @private
       * @function
       * @name beforeRelationRemove4RelatedResourceResult
       * @description 在关联关系删除前触发，处理节点资源联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       */
      (edgeSimpleInfoList) => {
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          let me = this._self;
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && fromNode.status === 0) {
            // 对于toNode来说，此操作可能为保存资源联想结果操作
            beforeRelationOperationHandler(me, toNodeId, fromNodeId, EdgeEvents.REMOVING_RELATED_RESOURCE_RELATION);
          }
          if (toNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && toNode.status === 0) {
            // 对于fromNode来说，此操作可能为保存资源联想结果操作
            beforeRelationOperationHandler(me, fromNodeId, toNodeId, EdgeEvents.REMOVING_RELATED_RESOURCE_RELATION);
          }
        });
      }
    ).subscribe(
      EdgeEvents.REMOVED,
      /**
       * @private
       * @function
       * @name afterRelationRemove4RelatedResourceResult
       * @description 当关联关系删除后触发，处理节点资源联想结果相关业务
       *
       * @param {number} type 删除类型
       * @param {[Edge]} edgeInfoList 已删除的边信息列表
       */
      (type, edgeInfoList) => {
        let me = this._self;
        let relatedResults = me.getRelatedResourceResult();
        // 仅处理实线关系删除事件
        if (type & REMOVE_FROM_VIEW) {
          edgeInfoList.forEach(edgeInfo => {
            let fromNodeId = edgeInfo.from;
            let toNodeId = edgeInfo.to;
            relatedResults.forEach(relatedResult => {
              let relatedResourceNodeId;
              if (relatedResult.nodeId === fromNodeId) {
                relatedResourceNodeId = toNodeId;
              } else if (relatedResult.nodeId === toNodeId) {
                relatedResourceNodeId = fromNodeId;
              } else {
                return;
              }

              // 查询对应边是否在结果集中
              const edgeIdx = relatedResult.newEdges.findIndex(edge =>
                (edge.from === relatedResourceNodeId && edge.to === relatedResult.nodeId) ||
                (edge.from === relatedResult.nodeId && edge.to === relatedResourceNodeId)
              );
              if (edgeIdx < 0) return;

              // 查询节点所在位置
              const nodeIdx = relatedResult.allNodes.findIndex(node => node.id === relatedResourceNodeId);
              if (nodeIdx < 0) return;

              let removeFromResult = false;
              if (type & REMOVE_FROM_GRAPH) {
                // 关系被直接删除，检查此关系是否在当前展示的批次中，如果在则需要从结果集中删除
                if (relatedResult.currentStartPos <= nodeIdx &&
                  (relatedResult.currentStartPos + relatedResult.currentlyShowingAmount) > nodeIdx) {

                  removeFromResult = true;
                }
              }

              relatedResult.currentSolidNodeIds =
                relatedResult.currentSolidNodeIds.filter(nodeId => relatedResourceNodeId !== nodeId);
              relatedResult.currentSolidEdgeIds =
                relatedResult.currentSolidEdgeIds.filter(edgeId => edgeInfo.id !== edgeId);
              relatedResult.processingNodeIds =
                relatedResult.processingNodeIds.filter(nodeId => nodeId !== relatedResourceNodeId);

              if (removeFromResult) {
                // nextStartPos可能为0
                relatedResult.nextStartPos = relatedResult.nextStartPos > relatedResult.currentStartPos ?
                  relatedResult.nextStartPos-- : relatedResult.nextStartPos;
                if (relatedResult.nextStartPos <= relatedResult.currentStartPos) {
                  relatedResult.currentStartPos = -1;
                }
                relatedResult.currentlyShowingAmount--;
                relatedResult.currentNodes = relatedResult.currentNodes
                  .filter(node => node.id !== relatedResourceNodeId);
                relatedResult.currentEdges = relatedResult.currentEdges.filter(edge => edge.id !== edgeInfo.id);
              }

              this.setRelatedResourceResult(relatedResult.nodeId, relatedResult);
              me._PB.emit('default', EdgeEvents.RELATED_RESOURCE_RELATION_REMOVED, relatedResult.nodeId,
                relatedResourceNodeId, edgeInfo);
            });
          });
        }
      }
    ).subscribe(
      EdgeEvents.REMOVE_FAILED,
      /**
       * @private
       * @function
       * @name onRelationFailedToRemove4RelatedResourceResult
       * @description 当关联关系删除失败时触发，处理节点资源联想结果相关业务
       *
       * @param {[{fromNodeId: string, toNodeId: string}]} edgeSimpleInfoList 关系简单信息列表
       * @param {{code: number, msg: string}} errorInfo 错误信息
       */
      (edgeSimpleInfoList, errorInfo) => {
        edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
          let me = this._self;
          const fromNode = me._data.nodes.get(fromNodeId);
          const toNode = me._data.nodes.get(toNodeId);
          if (!fromNode || !toNode) {
            return;
          }
          if (fromNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && fromNode.status === 0) {
            onRelationOperationFailedHandler(me, toNodeId, fromNodeId,
              EdgeEvents.REMOVE_RELATED_RESOURCE_RELATION_FAILED);
          }
          if (toNode[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT && toNode.status === 0) {
            onRelationOperationFailedHandler(me, fromNodeId, toNodeId,
              EdgeEvents.REMOVE_RELATED_RESOURCE_RELATION_FAILED);
          }
        });
      }
    ).subscribe(
      NodeEvents.REMOVING,
      /**
       * @private
       * @function
       * @name beforeNodeRemove4RelatedResourceResult
       * @description 在节点删除前触发，处理节点资源联想结果相关业务
       *
       * @param {number} type 删除类型
       * @param {[string]} nodeIdList 待删除节点ID列表
       */
      (type, nodeIdList) => {
        nodeIdList.forEach(nodeId => {
          let me = this._self;
          let relatedResults = me.getRelatedResourceResult();
          relatedResults.forEach(relatedResult => {
            // 查询节点所在位置
            const nodeIdx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
            if (nodeIdx < 0) return;

            beforeRelationOperationHandler(me, relatedResult.nodeId, nodeId,
              EdgeEvents.REMOVING_RELATED_RESOURCE_RELATION);
          });
        });
      }
    ).subscribe(
      NodeEvents.REMOVED,
      /**
       * @private
       * @function
       * @name afterNodeRemove4RelatedResourceResult
       * @description 当节点删除后触发，处理节点资源联想结果相关业务
       *
       * @param {number} type 删除类型
       * @param {[Node]} nodeInfoList 已删除的节点列表
       */
      (type, nodeInfoList) => {
        let me = this._self;
        let relatedResults = me.getRelatedResourceResult();

        // 仅处理实线关系删除事件
        if (type & REMOVE_FROM_VIEW) {
          nodeInfoList.forEach(nodeInfo => {
            let nodeId = nodeInfo.id;
            relatedResults.forEach(relatedResult => {
              if (relatedResult.nodeId === nodeId) {
                // 被扩展的节点被删除，清空扩展结果数据
                let connectedNodes =
                  me._relatedResourceResult.get(nodeId) ? me._relatedResourceResult.get(nodeId).currentNodes : [];
                me.clearRelatedResourceResult(nodeId, connectedNodes);
                me.setRelatedResourceResult(nodeId, null);
              } else if (relatedResult.status === NodeGrowingStatus.SUCCESS) {
                // 查询节点所在位置
                const idx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
                // 删除的节点不在结果集中
                if (idx === -1) {
                  // 删除的节点不在结果集中
                } else if (idx >= relatedResult.currentStartPos &&
                  idx < (relatedResult.currentStartPos + relatedResult.currentlyShowingAmount) &&
                  (type & REMOVE_FROM_GRAPH)) {

                  // 将数据从结果中删除，并修改nextStartPos及currentStartPos值
                  _.remove(relatedResult.newNodes, node => node.id === nodeId);
                  let removed = _.remove(relatedResult.allNodes, node => node.id === nodeId);
                  _.remove(relatedResult.currentNodes, node => node.id === nodeId);
                  relatedResult.currentlyShowingAmount -= removed.length;
                  if (relatedResult.nextStartPos > 0) {
                    relatedResult.nextStartPos -= removed.length;
                  }
                  if (relatedResult.nextStartPos <= relatedResult.currentStartPos) {
                    relatedResult.currentStartPos = -1;
                  }
                  let removedIds = removed.map(node => node.id);
                  relatedResult.currentSolidNodeIds =
                    relatedResult.currentSolidNodeIds.filter(nodeId => !removedIds.includes(nodeId));

                  let removedEdge = _.remove(relatedResult.newEdges,
                    edge => (edge.from === nodeId || edge.to === nodeId));
                  _.remove(relatedResult.currentEdges,
                    edge => (edge.from === nodeId || edge.to === nodeId));
                  if (removedEdge && removedEdge.length > 0) {
                    const removedEdgeIds = removedEdge.filter(edge => !!edge.id).map(edge => edge.id);
                    relatedResult.currentSolidEdgeIds =
                      relatedResult.currentSolidEdgeIds.filter(edgeId => !removedEdgeIds.includes(edgeId));
                  }
                  me.setRelatedResourceResult(relatedResult.nodeId, relatedResult);
                  me._PB.emit('default', EdgeEvents.RELATED_RESOURCE_RELATION_REMOVED, relatedResult.nodeId,
                    nodeId, removedEdge && removedEdge.length > 0 ? removedEdge[0] : undefined);
                } else {
                  // 删除的节点在结果集中，但对扩展结果无影响，将节点状态改为未固化，并从已固化节点、边ID中删除
                  relatedResult.allNodes[idx].status = 0;
                  if (-1 === relatedResult.newNodes.findIndex(node => node.id === nodeId)) {
                    relatedResult.newNodes.push(relatedResult.allNodes[idx]);
                  }

                  relatedResult.currentSolidNodeIds =
                    relatedResult.currentSolidNodeIds.filter(nodeId => nodeInfo.id !== nodeId);
                  let relatedEdges = relatedResult.newEdges
                    .filter(edge => (edge.from === nodeId || edge.to === nodeId));
                  let relatedEdgeIds = relatedEdges.map(edge => edge.id);
                  if (relatedEdgeIds && relatedEdgeIds.length > 0) {
                    relatedResult.currentSolidEdgeIds =
                      relatedResult.currentSolidEdgeIds.filter(edgeId => !relatedEdgeIds.includes(edgeId));
                  }
                  me.setRelatedResourceResult(relatedResult.nodeId, relatedResult);
                  if (relatedEdgeIds && relatedEdgeIds.length > 0) {
                    me._PB.emit('default', EdgeEvents.RELATED_RESOURCE_RELATION_REMOVED, relatedResult.nodeId,
                      nodeId, relatedEdges[0]);
                  }
                }
              }
            });
          });
        }
      }
    ).subscribe(
      NodeEvents.REMOVE_FAILED,
      /**
       * @private
       * @function
       * @name onNodeFailedToRemove4RelatedResourceResult
       * @description 当节点删除失败时触发，处理节点资源联想结果相关业务
       *
       * @param {[string]} nodeIdList 待删除节点ID列表
       * @param {{code: number, msg: string}} errorInfo 错误信息
       */
      (nodeIdList, errorInfo) => {
        let me = this._self;
        let relatedResults = me.getRelatedResourceResult();
        relatedResults.forEach(relatedResult => {
          nodeIdList.forEach(nodeId => {
            // 查询节点所在位置
            const nodeIdx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
            if (nodeIdx < 0) return;

            onRelationOperationFailedHandler(me, relatedResults.nodeId, nodeId,
              EdgeEvents.REMOVE_RELATED_RESOURCE_RELATION_FAILED);
          });
        });
      }
    ).subscribe(
      NodeEvents.ID_REPLACED,
      /**
       * @private
       * @function
       * @name afterNodeReplaced4RelatedClueResult
       * @description 当节点替换后触发，处理节点线索联想结果相关业务
       *
       * @param {Object} replacement 替换列表
       */
      (replacement) => {
        let me = this._self;
        let relatedResults = me.getRelatedResourceResult();

        Object.keys(replacement).forEach(fromId => {
          let nodeId = fromId;
          relatedResults.forEach(relatedResult => {
            if (relatedResult.nodeId === nodeId) {
              // 被扩展的节点被替换，清空扩展结果数据，应该不存在此情况
              let connectedNodes =
                me._relatedResourceResult.get(nodeId) ? me._relatedResourceResult.get(nodeId).currentNodes : [];
              me.clearRelatedResourceResult(nodeId, connectedNodes);
              me.setRelatedResourceResult(nodeId, null);
            } else if (relatedResult.status === NodeExpandingStatus.SUCCESS) {
              // 查询节点所在位置
              const idx = relatedResult.allNodes.findIndex(node => node.id === nodeId);
              // 删除的节点不在结果集中
              if (idx === -1) {
                // 删除的节点不在结果集中
              } else {
                // 替换ID
                relatedResult.newNodes.forEach(node => {
                  if (node.id === nodeId) node.id = replacement[nodeId];
                });
                relatedResult.currentNodes.forEach(node => {
                  if (node.id === nodeId) node.id = replacement[nodeId];
                });

                let index = _.findIndex(relatedResult.currentSolidNodeIds, id => id === nodeId);
                if (index >= 0) relatedResult.currentSolidNodeIds.splice(index, 1, replacement[nodeId]);

                let edgeReplacement = {};
                relatedResult.newEdges = relatedResult.newEdges.map(edge => {
                  if (edge.from === nodeId) {
                    edgeReplacement[edge.id] = new Edge({
                      ...edge,
                      from: replacement[nodeId],
                      id: undefined
                    });
                    return edgeReplacement[edge.id];
                  } else if (edge.to === nodeId) {
                    edgeReplacement[edge.id] = new Edge({
                      ...edge,
                      to: replacement[nodeId],
                      id: undefined
                    });
                    return edgeReplacement[edge.id];
                  } else {
                    return edge;
                  }
                });
                relatedResult.currentEdges = relatedResult.currentEdges.map(edge => edgeReplacement[edge.id] || edge);

                me.setRelatedResourceResult(relatedResult.nodeId, relatedResult);
              }
            }
          });
        });
      }
    );
  };

  /**
   * @private
   * 删除关系请求
   *
   * @param {string} edgeId 边ID
   *
   * @return {Promise}
   */
  removeEdgeInfoPromise = edgeId => {
    if (!_.isString(edgeId)) {
      return new Promise((resolve, reject) => reject({code: 500, msg: 'Invalid edge id.'}));
    }
    const edgeInfo = this._data.edges.get(edgeId);
    if (!edgeInfo) {
      return new Promise((resolve) => resolve());
    }
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_RemoveEdge(this._viewId, edgeInfo.from, edgeInfo.to)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 删除节点请求
   *
   * @param {string} nodeId 节点ID
   *
   * @return {Promise}
   */
  removeNodeInfoPromise = nodeId => {
    if (!_.isString(nodeId)) {
      return new Promise((resolve, reject) => reject({code: 500, msg: 'Invalid node id.'}));
    }
    const nodeInfo = this._data.nodes.get(nodeId);
    if (!nodeInfo) {
      return new Promise((resolve) => resolve());
    }
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_RemoveNode(me._viewId, nodeId)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 删除节点请求
   *
   * @param {string[]} nodeIds 节点ID列表
   *
   * @return {Promise}
   */
  removeNodeInfoListPromise = nodeIds => {
    if (!_.isArray(nodeIds)) {
      return new Promise((resolve, reject) => reject({code: 500, msg: 'Invalid node id list.'}));
    }
    const nodeInfoList = this._data.nodes.get(nodeIds);
    if (!nodeInfoList || nodeInfoList.length <= 0) {
      return new Promise((resolve) => resolve());
    }
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_RemoveNodes(me._viewId, nodeIds)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 删除指定附件请求
   *
   * @param {string} propertyId 附件ID
   * @param {string} [nodeId] 节点ID
   *
   * @return {Promise}
   */
  removeFilesPromise = (propertyId, nodeId) => {
    let me = this._self;
    if (nodeId) {
      if (!_.isString(nodeId)) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Invalid node id ${nodeId}.`}));
      }
      const node = me._data.nodes.get(nodeId);
      if (!node) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Node not found: ${nodeId}`}));
      }
    }
    return new Promise((resolve, reject) => {
      API_RemoveFile(propertyId)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        })
    })
  };

  /**
   * @private
   * 删除指定附件中某一文件请求
   *
   * @param {string} fileId 附件ID
   * @param {string} [nodeId] 节点ID
   *
   * @return {Promise}
   */
  removeFileItemPromise = (fileId, nodeId) => {
    let me = this._self;
    if (nodeId) {
      if (!_.isString(nodeId)) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Invalid node id ${nodeId}.`}));
      }
      const node = me._data.nodes.get(nodeId);
      if (!node) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Node not found: ${nodeId}`}));
      }
    }
    return new Promise((resolve, reject) => {
      API_RemoveFileItem(fileId)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        })
    })
  };

  /**
   * @private
   * 删除指定关系图请求
   *
   * @param {string[]} nodeIds 节点ID列表
   * @param {{from: string, to: string}[]} edgeKeyList
   *
   * @return {Promise}
   */
  removeRelationGraphPromise = (nodeIds, edgeKeyList) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_RemoveRelationGraph(me._viewId, nodeIds, edgeKeyList)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 恢复指定关系图请求
   *
   * @param {string[]} nodeIds 节点ID列表
   * @param {{from: string, to: string}[]} edgeKeyList
   *
   * @return {Promise}
   */
  recoverRelationGraphPromise = (nodeIds, edgeKeyList) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_RecoverRelationGraph(me._viewId, nodeIds, edgeKeyList)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 设置节点搜索结果信息
   *
   * @param {string} nodeId 节点ID
   * @param {string|Object|null} key 搜索结果字段名称或键值对对象，为空则清空数据
   * @param {*} [value] 字段值，当key为字段名称时此项必填
   */
  setExactMatchResult = (nodeId, key, value) => {
    let exactMatchResult = this.getExactMatchResult(nodeId);
    if (exactMatchResult) {
      if (_.isString(key) && value !== undefined) {
        // noinspection JSValidateTypes
        exactMatchResult = {
          ...exactMatchResult,
          [key]: value,
        };
      } else if (!key) {
        this._exactMatchResult.remove(nodeId);
        return;
      } else if (_.isPlainObject(key)) {
        // noinspection JSValidateTypes
        exactMatchResult = {
          ...exactMatchResult,
          ...key,
        };
      }
      this._exactMatchResult.update(exactMatchResult);
    }
  };

  /**
   * @private
   * 设置子图搜索结果信息
   *
   * @param {string} subViewId 子图ID
   * @param {string|Object|null} key 搜索结果字段名称或键值对对象，为空则清空数据
   * @param {*} [value] 字段值，当key为字段名称时此项必填
   */
  setMatchResultForSubView = (subViewId, key, value) => {
    let matchResultForSubView = this.getMatchResultForSubView(subViewId);
    if (matchResultForSubView) {
      if (_.isString(key) && value !== undefined) {
        // noinspection JSValidateTypes
        matchResultForSubView = {
          ...matchResultForSubView,
          [key]: value,
        };
      } else if (!key) {
        this._matchResultForSubView.remove(subViewId);
        return;
      } else if (_.isPlainObject(key)) {
        // noinspection JSValidateTypes
        matchResultForSubView = {
          ...matchResultForSubView,
          ...key,
        };
      }
      this._matchResultForSubView.update(matchResultForSubView);
    }
  };

  /**
   * @private
   * 设置节点扩展结果信息
   *
   * @param {string} nodeId 节点ID
   * @param {string|Object|null} key 扩展结果字段名称或键值对对象，为空则清空数据
   * @param {*} [value] 字段值，当key为字段名称时此项必填
   */
  setRelatedClueResult = (nodeId, key, value) => {
    let relatedClueResult = this.getRelatedClueResult(nodeId);
    if (relatedClueResult) {
      if (_.isString(key) && value !== undefined) {
        // noinspection JSValidateTypes
        relatedClueResult = {
          ...relatedClueResult,
          [key]: value,
        };
      } else if (!key) {
        this._relatedClueResult.remove(nodeId);
        return;
      } else if (_.isPlainObject(key)) {
        // noinspection JSValidateTypes
        relatedClueResult = {
          ...relatedClueResult,
          ...key,
        };
      }
      this._relatedClueResult.update(relatedClueResult);
    }
  };

  /**
   * @private
   * 设置节点联想结果信息
   *
   * @param {string} nodeId 节点ID
   * @param {string|Object|null} key 联想结果字段名称或键值对对象，为空则清空数据
   * @param {*} [value] 字段值，当key为字段名称时此项必填
   */
  setRelatedResourceResult = (nodeId, key, value) => {
    let relatedResourceResult = this.getRelatedResourceResult(nodeId);
    if (relatedResourceResult) {
      if (_.isString(key) && value !== undefined) {
        // noinspection JSValidateTypes
        relatedResourceResult = {
          ...relatedResourceResult,
          [key]: value,
        };
      } else if (!key) {
        this._relatedResourceResult.remove(nodeId);
        return;
      } else if (_.isPlainObject(key)) {
        // noinspection JSValidateTypes
        relatedResourceResult = {
          ...relatedResourceResult,
          ...key,
        };
      }
      this._relatedResourceResult.update(relatedResourceResult);
    }
  };

  /**
   * @private
   * 将视图配置转换成后台接受的参数
   *
   * @param options
   * @return {{area: string, types: string, time: string, keyword: string}}
   */
  stringifyViewOptions = (options = this._parsedViewOptions) => {
    let area;
    if (options.areas.length <= 0) {
      area = {prov: [], city: []};
    } else {
      let areaObj = options.areas[0];
      if (areaObj.province === areaObj.city) {
        area = {prov: [`${areaObj.province}-${areaObj.city}`], city: []};
      } else {
        area = {prov: [], city: [`${areaObj.province}-${areaObj.city}`]};
      }
    }
    return {
      time: options['time'],
      types: options.types.join(','),
      area: JSON.stringify(area),
      keyword: options.keywords.join(' '),
    };
  };

  /**
   * @private
   * 更新指定附件信息请求
   *
   * @param {string} propertyId 附件ID
   * @param {string} comment 附件说明
   * @param {*} meta 附件元信息
   * @param {string} [nodeId] 节点ID
   *
   * @return {Promise}
   */
  updateFilesRemarkPromise = (propertyId, comment, meta, nodeId) => {
    let me = this._self;
    if (nodeId) {
      if (!_.isString(nodeId)) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Invalid node id ${nodeId}.`}));
      }
      const node = me._data.nodes.get(nodeId);
      if (!node) {
        return new Promise((resolve, reject) => reject({code: 500, msg: `Node not found: ${nodeId}`}));
      }
    }
    return new Promise((resolve, reject) => {
      API_UpdateFilesRemark(propertyId,
        {remark: JSON.stringify({comment, meta, version: CURRENT_FILE_REMARK_VERSION})})

        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        })
    })
  };

  /**
   * 创建图谱相关的用户奖章
   *
   * @param {string} viewId
   * @param {string} userId 奖章接收人
   * @param {nodeId}
   * @param {nodeFname}
   * @param {text} 奖励名称
   * @param {type} 奖章类型 （数值）
   */
  setViewBadge = ({viewId, userId, nodeId, nodeFname, text, type}) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      if (!me._viewId) {
        reject({code: 500, msg: 'View id must not be empty'});
        return;
      }
      me._PB.emit('default', NodeEvents.BADGE_SETTING, {viewId, userId, nodeId, nodeFname, text, type});
      API_SetViewBadge({viewId, userId, nodeId, nodeFname, text, type}).then(response => {
        if (response && response.data && response.data.code === 0) {
          me._PB.emit('default', NodeEvents.BADGE_SET_SUCCESS, {
            viewId,
            userId,
            nodeId,
            nodeFname,
            text,
            type
          });
          resolve();
        } else {
          me._PB.emit('default', NodeEvents.BADGE_SET_FAILED, {
            viewId,
            userId,
            nodeId,
            nodeFname,
            text,
            type
          });
          reject(me.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(me.getErrorInfo(error));
      });
    });
  };

  

  /**
   * @private
   * 跨看板获取节点
   *
   * @param {string[]} viewId 图谱ID
   * @param {string[]} nodeId 节点ID
   *
   * @return {Promise}
   */
   getGraphNodeListPromise = (viewId, nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_GraphNodeList(viewId||me._viewId, nodeId)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve();
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

  /**
   * @private
   * 获取节点信息
   *
   * @param {string[]} viewId 图谱ID
   * @param {string[]} nodeId 节点ID
   *
   * @return {Promise}
   */
  getNodeInfoPromise = (viewId, nodeId) => {
    let me = this._self;
    return new Promise((resolve, reject) => {
      API_GetNodeInfo(viewId||me._viewId, '0', nodeId)
        .then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response.data.data);
          } else {
            reject(me.getErrorInfo({response}));
          }
        })
        .catch(error => {
          reject(me.getErrorInfo(error));
        });
    });
  };

}

export default ViewDataProvider;

const visNetworkWrappers = {};

class VisNetworkWrapper {
  self = undefined;
  network = undefined;

  static get(self, network) {
    if (!self._uuid) self._uuid = _.uniqueId('component_');
    if (!visNetworkWrappers[self._uuid]) {
      visNetworkWrappers[self._uuid] = new VisNetworkWrapper(self, network);
    }
    return visNetworkWrappers[self._uuid];
  }

  /**
   *
   * @param {Object} self 事件监听的实例
   * @param {ViewDataProvider} network VisNetwork实例
   */
  constructor(self, network) {
    this.self = self;
    this.network = network;
  }

  /**
   * 添加单次事件监听处理函数
   *
   * @param name 事件名称
   * @param handler 事件处理函数
   * @return {VisNetworkWrapper}
   */
  once = (name, handler) => {
    this.network.once(this.self, name, handler);
    return this;
  };

  /**
   * 添加事件监听处理函数
   *
   * @param name 事件名称
   * @param handler 事件处理函数
   * @return {VisNetworkWrapper}
   */
  subscribe = (name, handler) => {
    this.network.sub(this.self, name, handler);
    return this;
  };

  sub = this.subscribe;

  /**
   * 取消某个对象对所有事件的监听
   *
   * @return {VisNetworkWrapper}
   */
  unSubscribe = () => {
    this.network.unSub(this.self);
    return this;
  };

  unSub = this.unSubscribe;
}
