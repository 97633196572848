import React from "react";
import {BrowserRouter, Route, Redirect, Switch} from "react-router-dom";
import {connect} from 'react-redux'
import jwtDecode from 'jwt-decode';
import qs from 'qs';

import '@/style/index.less';

import Account, {UserInfoLoadingStatus} from '@/components/common/common.account';

// 组件
import MainView from '@/containers/mainView.1.0.1'
import LoginView from '@/containers/loginView'
import ResetPwdView from '@/containers/resetPwdView'
import RegisterView from '@/containers/registerView'
import Viewer from '@/containers/viewer'
import ExplorationView from "@/containers/explorationView";
import {initLoginViaUsernameAction, initLoginViaWechatAction} from "@/redux-saga/account/actions";
import {getToken, removeToken} from "@/utils/HttpUtil";
import DetailRelationView from "@/containers/detailRelationView";
import ExploreRelationView from "@/containers/exploreRelationView";
import VerifyEmailView from "@/containers/verifyEmailView";
import RelationView from "@/containers/relationView";
import PublicViews from "@/containers/publicView";
import IndexView from "@/containers/indexView";
import NoticeView from "@/containers/noticeView";
import SearchView from '@/containers/searchView'
import InsightView from '@/containers/insightView'
import TodoView from '@/containers/todoView'
import ChannelView from '@/containers/channelView'
import NodeExtendedView from '@/containers/nodeExtendedView'
import Demonstration from '@/containers/demonstration'

class R extends React.Component {
  render() {
    let token = getToken();
    const currentTime = (new Date()).getTime() / 1000;
    if (token) {
      try {
        const decoded = jwtDecode(token);
        if (decoded['exp'] < currentTime) {
          token = undefined;
        }
        console.log('decoded jwt: ', decoded);
      } catch (e) {
        token = undefined;
      }
      console.log('currentTime: ', currentTime);
    }
    console.log('token: ', token);
    console.log('this.props.location ->  :', this.props.location );
    if (!token || !this.props.userInfo) {
      removeToken();
      requestAnimationFrame(() => {
        let params = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
          strictNullHandling: true,
        });
        if (params['login_type'] === 'pwd') {
          this.props.initLoginViaUsername();
        } else {
          this.props.initWechatQRCode((dispatch) => {
            dispatch(initLoginViaUsernameAction())
          });
        }
      });
      return (
        <BrowserRouter>
          <Switch>
          {
            location.host !== 'www.joinmap.ai' && location.host !== 'joinmap.ai' ? (
              <Route path={'/home.html'} render={() => <Redirect to="/index" />} />
            ) : undefined
          }
            <Route path={'/index/:type?'} component={IndexView}/>
            <Route path={'/publicView'} component={PublicViews} />
            <Route path={'/login'} component={LoginView} />
            <Route path={'/resetPassword/:token'} component={ResetPwdView} />
            <Route path={'/verifyMailbox/:token'} component={VerifyEmailView} />
            <Route path={'/register'} component={RegisterView} />
            <Route path={'/show/:category?'} component={Demonstration} />
            <Route path={'/'} exact={true} render={() => <Redirect to="/home.html" />} />
            {(window.location.hostname=="ustcsv.joinmap.ai")?
              (<Route render={({location}) => <Redirect to={`/login`} />} />):
              (<Route render={({location}) => <Redirect to={`/login?p=${encodeURIComponent(location['pathname'] + (location['search'] ? location['search'] : ''))}`} />} />)}
          </Switch>
        </BrowserRouter>
      )
    }

    Account.userInfo = this.props.userInfo;
    Account.userInfoLoadingStatus = {
      status: this.props.userInfo ? UserInfoLoadingStatus.SUCCESS : UserInfoLoadingStatus.IDLE,
      errorCode: 0,
      errorMsg: '',
    };

    return (
      <BrowserRouter>
        <Switch>
          {
            location.host !== 'www.joinmap.ai' && location.host !== 'joinmap.ai' ? (
              <Route path={'/home.html'} render={() => <Redirect to="/index" />} />
            ) : undefined
          }
          {(window.location.hostname!=="ustcsv.joinmap.ai") &&
            <Route path={'/login'} component={LoginView} />}
          {(window.location.hostname=="ustcsv.joinmap.ai") &&
            <Route path={'/index/:type?'}  render={() => <Redirect to="/channel/ustcsv/teamwork" />}/>}
          {(window.location.hostname!=="ustcsv.joinmap.ai") &&
            <Route path={'/index/:type?'} component={IndexView}/>}
          <Route path={'/resetPassword/:token'} component={ResetPwdView} />
          <Route path={'/verifyMailbox/:token'} component={VerifyEmailView} />
          <Route path={'/verifyEmail/:token'} component={VerifyEmailView} />
          <Route path={'/register'} component={RegisterView} />
          <Route path={'/mainview/:category?/:id?'} component={MainView} />
          <Route path={'/detail_relation/:type/:id'} component={DetailRelationView}/>
          <Route path={'/explore_relation/:viewId/:nodeId/:nodeType/:urlData?'} component={ExploreRelationView}/>
          <Route path={'/explore/:viewId/:resultType?/:selectedNodeId?/:resourceType?/:resourceId?'} component={ExplorationView}/>
          <Route path={'/relation/:viewId/:resultType?/:selectedNodeId?'} component={RelationView}/>
          <Route path={'/viewer'} component={Viewer} />
          <Route path={'/notice'} component={NoticeView} />
          <Route path={'/search'} component={SearchView} />
          <Route path={'/insight'} component={InsightView} />
          <Route path={'/todo'} component={TodoView} />
          <Route path={'/channel'} component={ChannelView} />
          <Route path={'/node_extended'} component={NodeExtendedView} />
          <Route path={'/show/:category?'} component={Demonstration} />
          <Route path={'/'} render={() => <Redirect to="/home.html" />} />
          <Route render={() => <Redirect to="/home.html" />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(
  state => ({userInfo: state.account.userInfo}),
  dispatch => ({
    initLoginViaUsername: () => dispatch(initLoginViaUsernameAction()),
    initWechatQRCode: (backFn) => dispatch(initLoginViaWechatAction(() => {backFn(dispatch)})),
  })
)(R);
