import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Input, Button, Tooltip, Form, List, message, Modal, Radio} from 'antd';
// noinspection SpellCheckingInspection
import anime from 'animejs';
import copy from 'copy-to-clipboard';

import style from '@/style/common/relation/common.relation.search.less';
import nodeStyle from '@/style/components/main.nodeInfo.less';
import PB from "@/libs/simplePB";
import {
  getNodeDisplayTitle,
  NODE_TYPE_COMPANY,
  NODE_TYPE_PAPER,
  NODE_TYPE_PATENT,
  NODE_TYPE_TALENT,
} from "@/constants/vis.defaultDefine.1";
import CompanyNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.company.summary";
import TalentNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.talent.summary";
import PaperNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.paper.summary";
import PatentNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.patent.summary";
import CommonNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.common.summary";
import {compare} from "@/utils/PinYinCompare";
import {NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';
import TextNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.text.summary";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import {showPageLoading} from "@/components/common/common.message";
import {IconTypes} from "@/constants/common";
import Icon from "@/components/common/common.icon";
import {SysUIConfig} from "@/constants/sys.config";
import intl from 'react-intl-universal';

const dataListKey = ['nodes'];
const searchFields = ['fname', 'tag'];
const roundPerFrame = 200;

let defaultDataLists = {};
dataListKey.forEach(v => defaultDataLists[v] = []);

const margin = 8;

@Form.create()
class RelationSearch extends React.Component {
  element = undefined; // dom node
  anim = undefined; // 动画对象
  parentBoundingClientRect = undefined;
  ctrlDown = false;
  manualShowSearchMessageKey = undefined;
  manualSearchText = '';
  nextInputLine = undefined;
  currentSelectedNodeId = undefined;

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      // 设置起始位置
      translateX: (document.body.offsetWidth - 379) * 0.5,
      translateY: document.body.offsetHeight * 0.75,
      lastSearchText: undefined,
      searching: false, // 是否正在搜索中
      searchCallback: undefined, // 搜索成功以后的回调
      searchResultList: [],
      smartSearchResultList: [],
      smartSearching: false,
      fileList: [],
      // visible: !!props.readonly,
      visible: true,

      // 键盘操作选择搜索结果
      searchResultHand: null,

      // 多项简单输入确认框
      showMultipleItemInputConfirmModal: false,
      multipleItemInputList: [],
      multipleItemInputMethodType: 'star',
      multipleItemInputProcessing: false,
      multipleItemInputSeparatedBy: 'space',

      // 多行复杂输入确认框
      showMultipleLineInputConfirmModal: false,
      multipleLineInputContent: '',
      multipleLineInputMethodType: 'single-line',
      multipleLineInputProcessing: false,
    };
    // this.anaFile = null
    this.canMove = false;
    this.lastX = null;
    this.lastY = null;
    this.timeoutId = 0;
    window.onmouseup = e => this.onMouseUp(e);
    window.onmousemove = e => this.onMouseMove(e);
  }

  onMouseDown = (e) => {
    e.stopPropagation();
    const { anim } = this;
    if (anim) stopAnimation(anim);
    this.canMove = true
  };

  onMouseMove = (e) => {
    this.canMove && this.onMove(e)
  };

  // 40是上工具栏的高度
  onMove = (e) => {
    if (this.lastX && this.lastY) {
      let dx = e.clientX - this.lastX;
      let dy = e.clientY - this.lastY;
      this.setState({
        translateX: (this.state.translateX + dx) > this.props.areaLeft ? (
          (this.state.translateX + dx) < (this.element.parentElement.offsetWidth - this.props.areaRight - 32) ?
            (this.state.translateX + dx) : (this.element.parentElement.offsetWidth - this.props.areaRight - 32)
        ) : this.props.areaLeft,
        translateY: (this.state.translateY + dy) > (this.props.areaTop) ? (
          (this.state.translateY + dy) < (this.element.parentElement.offsetHeight - this.props.areaBottom - 32) ?
            (this.state.translateY + dy) : (this.element.parentElement.offsetHeight - this.props.areaBottom - 32)
        ) : (this.props.areaTop),
      }, () => PB.emit('relationSearch', 'position.after_change',
        {x: this.state.translateX, y: this.state.translateY}));
    }
    this.lastX = e.clientX > (this.props.areaLeft + this.parentBoundingClientRect.left) ? (
      e.clientX < (this.element.parentElement.offsetWidth - this.props.areaRight - 32) ?
        e.clientX : (this.element.parentElement.offsetWidth - this.props.areaRight - 32)
    ) : (this.props.areaLeft + this.parentBoundingClientRect.left);
    this.lastY = e.clientY > (this.props.areaTop + this.parentBoundingClientRect.top) ? (
      e.clientY < (this.element.parentElement.offsetHeight - this.props.areaBottom - 32) ?
        e.clientY : (this.element.parentElement.offsetHeight - this.props.areaBottom - 32)
    ) : (this.props.areaTop + this.parentBoundingClientRect.top);
  };

  onMouseUp = () => {
    let me = this;

    if (!me.canMove) return;

    me.lastX = null;
    me.lastY = null;
    me.canMove = false;
  };

  dockComponent = () => {
    let me = this;

    PB.emit('relationSearch', 'position.auto_dock');
    setTimeout(() => {
      let targetTranslateX = me.props.dockAtLeft > 0 ? me.props.dockAtLeft :
        (me.element.parentElement.offsetWidth + me.props.dockAtLeft - 32);
      let targetTranslateY = me.props.dockAtTop > 0 ? me.props.dockAtTop :
        (me.element.parentElement.offsetHeight + me.props.dockAtTop - 32);
      if (me.state.translateX !== targetTranslateX || me.state.translateY !== targetTranslateY) {
        const { anim } = me;
        if (anim) stopAnimation(anim);
        me.anim = anime({
          targets: me.element,
          left: targetTranslateX,
          top: targetTranslateY,
          duration: 800,
          easing: 'easeInOutCirc',
          update: function(anim) {
            me.setState({
              translateX: parseInt(anim.animations[0].currentValue.substring(0,
                anim.animations[0].currentValue.length - 2)),
              translateY: parseInt(anim.animations[1].currentValue.substring(0,
                anim.animations[1].currentValue.length - 2)),
            }, () => PB.emit('relationSearch', 'position.after_change',
              {x: me.state.translateX, y: me.state.translateY}));
          },
        });
      }
    }, 100);
  };

  /**
   * 生成列表内容
   * @param node
   * @returns {*}
   */
  getNodeContent = (node) => {
    const nodeTypeFieldName = this.props.typeFieldName;
    const onTitleClicked = () => PB.emit('network', 'focus', node);

    switch (node[nodeTypeFieldName]) {
      case NODE_TYPE_COMPANY:
        return (
          <CompanyNodeSummaryInfo
            node={node}
            permanent={node.status === 1}
            showSummary={true}
            onTitleClicked={onTitleClicked}
          />
        );
      case NODE_TYPE_TALENT:
        return (
          <TalentNodeSummaryInfo
            node={node}
            permanent={node.status === 1}
            showSummary={true}
            onTitleClicked={onTitleClicked}
          />
        );
      case NODE_TYPE_PAPER:
        return (
          <PaperNodeSummaryInfo
            node={node}
            permanent={node.status === 1}
            showSummary={true}
            onTitleClicked={onTitleClicked}
          />
        );
      case NODE_TYPE_PATENT:
        return (
          <PatentNodeSummaryInfo
            node={node}
            permanent={node.status === 1}
            showSummary={true}
            onTitleClicked={onTitleClicked}
          />
        );
      case NODE_TYPE_TEXT:
        return (
          <TextNodeSummaryInfo
            node={node}
            networkRef={this.props.networkRef}
            onTitleClicked={onTitleClicked}
          />
        );
      default:
        return (
          <CommonNodeSummaryInfo
            node={node}
            permanent={node.status === 1}
            showSummary={true}
            networkRef={this.props.networkRef}
            onTitleClicked={onTitleClicked}
          />
        );
    }
  };

  /**
   * 向图谱添加节点，通过参数指定是否扩展
   *
   * @param {string} autoParse 自动解析方式
   */
  doAdd = (autoParse = 'complex') => {
    let me = this, text;

    if (me.state.showMultipleLineInputConfirmModal) {
      text = me.nextInputLine;
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `智能输入 "${getNodeDisplayTitle({fname: text}, 30)}"`,
      });
    } else if (me.state.showMultipleItemInputConfirmModal) {
      text = me.state.multipleItemInputList.join(' ');
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `智能输入 "${getNodeDisplayTitle({fname: text}, 30)}"`,
      });
    } else {
      text = _.trim(me.state.searchText);
    }

    if (!text || text === "") {
      message.warn('请先输入关注点后再添加。');
      me.searchTextInput && this.searchTextInput.focus();
      return;
    }
    PB.emit('network', 'addNode', {
      value: text,
      autoParse,
      successCB: () => {
        try {
          if (me.state.showMultipleLineInputConfirmModal) {
            let lines = me.state.multipleLineInputContent.split("\r\n");
            // 删除已经处理过的数据
            lines.shift();
            me.nextInputLine = _.trim(lines[0]);
            while (!me.nextInputLine && lines.length > 0) {
              lines.shift();
              me.nextInputLine = lines.length > 0 ? _.trim(lines[0]) : undefined;
            }
            if (me.nextInputLine) {
              me.setState({multipleLineInputContent: lines.join("\r\n")},
                () => setTimeout(() => me.doAdd(), 10));
              return;
            } else {
              // 操作完成
              me.setState({
                showMultipleLineInputConfirmModal: false,
                multipleLineInputContent: '',
                multipleLineInputMethodType: 'single-line',
                multipleLineInputProcessing: false,
              }, () => message.success('所有操作已完成'));
            }
          } else if (me.state.showMultipleItemInputConfirmModal) {
            me.setState({
              showMultipleItemInputConfirmModal: false,
              multipleItemInputList: [],
              multipleItemInputMethodType: 'star',
              multipleItemInputProcessing: false,
            }, () => message.success('操作已完成'));
          }
          me.setState({searchText: ''},
            () => PB.emit('relation', 'find_node_by_text.start', {text: ''}));
        } catch (e) {
          // ignore
        }
      },
      failedCB: () => {
        if (me.state.showMultipleLineInputConfirmModal) {
          me.setState({
            multipleLineInputProcessing: false,
          });
        } else if (me.state.showMultipleItemInputConfirmModal) {
          me.setState({
            multipleItemInputProcessing: false,
          });
        }
      },
      interruptedCB: () => {
        if (me.state.showMultipleLineInputConfirmModal) {
          me.setState({
            multipleLineInputProcessing: false,
          }, () => message.warn('操作已终止'));
        } else if (me.state.showMultipleItemInputConfirmModal) {
          me.setState({
            multipleItemInputProcessing: false,
          }, () => message.warn('操作已终止'));
        }
      },
      bulk: me.state.showMultipleLineInputConfirmModal,
    });
  };

  doSearch = () => {
    let me = this;

    if (me.timeoutId) clearTimeout(me.timeoutId);
    me.timeoutId = 0;
    let searchText = me.state.searchText.toLowerCase();
    if (me.state.searchText !== '' && me.state.searchText !== me.state.lastSearchText) {
      // console.log('搜索文本发生变化，重新搜索');
      // 搜索文本发生变化，重新搜索
      let doQuickSearch = false; // 标记是否可以使用快速查询
      let lastSearchResultList = me.state.searchResultList;
      if (me.state.lastSearchText && !me.state.searching &&
        me.state.searchText.indexOf(me.state.lastSearchText) >= 0) {

        // console.log('新搜索字符串包含原搜索字符串，原结果应包含新结果，支持快速搜索');
        // 新搜索字符串包含原搜索字符串，原结果应包含新结果，支持快速搜索
        doQuickSearch = true;
      }
      PB.emit('relation', 'find_node_by_text.started', {
        text: searchText,
      });
      if (me.props.networkRef) {
        setTimeout(() => {
          if (searchText !== me.state.searchText.toLowerCase()) return;
          // console.log('调用后台引力场查询');
          // 调用后台引力场查询
          me.props.networkRef.smartSearch(searchText).then(nodes => {
            // console.log('后台引力场查询正常返回');
            if (searchText === me.state.searchText.toLowerCase()) {
              // console.log('后台引力场查询正常返回，结果有效，me.state.searching - ', me.state.searching);
              PB.emit('relation', `find_node_by_text.${
                me.state.searching ? 'processing' : 'finished'
              }`, {
                text: searchText,
                resultList: me.state.searchResultList,
                searching: me.state.searching,
                smartSearchResultList: nodes,
                smartSearching: false,
                changed: true,
              });
            }
          }).catch(() => {
            // console.log('后台引力场查询返回错误信息');
            if (searchText === me.state.searchText.toLowerCase()) {
              // console.log('后台引力场查询返回错误信息，待搜索文本尚未变化，me.state.searching - ', me.state.searching);
              PB.emit('relation', `find_node_by_text.${
                me.state.searching ? 'processing' : 'finished'
              }`, {
                text: searchText,
                resultList: me.state.searchResultList,
                searching: me.state.searching,
                smartSearchResultList: [],
                smartSearching: false,
                changed: true,
              });
            }
          });
        }, 1000);
      }
      requestAnimationFrame(() => {
        // 循环本地搜索
        let currentDataListKeyIndex = 0,
          currentDataIndex = 0,
          currentNodeList = doQuickSearch ? lastSearchResultList :
            me.props.dataLists[dataListKey[currentDataListKeyIndex]].get(),
          currentNodeListLength = currentNodeList.length,
          currentResult = [];

        let fn = (round = 1) => {
          if (searchText !== me.state.searchText.toLowerCase()) {
            // console.log('搜索文本被修改，终止此次搜索，由于后一次搜索可能已经开始，此处不改变searching状态');
            // 搜索文本被修改，终止此次搜索，由于后一次搜索可能已经开始，此处不改变searching状态
            PB.emit('relation', 'find_node_by_text.interrupted', {text: searchText});
            return;
          }
          let newDataFound = false;

          if (currentNodeListLength <= currentDataIndex) {
            currentDataIndex = 0;
            if (doQuickSearch) {
              currentDataListKeyIndex = 0;
            } else {
              currentDataListKeyIndex++;
              if (currentDataListKeyIndex >= dataListKey.length) {
                currentDataListKeyIndex = 0;
              }
              currentNodeList = me.props.dataLists[dataListKey[currentDataListKeyIndex]].get();
              currentNodeListLength = currentNodeList.length;
            }
            // 进入下一轮循环
          } else {
            let currentNode = currentNodeList[currentDataIndex];
            searchFields.find(field => {
              if (currentNode[field] && currentNode[field].toLowerCase().indexOf(searchText) !== -1) {
                // 找到节点
                currentResult.push(currentNode);
                newDataFound = true;
                return true;
              } else {
                return false;
              }
            });
            currentDataIndex++;
            // 进入下一轮循环
          }

          if (currentDataListKeyIndex !== 0 || currentDataIndex !== 0) {
            round >= roundPerFrame ? requestAnimationFrame(() => {
              if (newDataFound) {
                currentResult = currentResult.sort((v1, v2) => {
                  return compare(v1, v2)
                });
                PB.emit('relation', 'find_node_by_text.processing', {
                  text: searchText,
                  resultList: currentResult,
                  changed: me.state.searchResultList.length !== currentResult.length,
                });
                requestAnimationFrame(() => fn(0));
              } else {
                fn(0);
              }
            }) : fn(round + 1);
          } else {
            // console.log('循环结束，me.state.smartSearching - ', me.state.smartSearching);
            // 循环结束
            if (newDataFound) {
              currentResult = currentResult.sort((v1, v2) => {
                return compare(v1, v2)
              });
            }
            PB.emit('relation', `find_node_by_text.${
              me.state.smartSearching ? 'processing' : 'finished'
            }`, {
              text: searchText,
              resultList: currentResult,
              searching: false,
              smartSearchResultList: me.state.smartSearchResultList,
              smartSearching: me.state.smartSearching,
              changed: true,
            });
          }

        };
        fn();
      });
    } else if (me.state.searchText === '') {
      PB.emit('relation', 'find_node_by_text.started', {text: ''});
      PB.emit('relation', 'find_node_by_text.finished', {
        text: '',
        resultList: [],
        searching: false,
        smartSearchResultList: [],
        smartSearching: false,
        changed: me.state.searchResultList.length > 0,
      });
    } else if (!me.state.searching && !me.state.smartSearching) {
      PB.emit('relation', 'find_node_by_text.finished', {
        text: searchText,
        resultList: me.state.searchResultList,
        searching: false,
        smartSearchResultList: me.state.smartSearchResultList,
        smartSearching: false,
        changed: false,
      });
    }
  };

  emitEmpty = () => {
    this.searchTextInput.focus();
    PB.emit('relation', 'find_node_by_text.start', {text: ''});
  };

  initListeners = () => {
    let me = this;

    me.element.onkeyup = function (event) {
      let e = event || window.event;
      e && console.log('松开了键：', e, e.keyCode);

      // ctrl
      if (e && e.keyCode === 17) {
        me.ctrlDown = false;
      }
    };

    // 键盘响应
    me.element.onkeydown = function (event) {
      let e = event || window.event;
      e && console.log('按下了键：', e, e.keyCode);

      // ctrl
      if (e && e.keyCode === 17) {
        me.ctrlDown = true;
      }

      // delete
      if (e && e.keyCode === 46) {
        if (me.ctrlDown) PB.emit('relation', 'selection.on_remove');
      }

      // 按 up=减
      if (e && e.keyCode === 38) {
        //要做的事情
        console.log('按下了 up');

        if (me.state.searchResultList.length > 0) {
          if (me.state.searchResultHand === null) {
          } else if (me.state.searchResultHand === 0) {
            // 第一条
            me.setState({
              searchResultHand: null,
            }, () => {
              let ipt = document.getElementById('schInput');
              // ipt.focus();
              me.setCaretPosition(ipt)
            })
          } else {
            me.setState({
              searchResultHand: me.state.searchResultHand - 1,
            }, () => {
              document.getElementById('schListId' + me.state.searchResultHand).focus();
            })
          }
        } else {
          me.setState({
            searchResultHand: null,
          })
        }
      }

      // 按 down=加
      if (e && e.keyCode === 40) {
        //要做的事情
        console.log('按下了 down');
        if (me.state.searchResultList.length > 0) {
          if (me.state.searchResultHand == null) {
            me.setState({
              searchResultHand: 0,
            }, () => {
              document.getElementById('schListId' + me.state.searchResultHand).focus();
            })
          } else if (me.state.searchResultHand === me.state.searchResultList.length - 1) {
            // 最后一条
          } else {
            me.setState({
              searchResultHand: me.state.searchResultHand + 1,
            }, () => {
              document.getElementById('schListId' + me.state.searchResultHand).focus();
            })
          }

        } else {
          me.setState({
            searchResultHand: null,
          })
        }
      }

      // 按 esc = 清空输入内容
      if (e && e.keyCode === 27 && me.state.searchText !== '') {
        console.log(' 按下键盘的 ESC ');
        me.emitEmpty();
      }

    };

    me.element.onkeypress = function (event) {
      let e = event || window.event;
      if (e && e.keyCode === 27) { // 按 Esc
        //要做的事情
        console.log('按下了 ESC');
        me.setState({
          searchResultHand: null,
        })
      }

      // 回车 聚焦
      if (e && e.keyCode === 13) { // 按 Esc
        //要做的事情
        console.log('按下了 Enter', e, e.keyCode);
        if (me.state.searchResultList.length > 0 && me.state.searchResultHand !== null) {
          PB.emit('network', 'focus', me.state.searchResultList[me.state.searchResultHand]);
        }
      }
    };
  };

  //移动光标至最后一位，fixme: 偶尔有效果
  setCaretPosition = (ele) => {
    ele.blur();
    ele.focus();
    let len = ele.value.length;
    if (document.selection) {
      let sel = ele.createTextRange();
      sel.moveStart('character', len);
      sel.collapse();
      sel.select();
    } else {
      ele.selectionStart = ele.selectionEnd = len;
    }
  };

  onAdd = (text, type = 'auto') => {
    let me = this;

    if (!text || text === "") {
      message.warn('请先输入关注点后再添加。');
      me.searchTextInput && this.searchTextInput.focus();
      return;
    }

    switch (type) {
      case 'single':
        me.doAdd('single');
        break;
      default:
        if (text.includes("\n") && (text.includes(' ') || text.includes('>'))) {
          // 有换行，且有空格或大于号等语法字符
          requestAnimationFrame(() => {
            me.setState({
              showMultipleLineInputConfirmModal: true,
              multipleLineInputContent: text,
              multipleLineInputMethodType: 'single-line',
            });
          });
        } else if (text.includes("\n")) {
          // 有换行，且没有空格或大于号等语法字符
          let multipleItemInputList = text.replace(/\r\n/g, "\n")
            .replace(/\r/g, "\n").replace(/[\n]+/g, "\n")
            .split("\n");
          requestAnimationFrame(() => {
            me.setState({
              showMultipleItemInputConfirmModal: true,
              multipleItemInputList,
              multipleItemInputSeparatedBy: 'line',
            });
          });
        } else if (text.includes('>')) {
          // 有大于号语法字符
          me.doAdd();
        } else if (text.includes(' ')) {
          let multipleItemInputList = text.split(' ').filter(t => !!t);
          if (multipleItemInputList.length <= 2) {
            // 按照常规语法处理
            me.doAdd();
          } else {
            // 有空格语法字符
            requestAnimationFrame(() => {
              me.setState({
                showMultipleItemInputConfirmModal: true,
                multipleItemInputList: text.split(' ').filter(t => !!t),
                multipleItemInputSeparatedBy: 'space',
              });
            });
          }
        } else {
          // 单文本输入
          me.doAdd('single');
        }
    }
  };

  onSearchInputPaste = e => {
    let me = this, clipboardText = e.clipboardData.getData('text');

    e.stopPropagation();
    clipboardText = clipboardText.trim();
    if (clipboardText.includes("\n")) {
      clipboardText =
        (me.searchTextInput && me.searchTextInput.input.value ? me.searchTextInput.input.value + "\n" : '' )
        + clipboardText;
      clipboardText = clipboardText.replace(/\s*([\r\n]+)\s*/g, "$1");
      me.onAdd(clipboardText);
    }
  };

  /**
   * 处理搜索框输入信息
   *
   * @param {string} value 输入的内容
   * @param {boolean} doSearch 是否执行搜索
   * @param {boolean|string} doAdd 是否执行添加及添加方式
   */
  doHandleSearchInput = (value, doSearch = true, doAdd = 'auto') => {
    let me = this;

    if (doSearch) {
      if (me.state.searchCallback) {
        // 已注册过回调，忽略
        return;
      }
      const tmpObj = {hideLoading: undefined};
      const showLoadingTimeout = setTimeout(() => {
        tmpObj.hideLoading = showPageLoading('查询中，请稍后……');
        setTimeout(tmpObj.hideLoading, 20000);
      }, 200);
      if (value && _.trim(value) !== '' && me.manualSearchText !== _.trim(value)) {
        value = _.trim(value);
        if (doAdd) {
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `输入 "${getNodeDisplayTitle({fname: value}, 30)}"`,
          });
        } else {
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `在图谱中搜索 "${getNodeDisplayTitle({fname: value}, 30)}"`,
          });
        }
      }
      me.setState({
        searchCallback: () => {
          clearTimeout(showLoadingTimeout);
          const fn = () => {
            if (!doAdd || me.state.searchResultList.length > 0) {
              PB.emit('relation', 'find_node_by_text.show_next', {text: me.state.searchText});
            } else {
              me.onAdd(value, doAdd);
            }
          };
          if (tmpObj.hideLoading) {
            setTimeout(() => {
              tmpObj.hideLoading();
              fn();
            }, 500);
          } else {
            fn();
          }
        },
      }, () => {
        PB.emit('relation', 'find_node_by_text.start', {text: value, manualStart: true});
      });
    } else if (doAdd) {
      if (_.trim(value) !== "") {
        if (doAdd === 'single') {
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `单节点输入 "${getNodeDisplayTitle({fname: _.trim(this.state.searchText)}, 30)}"`,
          });
        } else {
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `智能输入 "${getNodeDisplayTitle({fname: _.trim(this.state.searchText)}, 30)}"`,
          });
        }
      }
      me.onAdd(value, doAdd);
    }
  };

  doHandleSearchInputAll = (value) => {
    let me = this;
    if (value && _.trim(value) !== '' && me.manualSearchText !== _.trim(value)) {
      value = _.trim(value);
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `全站搜索 "${getNodeDisplayTitle({fname: value}, 30)}"`,
      });
      //PB.emit('searchinput', 'ety.list.show', {keyword: me.state.searchText});
    }
    PB.emit('searchinput', 'ety.list.show', {keyword: _.trim(value)});
  };

  onMultipleLineInputConfirmed = () => {
    let me = this;

    if (me.state.multipleLineInputMethodType === 'single-line') {
      // 整体解析
      me.setState({
        showMultipleLineInputConfirmModal: false,
        multipleLineInputContent: '',
        multipleLineInputMethodType: 'single-line',
        multipleLineInputProcessing: false,
        searchText: me.state.multipleLineInputContent.replace(/\r\n/g, " ").replace(/\r/g, " ")
          .replace(/\n/g, " ").replace(/\s+/g, " "),
      }, () => me.doAdd());
    } else {
      let lines = me.state.multipleLineInputContent.replace(/\r\n/g, "\n")
        .replace(/\r/g, "\n").split("\n");
      me.nextInputLine = undefined;
      if (lines.length > 0) {
        me.nextInputLine = _.trim(lines[0]);
      }
      while (!me.nextInputLine && lines.length > 0) {
        lines.shift();
        me.nextInputLine = lines.length > 0 ? _.trim(lines[0]) : undefined;
      }
      if (me.nextInputLine) {
        me.setState({multipleLineInputContent: lines.join("\r\n"), multipleLineInputProcessing: true},
          () => setTimeout(() => me.doAdd(), 10));
      } else {
        // 没数据，不会吧
        message.warn('请先输入关注点后再添加。');
      }
    }
  };

  onMultipleItemInputConfirmed = () => {
    let me = this;

    me.setState({multipleItemInputProcessing: true},
      () => me.doAdd(me.state.multipleItemInputMethodType));
  };

  onCopyResultNodeTitles = () => {
    let textList = [];

    _.uniqBy(_.concat(
      this.state.searchResultList || [], this.state.smartSearchResultList || []
    ), n => n.id).forEach(n => {
      textList.push(getNodeDisplayTitle(n));
    });

    if (textList.length <= 0) {
      message.warn('没有可以复制标题的节点。');
      return;
    }

    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制列表中节点的标题。',
    });

    if (result) message.success('节点标题已复制到剪切板。');
  }

  componentDidMount() {
    let me = this;

    PB.sub(me, 'relationView', 'showOrHideInput', () => {
      me.setState({
        visible: !me.state.visible,
      })
    });
    PB.sub(me, 'workspace', 'input.change_visible', () => {
      me.setState({
        visible: !me.state.visible,
      })
    });
    PB.sub(me, 'workspace', 'input.do_focus', () => {
      if (me.state.visible) {
        me.searchTextInput && me.searchTextInput.focus();
      }
    });
    PB.sub(me, 'relation', 'find_node_by_text.show_next', ({text}) => {
      if (me.state.searchText !== text) {
        const tmpObj = {hideLoading: undefined};
        const showLoadingTimeout = setTimeout(() => {
          tmpObj.hideLoading = showPageLoading('查询中，请稍后……');
          setTimeout(tmpObj.hideLoading, 20000);
        }, 200);
        let originalCallback = me.state.searchCallback;
        let searchCallback = () => {
          clearTimeout(showLoadingTimeout);
          const fn = () => {
            tmpObj.hideLoading && tmpObj.hideLoading();
            PB.emit('relation', 'find_node_by_text.show_next', {text});
            originalCallback && originalCallback();
          };
          if (tmpObj.hideLoading) {
            setTimeout(() => {
              tmpObj.hideLoading();
              fn();
            }, 500);
          } else {
            fn();
          }
        };
        me.setState({searchCallback}, () => {
          PB.emit('relation', 'find_node_by_text.start', {text});
          let ipt = document.getElementById('schInput');
          // ipt.focus();
          me.setCaretPosition(ipt);
        });
      } else {
        let resultList = _.uniqBy(
          _.concat(this.state.searchResultList || [], this.state.smartSearchResultList || []), n => n.id);
        if (resultList.length > 0) {
          let currentPos = me.state.searchResultHand;
          if (currentPos === null) {
            currentPos = 0;
          } else {
            currentPos++;
          }
          currentPos = currentPos % resultList.length;
          me.setState({
            searchResultHand: currentPos,
          }, () => {
            PB.emit('network', 'focus', resultList[currentPos]);
            PB.emit('aiConsole', 'message.push', {
              type: 'ai',
              content: `定位到节点 "${getNodeDisplayTitle(resultList[currentPos], 12)}"`,
            });
          });
        } else if (!me.state.searchText) {
          message.info('请输入要搜索的词');
        } else if (me.state.searching || me.state.smartSearching) {
          message.info('搜索中，请稍后…');
        } else {
          message.info('画面中没有相关信息');
        }
      }
    });
    PB.sub(me, 'relation', 'find_node_by_text.delay_start', ({text, delay}) => {
      delay = (delay === undefined) ? 100 : Math.max(1, delay);
      me.setState({searchText: text}, () => {
        if (me.timeoutId) clearTimeout(me.timeoutId);
        me.timeoutId = setTimeout(() => {
          if (me.state.searchText === text) {
            PB.emit('relation', 'find_node_by_text.start', {text});
          }
        }, delay);
      });
    });
    PB.sub(me, 'relation', 'find_node_by_text.start', ({text, manualStart = false}) => {
      if (/*!me.autoShowSearchMsgTimeout && */manualStart && text !== '' && me.manualSearchText !== _.trim(text)) {
        // 用户触发查询
        if (me.manualShowSearchMessageKey) {
          PB.emit('aiConsole', 'message.update', {
            key: me.manualShowSearchMessageKey,
            content: `本次查询终止`,
          });
        }
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: `正在图谱中查询 "${getNodeDisplayTitle({fname: text}, 12)}"`,
          callback: ({key}) => me.manualShowSearchMessageKey = key,
        });
      }
      if (manualStart) me.manualSearchText = _.trim(text);
      me.setState({searchText: text}, () => me.doSearch());
    });
    PB.sub(me, 'relation', 'find_node_by_text.started', ({text}) => {
      me.setState({
        lastSearchText: text,
        searchResultList: [],
        smartSearchResultList: [],
        searchResultHand: null,
        searching: true,
        smartSearching: !!me.props.networkRef,
      });
    });
    PB.sub(me, 'relation', 'find_node_by_text.processing', (
      {text, resultList, searching, smartSearchResultList, smartSearching, changed}
    ) => {
      if (changed && me.state.lastSearchText === text) {

        resultList = resultList || [];
        searching = searching === undefined ? me.state.searching : (searching !== false);
        smartSearchResultList = smartSearchResultList || [];
        smartSearching = smartSearching === undefined ? me.state.smartSearching : (smartSearching !== false);

        if (me.manualShowSearchMessageKey/* || me.autoShowSearchMessageKey*/) {
          let content = `正在图谱中查询 "${getNodeDisplayTitle({fname: text}, 12)}"`;
          if (resultList && resultList.length > 0 && smartSearchResultList && smartSearchResultList.length > 0) {
            content = `${content}，目前找到匹配的节点 ${resultList.length} 个，相关的节点 ${smartSearchResultList.length} 个`;
          } else if (resultList && resultList.length > 0) {
            content = `${content}，目前找到匹配的节点 ${resultList.length} 个`;
          } else if (smartSearchResultList && smartSearchResultList.length > 0) {
            content = `${content}，目前找到相关的节点 ${smartSearchResultList.length} 个`;
          }

          PB.emit('aiConsole', 'message.update', {
            key: me.manualShowSearchMessageKey/* || me.autoShowSearchMessageKey*/,
            content,
          });
        }
        me.setState({searchResultList: resultList, searching, smartSearchResultList, smartSearching})
      }
    });
    PB.sub(me, 'relation', 'find_node_by_text.finished', ({resultList, smartSearchResultList}) => {
      let callback = me.state.searchCallback;
      if (me.manualShowSearchMessageKey/* || me.autoShowSearchMessageKey*/) {
        let content;
        if ((!resultList || resultList.length <= 0) && (!smartSearchResultList || smartSearchResultList.length <= 0)) {
          content = `没有找到与输入相关的节点`;
        } else {
          content = `在图谱中找到与输入`;

          if (resultList && resultList.length > 0 && smartSearchResultList && smartSearchResultList.length > 0) {
            content = `${content} 匹配的节点 ${resultList.length} 个，相关的节点 ${smartSearchResultList.length} 个`;
          } else if (resultList && resultList.length > 0) {
            content = `${content} 匹配的节点 ${resultList.length} 个`;
          } else {
            content = `${content} 相关的节点 ${smartSearchResultList.length} 个`;
          }
        }

        PB.emit('aiConsole', 'message.update', {
          key: me.manualShowSearchMessageKey/* || me.autoShowSearchMessageKey*/,
          content,
        });

        me.manualShowSearchMessageKey = undefined;
        /*me.autoShowSearchMessageKey = undefined;*/
      }
      me.setState({
        searchResultList: resultList,
        smartSearchResultList,
        searching: false,
        smartSearching: false,
        searchCallback: undefined,
      }, () => {
        callback && callback(_.concat(resultList, smartSearchResultList));
      });
    });
    PB.sub(me, 'relation', 'node.single_selection_change', (nodeId) => {
      if (nodeId) {
        me.currentSelectedNodeId = nodeId;
      } else {
        me.currentSelectedNodeId = undefined;
      }
    });

    if (me.element && me.element.offsetParent) {
      me.parentBoundingClientRect = me.element.offsetParent.getBoundingClientRect();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!me.parentBoundingClientRect) {
      if (me.element && me.element.offsetParent) {
        me.parentBoundingClientRect = me.element.offsetParent.getBoundingClientRect();
      }
    }
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  render() {
    let me = this, iconStyle = {
      textAlign: 'center',
      height: '10rem',
      marginTop: '-1rem',
      lineHeight: '8rem',
      fontSize: '12rem',
      marginLeft: '-24px',
    };

    const suffix = (
      <Icon
        name="close-circle"
        onClick={this.emitEmpty}
        style={{cursor: 'pointer', display: this.state.searchText ? undefined : 'none'}}
      />
    );

    const viewIds = ['434406be-b86c-4fbd-8c45-99e9d23340dd','807eb04e-cdd0-4012-8938-3afeaf9a80c6','3519c169-27c8-43bc-9aab-82aa15fee5f5','0e0bee68-7dec-4c4f-8f2c-aa66da3943ef'];
    // noinspection RequiredAttributes
    return me.props.hideInputBoxFlag===1?(''):(
      <div
        className={style["search-tool-inside"]}
        id="common_relation_search"
        ref={ele => {
          if (!ele || ele === me.element) return;
          me.element = ele;
          me.initListeners();
        }}
        style={{
          position: 'absolute',
          left: `${this.state.translateX}px`,
          top: `${this.state.translateY}px`,
          overflow: 'hidden',
          display: this.state.visible ? null : 'none',
          visibility:SysUIConfig.RelationSearchBar.visibility,
        }}
        onMouseDown={e => {
          //e.stopPropagation();
          //e.preventDefault();
          PB.emit('aiConsole', 'show.on_hover_false');
        }}
      >
        <div className={style['search-bar']}>
          <Tooltip placement="topLeft" title="按住拖动，双击自动停靠" arrowPointAtCenter>
            <Button
              block
              className={style["cool-button"]}
              style={{cursor: 'move'}}
              onMouseDown={e => this.onMouseDown(e)}
              onDoubleClick={() => me.dockComponent()}
            >
              <Icon name={'icon-move'} theme="outlined" type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
          <div>
            <Input.Group compact>
                <span className="ant-input-search ant-input-affix-wrapper">
                  <Input
                    autoComplete="off"
                    id='schInput'
                    ref={node => me.searchTextInput = node}
                    placeholder={!!me.props.readonly ? intl.get('Custom.form.searchInMap') : intl.get('Custom.form.signSpace')}
                    value={me.state.searchText}
                    suffix={suffix}
                    onChange={e => PB.emit('relation', 'find_node_by_text.delay_start', {text: e.target.value})}
                    onPaste={me.onSearchInputPaste}
                    onPressEnter={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (e.shiftKey && !me.props.readonly) {
                        me.doHandleSearchInput(e.target.value, false, 'single');
                      } else {
                        me.doHandleSearchInput(e.target.value);
                      }
                    }}
                    onFocus={() => {
                      PB.emit('relationSearch', 'input.on_focus');
                    }}
                    onBlur={() => {
                      PB.emit('relationSearch', 'input.on_blur');
                    }}
                  />
                </span>
            </Input.Group>
          </div>
          {
            !!me.props.readonly ? null : (
              <React.Fragment>
                <Tooltip
                  placement="topRight"
                  title={'智能输入（回车）'}
                  key={'btn-batch-add'}
                >
                  <Button
                    block
                    className={style["cool-button"]}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      me.doHandleSearchInput(me.state.searchText, false);
                    }}
                  >
                    <Icon name="icon-batch_plus" type={IconTypes.ICON_FONT}/>
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="topRight"
                  title={'单节点输入（Shift+回车）'}
                  key={'btn-add'}
                >
                  <Button
                    block
                    className={style["cool-button"]}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      me.doHandleSearchInput(me.state.searchText, false, 'single');
                    }}
                  >
                    <Icon name="plus"/>
                  </Button>
                </Tooltip>
              </React.Fragment>
            )
          }
          {//viewIds.includes(this.props.viewId) &&
          <Tooltip placement="topRight" title={'全站搜索'} key={'btn-search'}>
            <Button
              block
              className={style["cool-button"]}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                me.doHandleSearchInputAll(me.state.searchText);
              }}
            >
              <Icon name="icon-search" theme="outlined" type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>}
          {false &&
          <Tooltip placement="topRight" title={'在图谱中搜索'} key={'btn-search'}>
            <Button
              block
              className={style["cool-button"]}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                me.doHandleSearchInput(me.state.searchText, true, false);
              }}
            >
              <Icon name="icon-search" theme="outlined" type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>}
        </div>
        {
          (
            (this.state.searchResultList && this.state.searchResultList.length > 0) ||
            (this.state.smartSearchResultList && this.state.smartSearchResultList.length > 0)
          ) && (
            <List
              split={false}
              header={(
                <React.Fragment>
                  在图谱中查询到：
                  <Tooltip title={'复制下列节点标题'}>
                    <Button
                      style={{float: 'right', height: '100%', width: '1.3rem', lineHeight: '1.3rem'}}
                      icon={'copy'}
                      onClick={me.onCopyResultNodeTitles}
                    />
                  </Tooltip>
                </React.Fragment>
              )}
              className={style['search-result'] + ' scrollbar dark-theme'}
              bordered={false}
              locale={{emptyText: '没有数据'}}
              dataSource={_.uniqBy(_.concat(this.state.searchResultList || [], this.state.smartSearchResultList || []), n => n.id)}
              renderItem={(item, idx) => (
                <List.Item
                  id={'schListId' + idx}
                  className={[nodeStyle['node-summary-info'], this.state.searchResultHand === idx ? nodeStyle['in-hand'] : '']}
                  tabIndex={idx}
                >
                  {this.getNodeContent(item)}
                </List.Item>
              )}
            />
          )
        }

        <Modal
          title={'多节点创建方式确认'}
          width={me.currentSelectedNodeId ? '49rem' : '41rem'}
          centered={true}
          closable={!me.state.multipleItemInputProcessing}
          visible={me.state.showMultipleItemInputConfirmModal}
          okText={'确认'}
          cancelText={'取消'}
          onOk={me.onMultipleItemInputConfirmed}
          onCancel={() => {
            me.setState({
              searchText: me.state.multipleItemInputList.join(' '),
              showMultipleItemInputConfirmModal: false,
              multipleItemInputList: [],
              multipleItemInputMethodType: 'star',
              multipleItemInputProcessing: false,
            });
          }}
          cancelButtonProps={{disabled: me.state.multipleItemInputProcessing}}
          confirmLoading={me.state.multipleItemInputProcessing}
        >
          <div>检测到您输入了{me.state.multipleItemInputSeparatedBy === 'line' ? '多行' : '空格隔开的多条'}文本，您希望：</div>
          <Radio.Group
            disabled={me.state.multipleItemInputProcessing}
            onChange={e => me.setState({multipleItemInputMethodType: e.target.value})}
            value={me.state.multipleItemInputMethodType}
            className={style['multiple-line-radio-wrap']}
          >
            <Radio value={'star'} className={style['multiple-line-radio']}>
              {
                me.currentSelectedNodeId ? (
                  <React.Fragment>
                    <div style={{lineHeight: 1.2}}>每{me.state.multipleItemInputSeparatedBy === 'line' ? '行' : '条'}作为一个节点，按星型连接到当前节点</div>
                    <div style={iconStyle}>
                      <Icon name={'icon-node-star-to'} type={IconTypes.ICON_FONT} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div style={{lineHeight: 1.2}}>每{me.state.multipleItemInputSeparatedBy === 'line' ? '行' : '条'}作为一个单独的孤立节点</div>
                    <div style={iconStyle}>
                      <Icon name={'icon-node-separate'} type={IconTypes.ICON_FONT} />
                    </div>
                  </React.Fragment>
                )
              }
            </Radio>
            <Radio value={'link'} className={style['multiple-line-radio']}>
              {
                me.currentSelectedNodeId ? (
                  <React.Fragment>
                    <div style={{lineHeight: 1.2}}>每{me.state.multipleItemInputSeparatedBy === 'line' ? '行' : '条'}作为一个节点，按串型连接到当前节点</div>
                    <div style={iconStyle}>
                      <Icon name={'icon-node-link-to'} type={IconTypes.ICON_FONT} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div style={{lineHeight: 1.2}}>每{me.state.multipleItemInputSeparatedBy === 'line' ? '行' : '条'}作为一个节点，按串型连接起来</div>
                    <div style={iconStyle}>
                      <Icon name={'icon-node-queue'} type={IconTypes.ICON_FONT} />
                    </div>
                  </React.Fragment>
                )
              }
            </Radio>
          </Radio.Group>
        </Modal>

        <Modal
          title={'智能输入解析方式确认'}
          centered={true}
          closable={!me.state.multipleLineInputProcessing}
          visible={me.state.showMultipleLineInputConfirmModal}
          okText={'确认'}
          cancelText={'取消'}
          onOk={me.onMultipleLineInputConfirmed}
          onCancel={() => {
            me.setState({
              searchText: me.state.multipleLineInputContent.replace(/[\r\n]+/g, ' '),
              showMultipleLineInputConfirmModal: false,
              multipleLineInputContent: '',
              multipleLineInputMethodType: 'single-line',
              multipleLineInputProcessing: false,
            });
          }}
          okButtonProps={{disabled: !_.trim(me.state.multipleLineInputContent.replace(/[\r\n]/g, ' '))}}
          cancelButtonProps={{disabled: me.state.multipleLineInputProcessing}}
          confirmLoading={me.state.multipleLineInputProcessing}
        >
          <div style={{marginBottom: '1rem'}}>检测到您输入了多行文本，您希望：</div>
          <Input.TextArea
            rows={4}
            disabled={me.state.multipleLineInputProcessing}
            value={me.state.multipleLineInputContent}
            onChange={({target: {value}}) => me.setState({multipleLineInputContent: value})}
          />
          <Radio.Group
            disabled={me.state.multipleLineInputProcessing}
            onChange={e => me.setState({multipleLineInputMethodType: e.target.value})}
            value={me.state.multipleLineInputMethodType}
            className={style['multiple-line-radio-wrap']}
          >
            <Radio value={'single-line'} className={style['multiple-line-radio']}>
              <div style={{marginTop: '-5px'}}>将换行理解为空格</div>
              <div>全部作为一个整行处理</div>
              <div className={style['graph-box']} style={{alignItems: 'center'}}>
                <span className={style['graph-item']} />
                <span className={style['graph-item']} />
                <span className={style['graph-item']} />
              </div>
            </Radio>
            <Radio value={'multiple-line'} className={style['multiple-line-radio']}>
              <div style={{marginTop: '-5px'}}>每行单独理解</div>
              <div>相当于逐行输入</div>
              <div className={style['graph-box']} style={{flexDirection: 'column'}}>
                <span className={style['graph-item']} style={{width: '6rem'}} />
                <span className={style['graph-item']} style={{width: '9rem'}} />
                <span className={style['graph-item']} style={{width: '5rem'}} />
              </div>
            </Radio>
          </Radio.Group>

          <div style={{marginTop: '1rem', opacity: 0.8}}>
            <Icon name={'info-circle'} style={{marginRight: '0.5em'}} />
            您可直接在上方文本框修改内容，系统将{me.state.multipleLineInputMethodType === 'single-line' ? '整体'
            : '分 ' + me.state.multipleLineInputContent.split("\n").filter(t => !!t.trim()).length + ' 次'}解析并录入
          </div>
        </Modal>
      </div>
    )
  }
}

RelationSearch.defaultProps = {
  viewId: '',
  dataLists: defaultDataLists,
  typeFieldName: 'type',
  readonly: true,
  dockAtLeft: margin,
  dockAtTop: -margin,
  areaTop: margin,
  areaBottom: margin,
  areaLeft: margin,
  areaRight: margin,
  options: {
    defaultVisible: true, // 默认是否显示输入条
    offsetX: 0, // 输入条的偏移量
    offsetY: 0,// 输入条的偏移量
    canAdd: false, // 可添加
    canDrag: true, // 可拖动
    canInput: true, // 可输入
    canSearch: true, // 可搜索
    canAddAndNotExpand: false,// 是否可以添加，不联想
    thisCanAnalyzeFile: false,// 是否可以解析文件
    thisCanAnalyzeVoice: false,// 是否可以解析语音
  },
};

RelationSearch.propTypes = {
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  dataLists: PropTypes.object,
  typeFieldName: PropTypes.string, // tip:区分节点类型的字段名？
  readonly: PropTypes.bool,
  dockAtLeft: PropTypes.number, // 默认停靠位置Left
  dockAtTop: PropTypes.number, // 默认停靠位置Top
  areaTop: PropTypes.number, // 可用区上边距
  areaBottom: PropTypes.number, // 可用区下边距
  areaLeft: PropTypes.number, // 可用区左边距
  areaRight: PropTypes.number, // 可用区右边距
  viewId: PropTypes.string
};

export default RelationSearch;

const stopAnimation = animations => {
  /*
   This used to just pause any remaining animation
   but anime gets stuck sometimes when an animation
   is trying to tween values approaching 0.

   Basically to avoid that we're just forcing near-finished
   animations to jump to the end.

   This is definitely a hack but it gets the job done—
   if the root cause can be determined it would be good
   to revisit.
   */
  const stop = anim => {
    const { duration, remaining } = anim;
    if (remaining === 1) anim.seek(duration);
    else anim.pause();
  };
  if (Array.isArray(animations)) animations.forEach(anim => stop(anim));
  else stop(animations);
};
