import React from 'react';
import {Icon, Modal, Button, message} from 'antd';
import PropTypes from 'prop-types';
import _ from "lodash";
import QRCode from 'qrcode.react';
import moment from "moment";
import {API_TradeOrderCreat } from "@/api/user";
import {convertPrice, copyUrlToBoard} from '@/components/common/common.functions'

import style from '@/style/components/accountCenter/account.bindWeChatAccountModal.less';

class MemberUpgradeModal extends React.Component {
  wxLoginHandler = undefined;

  state = {
    weChatBindingPanelId: _.uniqueId('app-random-id-member-'),
  };

  doPayMemberModal  = (trade) => {
    const payMemberModal = Modal.confirm({
      centered: true,
      title: "购买会员",
      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
      okText: "我已支付成功",
      cancelText: "取消",
      width: 600,
      content:
          <div style={{
              height: '100%',
              width: '100%',
              minHeight: '300px',
              paddingTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          }}>
              <div style={{
                  flex: '1',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
              }}>
                  <div style={{flex: '1'}}>
                      <div>订单：<b>{trade.trade_no}</b></div>
                      <div>标题：<b>{'炬图-会员费'}</b></div>
                      <div>价格：<b>{convertPrice(trade.total_fee)}元</b></div>
                  </div>
                  <div style={{flex: '0 1 35%', textAlign: 'center'}}>
                      <span><Icon name="wechat" style={{fontSize: '1rem', color: 'green'}}/> 微信扫码支付</span>
                      <div style={{
                          padding: '10px 10px 5px 10px ',
                          margin: '0 auto',
                          maxWidth: '150px',
                          textAlign: 'center',
                      }}>
                          <QRCode value={trade.code_url} title={'请使用微信扫码支付'}></QRCode>
                      </div>
                      <span>交易金额：<b style={{color: 'red'}}>{convertPrice(trade.total_fee)} 元</b><br/>有效期：2小时</span>
                  </div>
              </div>
              <div style={{flex: '0 1 30%'}}>
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>成功</b>，请点击 <b
                      style={{color: 'red'}}>【我已支付成功】</b> 按钮。
                  </div>
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>失败</b>，请点击 <b
                      style={{color: 'red'}}>【取消】</b>重新购买。
                  </div>
              </div>
          </div>,
      onOk: () => {
        message.info('支付操作')
        payMemberModal.destroy();
        /*
          ViewManagerWrapper.queryOrder(orderId).then(res => {
              if (res.state === 1) { // 支付成功
                  // 回调
                  callback && callback({action: 'pay', viewId: view.viewId, success: true})
                  // 提示
                  const saleSuccessViewModal = Modal.confirm({
                      centered: true,
                      destroyOnClose: true,
                      title: <div>{view.price > 0 ? "购买完成" : "复制完成"}</div>,
                      icon: <Icon type={IconTypes.ICON_FONT} name={view.price > 0 ? "icon-shopping-cart-1" : "icon-fuzhi"}/>,
                      okText: "打开",
                      cancelText: "取消",
                      width: 400,
                      content: <div>{view.price > 0 ? (
                        <span>已经成功购买了图谱。<br/>
                        支付金额：<span style={{color: 'red'}}>{convertPrice(trade.total_fee)}元</span>。<br/>
                        <span>是否立即打开图谱？</span></span>
                      ) : (
                        <span>已经成功复制了图谱。<br/>
                        <span>是否立即打开图谱？</span></span>
                      )}</div>,
                      onOk: () => {
                          // 打开刚刚购买的图谱,要用新图谱的id
                          window.open('/mainview/relation/' + res.newViewId, '_blank')
                      },
                      onCancel: () => {
                          saleSuccessViewModal.destroy();
                      },
                  })
              } else if (res.state === 0) { // 待支付
                  // 提示
                  const saleWaitingViewModal = Modal.warning({
                      centered: true,
                      destroyOnClose: true,
                      title: "支付未完成",
                      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                      okText: "知道了",
                      cancelText: "取消",
                      width: 400,
                      content: <div>
                          <div>请在微信检查支付情况。</div>
                          <div>关闭窗口后，重新购买。</div>
                      </div>,
                      onOk: () => {
                          saleWaitingViewModal.destroy();
                      },
                  })
              } else if (res.state === 2) {//已失效
                  // 提示
                  const saleOutTimeViewModal = Modal.warning({
                      centered: true,
                      destroyOnClose: true,
                      title: "支付未完成",
                      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                      okText: "知道了",
                      cancelText: "取消",
                      width: 400,
                      content: <span><span>订单已经过期，</span> 请关闭窗口后，重新购买</span>,
                      onOk: () => {
                          saleOutTimeViewModal.destroy();
                      },
                  })
              } else if (res.state === 3) { // 已退款
                  // 提示
                  const saleRefundViewModal = Modal.warning({
                      centered: true,
                      destroyOnClose: true,
                      title: "支付未完成",
                      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                      okText: "知道了",
                      cancelText: "取消",
                      width: 400,
                      content: <span><span>交易款已退回，</span> 请关闭窗口后，重新购买</span>,
                      onOk: () => {
                          saleRefundViewModal.destroy();
                      },
                  })
              } else {
                  // 提示
                  const saleRefundViewModal = Modal.warning({
                      centered: true,
                      destroyOnClose: true,
                      title: "交易未成功",
                      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                      okText: "知道了",
                      cancelText: "取消",
                      width: 400,
                      content: <span><span>交易未成功，</span> 请关闭窗口后，重新购买</span>,
                      onOk: () => {
                          saleRefundViewModal.destroy();
                      },
                  })
              }
          }).catch()
          
          
          // todo：支付成功后，跳转我的图谱并刷新？？
          */
      },
      onCancel: () => {
          message.warning('您取消了支付操作')
          payMemberModal.destroy();
      },
    })
  }

  /**
     * 购买会员
     * @param member_type 所购买的会员等级
     * @param callback 购买后的回调 true=购买成功，false=购买失败
     */
  payMember = (member_type, callback) => {
    let me = this;
    let params ={
      trade_type:0,
      order_type: 0,
      product_id: 'member_2',
      id_user: localStorage.getItem('userId')
    }
    API_TradeOrderCreat(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          let trade = response.data.data;
          console.error('s-2:',trade);
          me.payMemberModal(trade);
        }else{
          message.info(response.data.msg, 3);
        }
      }else{
        message.info('网络不稳定，请稍后重新发起购买请求。--', 3);
      }
    }).catch((e) => {
      console.log('common.viewManager->payView->获取订单出错 e=', e);
      // console.log('common.viewManager->payView->获取订单出错 view=', view)
      // message.error('网络不稳定，请在窗口关闭后重做上架操作。...', 3).then(() => payViewModal.destroy())
      message.error('网络不稳定，请稍后重新发起购买请求。', 3)
      callback && callback({action: 'pay', viewId: view.viewId, success: false})
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visible === false && this.props.visible) {
      //this.initWxLogin();
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        // width={348}
        centered={true}
        visible={me.props.visible}
        title={'会员升级'}
        footer={[
          <Button key="close" onClick={me.props.onCancel}>关闭</Button>,
        ]}
        destroyOnClose={true}
        onCancel={me.props.onCancel}
      >
        <div className={style['third-party-login-wechat-frame']} style={{ height: 'unset' }}>
          <a key={'bind-wechat'} onClick={e => {e.stopPropagation();me.payMember(1);}}>会员升级_粉丝用户</a>
        </div>
        <div className={style['third-party-login-wechat-frame']} style={{height: 0}}>
          {}
        </div>
      </Modal>
    );
  }
}

MemberUpgradeModal.propType = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

export default MemberUpgradeModal;