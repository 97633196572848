import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Tooltip} from 'antd';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import AuthForm from '@/components/common/common.authForm';

import style from '@/style/components/common.authForm.less';
import intl from 'react-intl-universal';

class CheckUsernameForm extends React.Component {
  handleCheckUsername = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onCheckUsername(values.username)
      }
    })
  };
  componentWillMount() {
    if(window.location.hostname==="ustcsv.joinmap.ai"){
      this.props.onCheckUsername('ustcsv_guest@joinmap.ai')
    }
  }

  render () {
    let me = this;

    const { getFieldDecorator } = this.props.form;
    // noinspection RequiredAttributes 
    return (window.location.hostname==="ustcsv.joinmap.ai")?(''):(
      <AuthForm>
        {intl.get('locale')==='zh-cn' &&
        <div
          style={{
            height: '6rem',
            width: 'calc(350px + 5rem)',
            position: 'absolute',
            margin: '-2.5rem',
            textAlign: 'right',
            lineHeight: '5.5rem',
            fontSize: '5.5rem',
          }}
        >
          <Tooltip title={intl.get('Custom.general.pWechatLogin')}>
            <a onClick={me.props.loginViaWechat}>
              <Icon name={'icon-qr-code'} type={IconTypes.ICON_FONT} />
            </a>
          </Tooltip>
        </div>}
        <Form onSubmit={this.handleCheckUsername} style={{display: 'flex', flexDirection: 'column',marginBottom: '-1rem'}}>
          <h1 className={style['form-title']}>{intl.get('Custom.general.accountLogin')}</h1>
          <Form.Item className={style['form-item']} style={{marginTop: '3rem'}}>
            {getFieldDecorator('username', {
              rules: [{
                required: true,
                message: intl.get('Custom.general.enterEmail'),
              }],
              initialValue: this.props.currentUsername,
            })(
              <Input
                prefix={<Icon name="mail"/>} placeholder={intl.get('Custom.general.enterEmail')} autoFocus
                onFocus={this.props.onInputFocus} onBlur={this.props.onInputBlur}
              />
            )}
          </Form.Item>
          <div className={style['form-item']} style={{flex: 1}}>
            <div style={{lineHeight: '1.25em', paddingBottom: '3rem'}}>
            {intl.get('Custom.general.noAccount')} <a onClick={this.props.gotoRegister}>&lt;{intl.get('Custom.general.registerNow')}&gt;</a>
            </div>
          </div>
          <div className={style['form-item']}>
            {intl.get('locale')==='zh-cn' &&
            <Tooltip placement="right" title={<div>{intl.get('Custom.general.wechatLogin')}<br/>{intl.get('Custom.general.noNeedPassword')}</div>}>
              <Button
                shape="circle"
                className={`${style['form-button']} ${style['wechat-button']}`}
                onClick={this.props.loginViaWechat}
                style={{float: 'left'}}
              >
                <Icon name={'wechat'} />
              </Button>
            </Tooltip>}
            <Button
              type="primary"
              htmlType="submit"
              loading={this.props.checkingUsername}
              className={style['form-button']}
            >
              {intl.get('Custom.general.next')}
            </Button>
          </div>
          <div className={style['form-item']} style={{lineHeight: '1.25em', paddingTop: '3rem'}}>
            <a onClick={() => {me.props.guestLogin()}}>不登录，随便看看</a>
          </div>
        </Form>
      </AuthForm>
    );
  }
}

const WrappedCheckUsernameForm = Form.create()(CheckUsernameForm);

WrappedCheckUsernameForm.defaultProps = {
  onCheckUsername (username) {
    console.warn('onCheckUsername property is not set, username %s.', username);
  },
  gotoRegister () {
    console.warn('gotoRegister property is not set.');
  },
  loginViaWechat () {
    console.warn('loginViaWechat property is not set.')
  },
  onInputFocus () {},
  onInputBlur () {},
  currentUsername: '',
  checkingUsername: false,
};

WrappedCheckUsernameForm.propTypes = {
  onCheckUsername: PropTypes.func,
  gotoRegister: PropTypes.func,
  loginViaWechat: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  currentUsername: PropTypes.string,
  checkingUsername: PropTypes.bool,
};

export default WrappedCheckUsernameForm;