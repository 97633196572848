import React from "react";
import PropTypes from 'prop-types';
import {Dropdown, Menu, Modal} from "antd";
import UserAvatar from "react-user-avatar";

import PB, {SimplePB} from "@/libs/simplePB";

import {AvatarColors} from "@/constants/common";
import {ChangePwdStatus} from "@/constants/account";

import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import checkPwdStrength from "@/utils/PwdStrengthChecker";

import Account from "@/components/common/common.account";
import {showSuccessMsg} from "@/components/common/common.message";
import BasicInfoModal from "@/components/accountCenter/account.basicInfoModal";
import ChangePwdModal from "@/components/accountCenter/account.changePwdModal";
import BindWeChatAccountModal from "@/components/accountCenter/account.bindWeChatAccountModal";
import UserCenterModal from "@/components/accountCenter/account.userCenterModal";
import MemberUpgradeModal from "@/components/accountCenter/account.memberUpgradeModal";

import style from '@/style/common/common.accountActions.less';
import ChangeEmailModal from "@/components/accountCenter/account.changeEmailModal";
import intl from 'react-intl-universal';

class AccountActions extends React.PureComponent {
  state = {
    showBasicInfoModal: false,
    showChangePwdModal: false,
    showChangeEmailModal: false,
    showWeChatBindingModal: false,
    showUserCenterModal:false,
    showMemberUpgradeModal:false
  };

  changePwdFormRef = undefined;

  showBasicInfo = () => {
    this.setState({showBasicInfoModal: true});
  };

  // 修改、设置密码 - 开始

  showChangePwdModal = () => {
    let me = this;

    if (me.props.userInfo.email) {
      me.setState({showChangePwdModal: true});
    } else {
      Modal.confirm({
        title: '温馨提示',
        content: '请先绑定邮箱再设置密码。',
        okText: '立即绑定',
        cancelText: '取消',
        onOk: () => {
          requestAnimationFrame(() => me.showChangeEmailModal());
        },
      });
    }
  };

  doChangePwd = () => {
    let me = this;
    if (!me.changePwdFormRef) return;
    const form = me.changePwdFormRef.props.form;
    form.validateFields((err, values) => {
      if (!err) {
        const {oldPassword, password} = values;
        this.props.doChangePwd(oldPassword, password, checkPwdStrength(password));
        me.setState({showChangePwdModal: false});
      }
    });
  };

  onChangePwdSuccess = () => {
    let me = this;
    me.setState({showChangePwdModal: false}, () => {
      me.props.resetChangePwdStatus();
      showSuccessMsg({msg: '密码修改成功'});
    });
  };

  // 修改、设置密码 - 结束

  showChangeEmailModal = () => {
    this.setState({showChangeEmailModal: true});
  };

  onBindWeChat = () => {
    let me = this;
    Account.loadUserInfo().then(userInfo => {
      const fn = () => {
        me.setState({
          showWeChatBindingModal: true,
        });
      };
      if (userInfo['wxNick']) {
        // 已绑定微信
        if (userInfo['email']) {
          const fnUnbind = () => {
            Modal.confirm({
              title: '解绑微信',
              centered: true,
              okText: '立即解绑',
              cancelText: '取消',
              content: `您已绑定过微信账号"${userInfo['wxNick']}"，解绑后才能与新账号绑定，是否继续？`,
              onOk: () => {
                Account.unbindWeChatAccount().then(() => {
                  Modal.confirm({
                    title: '操作成功',
                    centered: true,
                    okText: '立即绑定新账号',
                    cancelText: '不绑定',
                    content: '您已成功解绑，是否立即绑定新账号？',
                    onOk: fn,
                  });
                }).catch(() => fnUnbind);
              },
            });
          };
          fnUnbind();
        } else {
          Modal.info({
            title: '提示',
            centered: true,
            content: `您已绑定过微信账号"${userInfo['wxNick']}"。由于您没有其他登录方式，无法修改绑定信息。请先绑定邮箱再尝试修改。`,
            okCancel: true,
            okText: '立即绑定邮箱',
            cancelText: '知道了',
            onOk: () => {
              requestAnimationFrame(() => me.showChangeEmailModal());
            },
          });
        }
      } else {
        fn();
      }
    });
  };

  // 退出登录
  onLogout = () => {
    let me = this;
    Modal.confirm({
      title: '确认退出吗？',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        me.props.onLogout();
        sessionStorage.clear();
      },
      onCancel() {
        // ignore
      },
    });
  };

  onBindMemberUpgrade = () => {
    let me = this;
    let params ={
      trade_type:0,
      order_type: 0,
      product_id: 'member_2',
      id_user: localStorage.getItem('userId')
    }
    API_TradeOrderCreat(params).then((response) => {
      console.error('s-1:',response);
      if(response && response.data && response.data.code === 0 ){
        let data = response.data.data;
        
      }else{
        
      }
    }).catch((e) => {
      console.error('s-2:',e)
    });
  };

  showUserCenterModal = () => {
    this.setState({showUserCenterModal: true});
  };

  showMemberUpgradeModal = () => {
    this.setState({showMemberUpgradeModal: true});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'account', 'change_email.show_modal', () => {
      me.showChangeEmailModal();
    });
    me.props.bus.sub(me, 'account', 'user_center.show_modal', () => {
      me.showUserCenterModal();
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    const {nick, name, email, picId, org, province, city, accessToken, noPassword} = me.props.userInfo;

    if (this.props.changePwdStatus === ChangePwdStatus.SUCCESS && this.state.showChangePwdModal) {
      requestAnimationFrame(() => me.onChangePwdSuccess());
    }

    return (
      <React.Fragment>
        <Dropdown
          overlayClassName={'dark-theme '}
          overlay={(
            <Menu style={{textAlign: 'center'}}>
              {me.props.extraMenuItems ? me.props.extraMenuItems : null}
              {me.props.extraMenuItems ? <Menu.Divider /> : null}
              <Menu.Item key={'user-center'} onClick={me.showUserCenterModal}>{intl.get('Custom.general.userCenter')}</Menu.Item>
              <Menu.Item key={'user-basic-info'} onClick={me.showBasicInfo}>{intl.get('Custom.general.baseInfo')}</Menu.Item>
              <Menu.Item key={'modify-password'} onClick={me.showChangePwdModal}>
                {me.props.userInfo.noPassword ? intl.get('Custom.general.setPassword') : intl.get('Custom.general.changePassword')}
              </Menu.Item>
              <Menu.Item key={'modify-email'} onClick={me.showChangeEmailModal}>
                {me.props.userInfo.email ? intl.get('Custom.general.modifyMailbox') : intl.get('Custom.general.bindmailbox')}
              </Menu.Item>
              <Menu.Item key={'bind-wechat'} onClick={me.onBindWeChat}>
              {intl.get('Custom.general.bindWeChat')}
              </Menu.Item>
              {false &&
              <Menu.Item key={'bind-wechat'} onClick={me.showMemberUpgradeModal}>
               会员升级
              </Menu.Item>}
              {window.location.hostname!=="ustcsv.joinmap.ai" &&
              <Menu.Item key={'login-out'} onClick={this.onLogout}>
              {intl.get('Custom.general.signOut')}
              </Menu.Item>}
            </Menu>
          )}
          placement={'bottomRight'}
        >
          {me.props.children ? me.props.children : (
            me.props.userInfo ? (
              <div
                className={`${style['user-action']} ${me.props.className ? me.props.className : ''}`}
                style={me.props.style}
              >
                <div className={`${style['avatar']} ${me.props.userInfo.picId ? '' : style['avatar-default-icon']}`}>
                  <UserAvatar
                    size={'var(--avatar-size)'}
                    name={me.props.userInfo.nick}
                    src={
                      me.props.userInfo.picId ?
                        `${REQUEST_BASE}/user/user/file/${me.props.userInfo.picId}?Authorization=${getToken()}` :
                        undefined
                    }
                    colors={AvatarColors}
                  />
                </div>
                {/*<span style={{marginLeft: '0.3em'}}>{me.props.userInfo.nick}</span>*/}
              </div>
            ) : null
          )}
        </Dropdown>
        {me.state.showUserCenterModal && <UserCenterModal
          visible={me.state.showUserCenterModal}
          onCancel={() => me.setState({showUserCenterModal: false})}
          nick={nick || name || email}
          name={name}
          avatarPicId={picId}
          org={org}
          province={province}
          city={city}
          accessToken={accessToken}
          onEdit={me.showBasicInfo}
        />}
        <BasicInfoModal
          visible={me.state.showBasicInfoModal}
          onCancel={() => me.setState({showBasicInfoModal: false})}
          nick={nick || name || email}
          name={name}
          avatarPicId={picId}
          org={org}
          province={province}
          city={city}
          accessToken={accessToken}
          changeUserBasicInfoStatus={me.props.changeUserBasicInfoStatus}
          onSaveUserBasicInfo={me.props.onSaveUserBasicInfo}
        />
        <ChangePwdModal
          visible={me.state.showChangePwdModal}
          onCancel={() => me.setState({showChangePwdModal: false})}
          onSave={me.doChangePwd}
          hasPassword={!noPassword}
          wrappedComponentRef={formRef => me.changePwdFormRef = formRef}
          processing={me.props.changePwdStatus === ChangePwdStatus.PROCESSING}
        />
        <BindWeChatAccountModal
          visible={me.state.showWeChatBindingModal}
          onCancel={() => me.setState({showWeChatBindingModal: false})}
        />
        <ChangeEmailModal
          visible={me.state.showChangeEmailModal}
          userEmail={!!email ? email : null}
          onClose={() => me.setState({showChangeEmailModal: false})}
        />
        <MemberUpgradeModal
          visible={me.state.showMemberUpgradeModal}
          onCancel={() => me.setState({showMemberUpgradeModal: false})}
        />
      </React.Fragment>
    );
  }
}

AccountActions.defaultProps = {
  style: {},
  bus: PB,
  changePwdStatus: ChangePwdStatus.INTERACTING,
  onLogout: () => {
    console.warn('onLogout property is not set.');
  },
  doChangePwd: (oldPwd, newPwd) => {
    console.warn('doChangePwd property is not set, old password: %s, new password: %s.', oldPwd, newPwd);
  },
  resetChangePwdStatus: () => {
    console.warn('resetChangePwdStatus property is not set.');
  },
};

AccountActions.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
  extraMenuItems: PropTypes.arrayOf(PropTypes.element),
  userInfo: PropTypes.object,
  changePwdStatus: PropTypes.number,
  onLogout: PropTypes.func,
  doChangePwd: PropTypes.func,
  resetChangePwdStatus: PropTypes.func,
  changeUserBasicInfoStatus: PropTypes.number,
  onSaveUserBasicInfo: PropTypes.func,
};

export default AccountActions;